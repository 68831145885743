import DeviceDetector from 'device-detector-js';

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);

const UNINITIALIZED_RECORDER = new Error(
    'Recorder is not yet initialized.' +
    'Call getMediaRecorder() before start recording'
);

// Types declarations

type BlobCallback = (blob: Blob) => void;
type EmptyCallback = () => void;

export interface MicRecorderInterface {
    recorder: MediaRecorder | null

    getMediaRecorder: (deviceID: string, onDisconnectCallback: EmptyCallback) => void
    startRecording: () => void
    stopRecording: (callback:BlobCallback) => void
    pauseRecording: () => void
    resumeRecording: () => void
    destroy: () => void
}


class MicRecorder implements MicRecorderInterface {

    
    recorder: MediaRecorder | null;
    chunks: Blob[] = [];

    onStopCallback: BlobCallback | null = null;

    constructor(deviceID: string, getMediaRecorder:boolean = true, onDisconnectCallback: EmptyCallback = () => {}, callback: (mic: MediaStream) => void = (mic: MediaStream) => {}) {
        this.recorder = null;

        if (getMediaRecorder) {
            this.getMediaRecorder(deviceID, onDisconnectCallback).then(callback);
        }
    }

    getMediaRecorder(deviceID: string, onDisconnectCallback: EmptyCallback) {

        return new Promise<MediaStream>((resolve) => {

            // create config depending on browser
            // let config = {
                // deviceId: {exact: deviceID},
                // echoCancellation: false,
                // numberOfAudioChannels: 1,
                // autoGainControl: true,
                // noiseSuppression: false,

            // };

            // let media_recorder_options = {
            //     mimeType: 'audio/webm',
            //     audioBitsPerSecond: 192000
            // }


            if (device.client?.name === 'Safari') {
                // config['numberOfAudioChannels'] = 2;
                // media_recorder_options['mimeType'] =  'audio/mp4';
                // media_recorder_options['audioBitsPerSecond'] = 192000;
    
            }

            console.log("[facu]device.client?.name", device.client?.name)
            // console.log("[facu]config=", config)
            // console.log("[facu]media_recorder_options=", media_recorder_options)

            navigator.mediaDevices.getUserMedia({
                audio: true,
                // video: false
            }).then((stream: MediaStream) => {
                this.recorder = new MediaRecorder(stream);

                this.recorder.ondataavailable = (event: BlobEvent) => {
                    if (this.recorder === null) {
                        debugger;
                        throw UNINITIALIZED_RECORDER;
                    }

                    if (event.data.size > 0) {
                        this.chunks.push(event.data);
                    }
                }

                this.recorder.onstop = () => {

                    if (this.recorder === null) {
                        debugger;
                        throw UNINITIALIZED_RECORDER;
                    }

                    const blob = new Blob(
                        this.chunks,
                        {type: this.recorder?.mimeType}
                    );
                    this.chunks = [];

                    if (this.onStopCallback !== null) {
                        this.onStopCallback(blob);
                    }

                    this.onStopCallback = null;
                }

                this.recorder.stream.getTracks().forEach(
                    track => track.onended = () => {
                        onDisconnectCallback();
                    }
                )

                resolve(stream);
            });
        });
    }

    startRecording() {

        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }
        this.recorder.start();
    }

    stopRecording(callback:BlobCallback = () => {}) {

        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.onStopCallback = callback;
        this.recorder.stop();
    }

    pauseRecording() {

        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.recorder.pause();
    }

    resumeRecording() {
        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        this.recorder.resume();
    }

    destroy() {

        // check the recorder state is correct
        if (this.recorder === null) {
            throw UNINITIALIZED_RECORDER;
        }

        if (this.recorder.state === 'recording') {
            throw new Error('Recorder is recording. Stop recording before destroying it.');
        }

        this.recorder?.stream.getTracks().forEach(track => track.stop());
    }
}

export default MicRecorder;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PopUpSuccess, PopUpError } from '../../Commons/Helpers';
import { useContent } from '../../Content/cms';
import { useDirtyState } from '../../store/dirtyState';
import SiteMap from '../../Commons/SiteMap';

import Apirest from "../../Content/Apirest";

import { Item, ProtocolData, ProtocolResData } from './types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ProtocolItems from './ProtocolItems';
import Form  from 'react-bootstrap/Form';
import ParticipantHeader from '../../Commons/Components/Participant/Header';
import { RowSpinner } from '../../Commons/MultipleComponents';
import Section from "../../Commons/Section";
import Link from '../../Commons/SiteMap/Link';
import SaveCancel from '../../Commons/Components/SaveCancelButtons';


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";

import GoBackButton from '../../Commons/Components/GoBackButton';

import './index.css';
import { FormGroup } from 'react-bootstrap';


const Recorder : React.FC = () => {

    let history = useHistory();

    let getContent = useContent();

    let {setDirty, setClean} = useDirtyState('protocol_recorder');

    let {participant_id, protocolres_id} = useParams<{participant_id: string, protocolres_id: string}>();

    let [protocolResID, setProtocolResID] = useState<number|undefined>();

    let [protocolFinished, setProtocolFinished] = useState<Boolean>(false);

    let [availableProtocols, setAvailableProtocols] = useState<ProtocolData[]|undefined>();

    let [savedResponses, setSavedResponses] = useState<string[]>([]);

    let [recordedResponses, setRecordedResponses] = useState<string[]>([]);

    let [isRecording, setIsRecording] = useState<boolean>(false);

    let [startIdx, setStartIdx] = useState<number>(0);

    let [comments, setComments] = useState<string>('')

    // Store the protocol items data
    let [selectedProtocol, setSelectedProtocol] = useState<ProtocolData|undefined>();

    //protocol end modal 
    const [show, setShow] = useState(false);
    const [display, setDisplay] = useState('display')

    const handleClose = () => {
        setShow(false);
        history.push(SiteMap.getFullURL('participant', [participant_id]))
        setDisplay('display');
    };

    const handleShow = () => {
        setDisplay('d-none')
        setShow(true);
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) =>{
        const value = event.target.value;
        setComments(value);
    }

    const submitComments = (event : any) => {
        event.preventDefault();
        event.stopPropagation();
        if (protocolResID === undefined) {
            throw (new Error("Protocol response ID is undefined"));
        }
        
        Apirest.add_protocol_res_comment(participant_id, protocolResID.toString(), comments, ()=>{
            setComments('');
        });
        handleClose();
    }

    // get available protocols from server
    useEffect(() => {

        if (protocolres_id !== undefined) {
            Apirest.get_protocolres(participant_id, protocolres_id, (protocolRes: ProtocolResData) => {
                setProtocolResID(protocolRes.id);
                setSavedResponses(protocolRes.responses.map(r => r.protocol_item.id.toString()));
                setStartIdx(protocolRes.responses.map(r => r.protocol_item.order).reduce((a,b) => Math.max(a,b), -1) + 1);
                // This setState is at the end to prevent set selected protocol before the other states.
                // Normally React should update all the states at the same time, but in this case it doesn't. don't know why
                setSelectedProtocol(protocolRes.protocol);
            });
        } else {
            Apirest.get_examiner_protocols(setAvailableProtocols);
        }
    }, [participant_id, protocolres_id]);

    // Set dirty state if there is any recorded response not yet saved
    useEffect(() => {
        if (isRecording || recordedResponses.some(r => !savedResponses.includes(r))) {
            setDirty();
        } else {
            setClean();
        }
    }, [recordedResponses, savedResponses, isRecording, setDirty, setClean]);

    useEffect(checkAllSavedAndRedirect, [savedResponses, protocolFinished]);

    function checkAllSavedAndRedirect() {
        if (selectedProtocol !== undefined && protocolFinished) {
            
            let all_saved = selectedProtocol.items.every(
                item => savedResponses.indexOf(item.id.toString()) > -1
            );
    
            if (all_saved) {
                setClean();
                PopUpSuccess(
                    <div className='text-center'>
                        <p>{getContent('recorder__server_sync__success')}</p>
                        <p>
                            <Link to={'participant'} params={[participant_id]}>
                                {getContent('recorder__server_sync__link')}
                            </Link>
                        </p>
                    </div>
                );
            }
        }
    }

    function onProtocolEnd() {
        setProtocolFinished(true);
        handleShow();
    }

    function sendResponse(protocol_item_id: string, blob: Blob) {
        if (selectedProtocol === undefined) {
            throw (new Error("Protocol data is undefined"));
        }

        if (protocolResID === undefined) {
            throw (new Error("Protocol response ID is undefined"));
        }

        Apirest.send_protocol_item_response(
            participant_id,
            protocolResID.toString(),
            protocol_item_id,
            blob,
            () => setSavedResponses(prev => [...prev, protocol_item_id]),
            () => {

                // Note: In order to read the real value of the protocolFinished
                // state, we need to use the functional setState function to get
                // the actual prev state and return that same state to avoid
                // setting a new state.
                setProtocolFinished(prev => {
                    if (prev) {
                        PopUpError(getContent('recorder__server_sync__failed'));
                    }

                    return prev;
                })
                
                setTimeout(() => sendResponse(protocol_item_id, blob), 4000);
            }
        );
    }

    function sendSkippedPurpose(protocol_item_id: string, purpose: string) {
        if (selectedProtocol === undefined) {
            throw (new Error("Protocol data is undefined"));
        }

        if (protocolResID === undefined) {
            throw (new Error("Protocol response ID is undefined"));
        }

        Apirest.send_protocol_item_skip(
            participant_id,
            protocolResID.toString(),
            protocol_item_id,
            purpose,
            () => setSavedResponses(prev => [...prev, protocol_item_id]),
            () => {
                // Note: In order to read the real value of the protocolFinished
                // state, we need to use the functional setState function to get
                // the actual prev state and return that same state to avoid
                // setting a new state.
                setProtocolFinished(prev => {
                    if (prev) {
                        PopUpError(getContent('recorder__server_sync__failed'));
                    }

                    return prev;
                })
                
                setTimeout(() => sendSkippedPurpose(protocol_item_id, purpose), 4000);
            }
        );
    }

    function onRecordingStart() {
        setIsRecording(true);
    }

    function onRecordingStop(item: Item, blob: Blob) {
        setIsRecording(false);
        setRecordedResponses(prev => [...prev, item.id.toString()]);
        sendResponse(item.id.toString(), blob);
    }

    function onSkipped(item: Item, purpose: string) {
        setIsRecording(false);
        setRecordedResponses(prev => [...prev, item.id.toString()]);
        sendSkippedPurpose(item.id.toString(), purpose);
    }

    function onMicDisconected() {
        setIsRecording(false);
    }

    function onSelectProtocol(e: React.ChangeEvent<HTMLSelectElement>) {
        if (availableProtocols === undefined) {
            throw (new Error("Available protocols is undefined"));
        }

        let selectedProtocol = availableProtocols[e.target.selectedIndex-1];

        if (selectedProtocol === undefined) {
            throw (new Error("Selected protocol is undefined"));
        }

        Apirest.create_protocol_res(
            participant_id,
            selectedProtocol.id,
            (response: {id:number}) => {
                setProtocolResID(response.id)
                setSelectedProtocol(selectedProtocol);
            }
        )  
    }

    let content = null;

    // Protocol is on progress
    if (selectedProtocol !== undefined && protocolResID !== undefined && !protocolFinished) {

        content = <ProtocolItems
            onProtocolEnd={onProtocolEnd}
            onRecordingStart={onRecordingStart}
            onRecordingStop={onRecordingStop}
            onSkipped={onSkipped}
            onMicDisconected={onMicDisconected}
            protocol={selectedProtocol}
            protocolResID={protocolResID}
            startIdx={startIdx}
        />;
    
    // Protocol is not yet selected
    } else if (selectedProtocol === undefined && availableProtocols !== undefined) { 
        content = (
            <Container className={'protocol '} fluid>
                <h3 className='protocol-header'>
                    {getContent('protocol__select_protocol__title')}
                </h3>
                <Row className={'protcol-body'}>
                    <Col>
                        <select 
                            className="protocol-select" 
                            defaultValue={-1} 
                            onChange={onSelectProtocol}
                        >
                            <option disabled value={-1}>
                                {getContent('protocol__select_protocol__title')}
                            </option>
                            {availableProtocols.map(
                                (protocol, idx) => 
                                    <option
                                        key={idx}
                                        value={idx}
                                    >{protocol.name}</option>
                            )}
                        </select>
                    </Col>
                </Row>
            </Container>
        );
    
    // Protocol is finished or protocol list not yet available
    } else {
        content = <Section
            className={'primary-section'}
            headerClassName={'primary-section-header'}
            bodyClassName={'primary-section-body'}
        >
            <RowSpinner className={'mt-3'} show/>
        </Section>;
    }

    return (
        <Container className={'bg-light content-wrapper'} fluid>
            <ParticipantHeader />
            <Row className={'bg-info p-2 d-flex justify-content-between'}>
            <Col className={'mt-2'}>
                <h5 className={'text-white  font-weight-bold mb-0 pb-0'}>
                    <FontAwesomeIcon icon={faBriefcase} id={'flaskIcon'}/>  {getContent("protocol_title")}
                </h5>
            </Col>
            <GoBackButton/>
            </Row>
            <Row className={'mx-3 d-flex justify-content-center content-minheight'}>
                <Col lg={10} className={display}>
                    {content}
                </Col>
                <Modal 
                    show={show} 
                    onHide={handleClose} 
                    animation={false}
                    centered
                    size={'lg'}
                    >
                    <Modal.Header >
                      <Modal.Title> {getContent('protocol__end_modal__title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {getContent('protocol__end_modal__text')}
                    <Form className={'mt-3'}>
                        <FormGroup controlId={'comments_form'}>
                            <Form.Control
                                as="textarea"
                                placeholder={getContent('protocol__end_modal__comment_placeholder')}
                                rows={3}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <SaveCancel
                          save={submitComments}
                          cancel={handleClose}
                          saveText= {getContent('protocol__end_modal__comment_send')}
                          saveClassName={'mb-0 mt-2'}
                          saveBtnVariant={'primary'}
                          cancelText= {getContent('protocol__end_modal__comment_cancel')}
                          cancelClassName={'mb-0 mt-2'}
                          cancelBtnVariant={'light'}
                        />
                    </Form>
                    </Modal.Body>
                </Modal>
            </Row>
        </Container>
    )
}

export default Recorder;


import React, { FC, useRef, useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import MicRecorder from './micRecorder';
import AudioVisualizer from './audioVisualizer';
import { useContent } from '../../../Content/cms';

import {faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';

import { StoreState } from '../../../store';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RecordingClock from './recordClock';
import Link from '../../../Commons/SiteMap/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RowSpinner } from '../../../Commons/MultipleComponents';
import SkipButton from './skipButton';


export interface RecorderProps {
  deviceID: string;
  protocolResID: number
  onRecordingStart: () => void
  onRecordingStop: (data: Blob) => void
  onMicDisconected: () => void
  onSkipItem: (purpose:string) => void
  disabled: boolean
  skippable: boolean

  // min and max duration in seconds are optional because they 
  // are optional in the protocol item data type
  minDuration?: number
  maxDuration?: number
}

const Recorder: FC<RecorderProps> = (props) => {
  
  let isDirty = useSelector<StoreState, boolean>(
    state => Object.values(state.dirtyState).some(v => v)
  );

  let getContent = useContent();

  let {participant_id} = useParams<{participant_id: string}>();

  // The recorder needs user permission to work, as this is asynchronous,
  // we use a state to indicate when the recorder is ready.
  // This is needed because the recorder is not a react component 
  // and therefore cannot trigger a state change.
  let [mediaStream, setMediaStream] = useState<MediaStream|null>(null);

  // This state is used to render correct state depending on the recorder state
  // because the recorder does not automatically trigger a state change in react.
  let [recordingStatus, setRecordingStatus] = useState<boolean>(false);

  // This state enable stop recording after the min duration
  let [stopEnabled, setStopEnabled] = useState<boolean>(!props.minDuration);

  //This state is for show modal to send recording when limit time is reached and can't continue recording
  let [limitReached, setLimitReached] = useState<boolean>(false);

  // This state is for showing the modal if the mic got disconnected
  let [micDisconnected, setMicDisconnected] = useState<boolean>(false);
  
  // The recorder is store in a ref to be able to maintain it across renders.
  let micRecorder = useRef<MicRecorder>();

  const mobiles = require('is-mobile');
  let mobile = mobiles();

  // This effect is triggered when the recording state changes
  useEffect(
    () => {
      
      if (mediaStream === null) {
        // This work because all the useEffects are called in order and 
        // the recorderReady state is set in the second useEffect and also react triggers the
        // state change after all the useEffects are called.
        // This behavior is not guaranteed by react and should not be relied upon.
        // I (Lao) read about it and did a lot of testing and it work.
        // but if in the future this behavior changes, this will break.
        return;
      }

      // If the recorder is not ready, we do nothing
      if (micRecorder.current === undefined) {
        throw new Error('Recorder is not ready');
      }

      if (recordingStatus) {

        // It's important to call the callback functions here in a useEffect and 
        // not inside a setState because setState is asynchronous and the callback
        // will trigger a re-render in the parent and then in this component.
        // creating a render inside a render and getting a warning from react.

        // Start recording if not
        micRecorder.current.startRecording();
        
        props.onRecordingStart();
      } else {
        // Stop recording and set obtained blob if it's currently recording
        micRecorder.current.stopRecording(props.onRecordingStop);
      }
    }, [recordingStatus] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(
    () => {
      console.log('[Recorder] new mic');
      micRecorder.current = new MicRecorder(
        props.deviceID,
        true,
        onDisconnectedMic,
        setMediaStream
      );

      return () => {
        console.log('[Recorder] Destroy mic');
        micRecorder.current?.destroy();
      }
    }, [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // This function is called when the user stop recording to reset recording 
  // state for the next recording.
  useEffect(
    () => {
      if (!recordingStatus) {
        setStopEnabled(!props.minDuration);
      }
    }, [recordingStatus, props.minDuration]
  );

  // Toggle start/stop recording
  const toggleRecorder = () => setRecordingStatus(isRecording => !isRecording);

  const showLimitModal = () => setLimitReached(true);

  function onDisconnectedMic() {

    // If the recorder is not ready, we do nothing
    if (micRecorder.current === undefined) {
      throw new Error('Recorder is not ready');
    }

    micRecorder.current.stopRecording();
    setMicDisconnected(true);
    props.onMicDisconected();
  }

  let isRecording = recordingStatus && !micDisconnected;
  let display =  isRecording ? 'display ' : 'd-none ' ;
  let disableState = props.disabled || mediaStream === null || micDisconnected || (recordingStatus && !stopEnabled)
  
  return <Container className={'d-flex justify-content-center'} fluid>
    <Row className={'justify-content-center' + (recordingStatus ? ' recorder' : ' ') + ( mobile ? ' w-100' : ' w-50') + (limitReached === true ? ' d-none' : ' display')} >
      
      {/** Clock */}
      <Col xs={4} lg={2} className={display + 'clockcol'}>
        {isRecording && <RecordingClock 
          timer={props.minDuration}
          limit={props.maxDuration}
          onTimerEnd={() => setStopEnabled(true)}
          onLimitReached={toggleRecorder}
          handleLimitModal={showLimitModal}
        />}
      </Col>
      
      {/** Audio Wave Visualizer */}
      <Col xs={4} lg={6} className={display }>
        {mediaStream !== null && isRecording && <AudioVisualizer mic={mediaStream} />}
      </Col>

      
      {/** Start/Stop button */}
      <Col xs={1} className={'buttoncol '}>
        <Button 
          className={'text-center' + (recordingStatus ? ' small-round-icon' : ' round-icon')}
          onClick={toggleRecorder}
          disabled={disableState}
        >
          <FontAwesomeIcon
            icon={recordingStatus ? faStop : faMicrophone}
            color={'#fff' }
          />
        </Button>
      </Col>

      {/** Skip button */}
      <Col  xs={1} className={'buttoncol '}>
        <SkipButton
            onSubmit={props.onSkipItem}
            disable={!props.skippable || isRecording}
            className= {'text-center' + (recordingStatus ? ' small-round-icon' : ' mx-2 round-icon')}
        />
      </Col>

    </Row>

    {/** Send recording when limit is reached button */}
    <Modal show={limitReached} backdrop={'static'} centered>
      <Modal.Body>
        <p>{getContent('recorder__limit_reached__modal_text')}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => setLimitReached(false)}>
          {getContent('recorder__limit_reached__modal_close')}
        </Button>
      </Modal.Footer>
    </Modal>

    {/** Mic disconnected modal */}
    <Modal show={micDisconnected} backdrop={'static'} centered>
      <Modal.Body>
        <p>{getContent('recorder__mic_disconnected__modal_text')}</p>

        {/** If stil synchronizing then show spiner */}
        {isDirty && <>
          <Row>
            {getContent('recorder__mic_disconnected__still_synching')}
          </Row>
          <RowSpinner show />
          </>}

      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" disabled={isDirty}>
          <Link
            to={'participant'}
            params={[participant_id]}
            className={'muted-link'}
          >
            {getContent('recorder__mic_disconnected__modal_close')}
          </Link>
        </Button>
        <Button variant="primary" disabled={isDirty}>
          <Link
            to={'resume_protocol'}
            params={[participant_id, props.protocolResID]}
            className={'muted-link'}
          >
            {getContent('recorder__mic_disconnected__modal_resume')}
          </Link>
        </Button>
      </Modal.Footer>
    </Modal>
  </Container>
};

export default Recorder;
import React, {FC, useEffect, useRef, useState} from 'react';

import Container from 'react-bootstrap/Container';

interface RecordingClockProps {
    timer?: number;
    limit?: number
    onTimerEnd?: () => void;
    onLimitReached?: () => void;
    handleLimitModal?: () =>void;
}

const RecordingClock:FC<RecordingClockProps> = (props) => {
    let [time, setTime] = useState(0);
    let interval = useRef<number>();

    const onTimerEnd = props.onTimerEnd || (() => {});
    const onLimitReached = props.onLimitReached || (() => {});
    const handleLimitModal = props.handleLimitModal || (() => {});

    
    useEffect(
        () => {
            interval.current = window.setInterval(() => {
                setTime(currTime => currTime + 1);
            }, 1000);
            
            return () => {
                window.clearInterval(interval.current);
            };
        }, []
    );

    // When timer is over, trigger onTimerEnd
    useEffect(
        () => {
            if (time === props.timer) {
                onTimerEnd()
            } else if (time === props.limit){
            handleLimitModal();
            onLimitReached();
            }
        },
        [time] // eslint-disable-line react-hooks/exhaustive-deps
    )

    // Print seconds in a nice format
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    let seconds_str = seconds < 10 ? '0' + seconds : seconds.toString();
    let minutes_str = minutes < 10 ? '0' + minutes : minutes.toString();
    return (
        <Container className={'text-left'}>
            {minutes_str}:{seconds_str}
        </Container>
    );
};

export default RecordingClock;
import React, { useEffect } from 'react';
import Apirest from '../../Content/Apirest';
import { PopulationDistribution } from '../../Dashboard/Protocol/types';

import { useDispatch } from 'react-redux';
import { SET_POPULATION_FEATURE_DISTRIBUTIONS } from '../../store/data/types';

const DataProvider: React.FC = () => {

    let dispatch = useDispatch();
    let saveDistributionsToStore = (populationFeatureDistributions: PopulationDistribution[]) => {

        // Create a Map for faster lookup
        const populationDistributionMap = new Map<string, PopulationDistribution[]>();

        populationFeatureDistributions.forEach((p: PopulationDistribution) => {
        const categoryId = p.protocol_item_category.id;
        const funcId = p.feature_extraction_func.id;
        const languageId = p.language.id;
        const key = `${categoryId}-${funcId}-${languageId}`;

        // append to array or create new array if doesn't exist
        if (populationDistributionMap.has(key)) {
            populationDistributionMap.get(key)!.push(p);
        } else {
            populationDistributionMap.set(key, [p]);
        }
        });



        dispatch({
            type: SET_POPULATION_FEATURE_DISTRIBUTIONS,
            payload: populationDistributionMap
        });
    }

    useEffect(() => {
        Apirest.get_feature_distribution(saveDistributionsToStore);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};

export default DataProvider;
import * as React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { boldRegexMatch, buildRegexExpession } from "../../Commons/Helpers";
import { useContent } from '../../Content/cms';
import { useFeatureToogle } from '../../ProtectedRoutes/FeatureToggle/hook';

// Import store
import {StoreState} from "../../store";

// Import interface
import {Participant} from "../interfaces";
import {Row as DataFrameRow} from '../../Commons/Components/DataFrame/types';

// Import components
import DataFrame from "../../Commons/Components/DataFrame";
import {
    faArchive,
    faFilter,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../../Commons/SiteMap/Link";
import Surveys from '../Surveys/Form';
import ProtocolLink from '../Protocol/protocolLink';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import { ARCHIVE_PARTICIPANT } from '../../store/participants/types';
import Apirest from '../../Content/Apirest';
import { IconButton } from '../../Commons/MultipleComponents';


interface FilteredParticipant {
    participant: Participant
    match: React.ReactNode
}

const ParticipantsList : React.FC = () => {

    let getContent = useContent();

    let {featureEnabled} = useFeatureToogle();

    const mobiles = require('is-mobile');
    let mobile = mobiles();
    
    // participant lists states
    let [participantsDataFrame, setParticipantsDataFrame] = useState<DataFrameRow[]>([]);
   
    // Filter participant states
    let [filterQuery, setFilterQuery] = useState<string>('');
    let [filteringParticipants, setFilteringParticipants] = useState<boolean>(false);
    let [filteredParticipants, setFilteredParticipants] = useState<DataFrameRow[]>([]);

    let participants = useSelector<StoreState, Participant[]>( 
        state => state.participants,
    );

    let filterTaskID = useRef<number|undefined>();
    let inputFilterRef = useRef<any>();

    const dispatch = useDispatch();
    const archiveParticipantInStore = (participant_id: number) => dispatch({
        type: ARCHIVE_PARTICIPANT,
        payload: participant_id
    });

    useEffect(
        () => {
            setParticipantsDataFrame(Participant2Dataframe(participants.filter(p => p.active)));
        },
        [participants, mobiles] // eslint-disable-line react-hooks/exhaustive-deps
    );


    const filterParticipants = (filter: string) => {

        if (filter === '') {
            setFilteredParticipants([]);
            setFilteringParticipants(false);
        }

        let regex = buildRegexExpession(encodeURI(filter).replace(' ',''));
        let newFilteredParticipants = [];

        for(let participant of participants) {

            // Define value to match
            let values = [
                participant.code, 
                participant.first_name + ' ' + participant.last_name,
                participant.last_name + ' ' + participant.first_name
            ];

            // Test all value to check
            for (let value of values) {
                let [matches, formattedMatch]= boldRegexMatch(regex, value);
                if (matches) {
                    newFilteredParticipants.push(
                        FilteredParticipant2Dataframe(
                            {participant: participant, match: formattedMatch}
                        )
                    );
                    break;
                }
            }
        }

        setFilteredParticipants(newFilteredParticipants);
        setFilteringParticipants(false);
    };

    const [filteredParticipantHeader, participantsHeader] = participantHeaders()

    const archiveParticipant = (participant_id: number) => {
        // Update participant in front and back independently
        // if backend update fails, the participant will be restored in the next refresh
        // and it's not a big problem but a bug to solve when they report it
        archiveParticipantInStore(participant_id);
        Apirest.archive_participant(participant_id.toString());
    }

    // NOTE: this fields does not distinguis between filtered or not because
    // the number of colmns is the same for both cases
    const columnswidth = [26,50,8,8,8];
    const columToggle = [
        false,
        mobile,
        !featureEnabled(['archiveParticipant']),
        !featureEnabled(['recordProtocol']),
        !featureEnabled(['recordSurvey'])
    ];

    function Participant2Row(participant: Participant): DataFrameRow {
        return [
            <Link to={'participant'} params={[participant.id]}>
                {!mobile && participant.code}
                {mobile && <>
                    <p className={'p-0 m-0'}>{participant.code}</p>
                    <small className={'text-muted'}>{participant.last_name} {participant.first_name}</small>
                </>}
            </Link>,
            <Link to={'participant'} params={[participant.id]}>
                {participant.last_name} {participant.first_name} 
            </Link>,
            <IconButton
                onClick={() => archiveParticipant(participant.id)}
                icon={{ icon: faArchive, color: "#5CB6E7" }}
                text={getContent('home__participants_list__archive__tooltip')}
            />,
            <ProtocolLink participant_id={participant.id.toString()} onlyIcon align={'text-center'}/>,
            <Surveys participant_id={participant.id.toString()} onlyIcon align={'text-center'}/>
        ]
    }

    function participantHeaders() {

        const filteredParticipantsHeader = [
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__match')}</p>,
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__full_name')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__archive')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__record')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__survey')}</p>
        ];

        const participantsHeader = [
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__code')}</p>,
            <p className={'mb-2'}>{getContent('home__participants_lists__participant_list__header__full_name')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__archive')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__record')}</p>,
            <p className={'text-center mb-2'}>{getContent('home__participants_lists__participant_list__header__survey')}</p>
        ];

        return [filteredParticipantsHeader, participantsHeader];
    }

    const delayedFilterParticipants = (filterQuery: string) => {
        setFilterQuery(filterQuery);
        clearTimeout(filterTaskID.current);
        filterTaskID.current = (setTimeout(
            () => {

                setFilteringParticipants(true);
                setTimeout(() => filterParticipants(filterQuery),0);
            },
            25
        ) as unknown) as number;
    };

    const onClear = () => {
        setFilterQuery('');
        (inputFilterRef as any).current.value ='';
    };

    const FilteredParticipant2Dataframe = (participant: FilteredParticipant) : DataFrameRow => {
        let row = Participant2Row(participant.participant);
        row[0] = (
            <Link to={'participant'} params={[participant.participant.id]} >
                {participant.match}
            </Link>
        );
        return row;
    };

    const Participant2Dataframe = (participants: Participant[]) : DataFrameRow[] => {
        return participants.sort((p1,p2) => p1.created_at < p2.created_at  ? 1 : -1).map(participant => Participant2Row(participant));
    }

    if (participants.length === 0){
        return (
            <div className={"viewParticipant"}>
                <h5 className={'mt-4 p-2 text-primary '}>
                    {getContent("home__participants_lists__all_participants__title")}
                </h5>
                <Alert variant={'warning mt-3'}>
                    {getContent("home__participants_lists__alert_empty_list")}
                </Alert>
            </div>
        );
    }


    return (
        <Container className={'mt-3 '}>

            {/** Participant Filter form */}
            {participantsDataFrame.length > 0 && <Row className={'d-flex justify-content-end '}>
                <Col xs={12} md={5} className={'search-div'}>
                    <InputGroup className={"mb-2"}>
                        <InputGroup.Prepend >
                            <InputGroup.Text className={'bg-white'}>
                                {filteringParticipants ? <Spinner animation={'border'} size={'sm'}/> : <FontAwesomeIcon icon={faFilter}/>}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            id={"inlineFormInputGroup"}
                            ref={inputFilterRef}
                            placeholder={getContent("home__participants_lists__filtered_participants__placeholder")}
                            onChange = {(event: any) => delayedFilterParticipants(event.target.value)}
                        />
                        <InputGroup.Prepend  className={'filter-search-reset'}>
                            <button onClick={onClear} className={'bg-white no-border'}> <FontAwesomeIcon icon={faTimes} color={ filterQuery === '' ? '#fff' : '#495057'}/> </button>
                        </InputGroup.Prepend>
                    </InputGroup>
                </Col>
            </Row>}

            {/** Participant list when THERE IS filter applied */}
            {filterQuery !== '' && <Row className={'viewParticipant'}>
                <Col>
                    <h5 className={'p-2 text-primary'}>
                        {getContent("home__participants_lists__filtered_participants__title")}
                    </h5>
                    <DataFrame
                        header={filteredParticipantHeader}
                        className={'header-left header-bordered'}
                        columnWidths={columnswidth}
                        hiddenColumns={columToggle}
                        dataframe={filteredParticipants }
                        columnClassName={'text-center'}
                        showAllOption={false}
                    />
                </Col>
            </Row>}

            {/** Participant list when THERE IS NO filter applied */}
            {filterQuery === '' && <Row className={'viewParticipant'}>
                <Col>
                    <h5 className={'p-2 text-primary'}>
                        {getContent("home__participants_lists__all_participants__title")}
                    </h5>
                    {participantsDataFrame.length > 0 && <DataFrame
                        header={participantsHeader}
                        className={'header-left header-bordered'}
                        columnWidths={columnswidth}
                        hiddenColumns={columToggle}
                        dataframe={participantsDataFrame}
                        pageSize={20}
                        showAllOption={true}
                    />}

                    {/* If there is participants, but are all inactive, then show an alert */}
                    {participantsDataFrame.length === 0 && <Alert variant={'warning mt-3'}>
                        {getContent("home__participants_lists__alert_empty_list")}
                    </Alert>}
                </Col>
            </Row>}

        </Container>
    );
};

export default ParticipantsList;

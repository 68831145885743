import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContent } from '../../../Content/cms';
import { useDispatch, useSelector } from 'react-redux';
import Apirest from '../../../Content/Apirest';
import { useFeatureToogle } from '../../../ProtectedRoutes/FeatureToggle/hook';

import { StoreState } from '../../../store';
import { RESTORE_SURVEY_RESPONSE, SET_SURVEY_RESPONSES } from '../../../store/surveys/types';
import { SurveyResponse } from '../types';

import DataFrame from '../../../Commons/Components/DataFrame';
import { IconButton } from '../../../Commons/MultipleComponents';
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';


const ArchivedSurveyResponses : React.FC = () => {

    let getContent = useContent();

    let {featureEnabled} = useFeatureToogle();

    let {participant_id} = useParams<{participant_id: string}>();

    let [show, setShow] = React.useState<boolean>(false);

    let surveyResponses = useSelector<StoreState, SurveyResponse[]>(
        state => state.survey.responses.filter(p => !p.active));

    // dispatch survey responses to store
    const dispatch = useDispatch();
    const setSurveyResponseInStore = (surveyResponses: SurveyResponse[]) => dispatch({
        type: SET_SURVEY_RESPONSES,
        payload: surveyResponses
    });
    const restoreSurveyResponseInStore = (surveyResponseId: number) => dispatch({
        type: RESTORE_SURVEY_RESPONSE,
        payload: surveyResponseId
    });

    useEffect(
        () => {
            Apirest.get_participant_survey_responses(
                participant_id, setSurveyResponseInStore
            )
        },
        [participant_id] // eslint-disable-line react-hooks/exhaustive-deps
    )

    function restoreSurveyResponse(participant_id: string, survey_response_id: number) {
        // Update survey in front and back independently
        // if backend update fails, the survey will be restored in the next refresh
        // and it's not a big problem but a bug to solve when they report it
        restoreSurveyResponseInStore(survey_response_id);
        Apirest.restore_survey_response(
            participant_id,
            survey_response_id.toString()
        );
    }

    function sureveyResponse2row(survey_response: SurveyResponse) {

        return [
            survey_response.date_created.format('ll'),

            <IconButton
                onClick={() => restoreSurveyResponse(participant_id, survey_response.id)}
                icon={{ icon: faTrashRestore, color: "#5CB6E7" }}
                text={getContent('archived__survey_response__restore__tooltip')}
            />
        ]
    }

    function allSurveyResponses2row(survey_responses: SurveyResponse[]) {
        return survey_responses.map(response => sureveyResponse2row(response));    
    }

    // Return a modal with the data of the survey responses in a dataframe
    return <>
        <button onClick={() => setShow(true)} className={'link-button'}>
            {getContent('archived__survey_response__menu_btn')}
        </button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{getContent('archived__survey_response__title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {surveyResponses.length > 0 && <DataFrame
                    dataframe={allSurveyResponses2row(surveyResponses)}
                    pageSize={5}
                    striped={true}
                    clickeableRow={true}
                    hiddenColumns={[false, !featureEnabled(['archiveSurveyRes'])]}
                    columnClassName={'text-right'}
                />}

                {surveyResponses.length === 0 && <p>
                    <Alert variant={'warning'}>
                        {getContent('archived__survey_response__empty')}
                    </Alert>
                </p>}
            </Modal.Body>
        </Modal>
    </>;
}

export default ArchivedSurveyResponses;
import React from 'react';
import { useFeatureToogle } from './hook';

import LoaderSpinner from '../../Commons/Components/LoaderSpinner';

interface FeatureToggleProps {
    featureNames: string[],
    showSpinner?: boolean
}

const FeatureToggle: React.FC<FeatureToggleProps> = (props) => {

    const {children, featureNames, showSpinner} = props;

    let {featureEnabled} = useFeatureToogle();

    if (featureEnabled(featureNames)) {
        return <>{children}</>;
    }

    if (showSpinner) {
        return <LoaderSpinner/>
    }

    return null;
}

export default FeatureToggle;

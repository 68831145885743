import * as React from "react";
import {useSelector, useDispatch} from "react-redux";

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {StoreState} from "../../store";
import { SET_LOCAL_CODE, LocalCode } from "../../store/i18n/types";


const AuthLanguageForm : React.FC = () => {
   
    let dispatch = useDispatch()
    let currentCodeLang = useSelector((state: StoreState) => state.i18n.localCode)

    const setLocalLang = (code: LocalCode) => dispatch({
        type: SET_LOCAL_CODE,
        payload: code
    });

    const languages : {name: string, value: LocalCode}[] = [
        { name: 'Español', value: 'ES' },
        { name: 'English', value: 'EN' },
        { name: 'Português', value: 'PT' },
        { name: 'Français', value: 'FR' },
      ];

        return(
            <ButtonGroup toggle className={'d-block mt-3'}>
                {languages.map((lang, idx) => (
                <ToggleButton
                    key={idx}
                    type="radio"
                    className={'m-2'}
                    name="lang"
                    value={lang.value}
                    checked={currentCodeLang === lang.value}
                    onChange={(e) => setLocalLang(e.currentTarget.value as LocalCode)}
                >
                    {lang.name}
                
                </ToggleButton>))}
        </ButtonGroup>
    );
} 


export default AuthLanguageForm;
import React from "react";
import { getDesign } from "../../WhiteLabeling"; 

// Import components
import {Container, Row, Col} from "react-bootstrap";

//Import Styles
import "./index.css"

const LoaderSpinner: React.FC = () => {
    
    const whiteLabels = getDesign();

    return <Container fluid className={'bg-light ignore-content-wrapper'}>
        <Row>
            <Col className={'text-center'}>
                <div className={'spinner-container'}>
                    <img
                        src={whiteLabels.logos.spinner}
                        alt="loading"
                        className={'logo-spinner'}
                    />
                </div>
            </Col>
        </Row>
    </Container>
};

export default LoaderSpinner;

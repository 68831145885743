// Create a Reat component that will display the activity history of the admin user as dataframe
import React from 'react';
import { useContent } from '../../../Content/cms';

import { ActivityHistory, Activity } from './types';

import Modal from 'react-bootstrap/Modal';
import DataFrame from '../../../Commons/Components/DataFrame';

interface AdminActivityHistoryProps {
    show: boolean;
    onHide: () => void;
    events: ActivityHistory;
}

const ActivityComparator = (a: Activity, b: Activity) => {
    if (a.date_created.isBefore(b.date_created)) {
        return 1;
    } else if (a.date_created.isAfter(b.date_created)) {
        return -1;
    }

    return 0;
};

const AdminActivityHistory: React.FC<AdminActivityHistoryProps> = (props) => {
    
    let getContent = useContent();

    // merge all events into a single array;
    let activities = props.events.protocolres.concat(props.events.surveyres).concat(props.events.participants);

    // sort by date_created
    activities = activities.sort(ActivityComparator);

    let data = activities.map((activity) => [
        activity.date_created.format('L'),
        activity.user,
        activity.examiner,
        activity.participant,
        activity.action
    ]);

    let dataframeHeader = [
        <p>{getContent('admin_activity_history__dataframe_header__date')}</p>,
        <p>{getContent('admin_activity_history__dataframe_header__user')}</p>,
        <p>{getContent('admin_activity_history__dataframe_header__examiner')}</p>,
        <p>{getContent('admin_activity_history__dataframe_header__participant')}</p>,
        <p>{getContent('admin_activity_history__dataframe_header__action')}</p>
    ];

    return (
        <Modal show={props.show} onHide={props.onHide} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Activity History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataFrame 
                    header={dataframeHeader}
                    dataframe={data}
                    pageSize={10}
                />
            </Modal.Body>
        </Modal>
        
    );
}

export default AdminActivityHistory;
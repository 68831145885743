import React, {FC, useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Recorder, { RecorderProps } from '../Recorder';
import { useContent } from '../../../Content/cms';

import { ProtocolData, Item } from '../types';
import { StoreState } from '../../../store';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RowSpinner } from '../../../Commons/MultipleComponents';
import MicPermission from './MicPermission';
import DeviceSelection from './DeviceSelection';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


interface ProtocolItemsOwnProps {
    protocol: ProtocolData
    startIdx: number
    onProtocolEnd: () => void
    onRecordingStop: (item: Item, blob: Blob) => void
    onSkipped: (item: Item, purpose:string) => void
}

export type ProtocolItemsProps = 
    & Omit<RecorderProps, 'disabled' | 'deviceID' | 'onSkipItem' | 'onRecordingStop' | 'skippable'>
    & ProtocolItemsOwnProps;

const ProtocolItems:FC<ProtocolItemsProps> = (props) => {

    const {onProtocolEnd, onRecordingStart, onSkipped, onMicDisconected} = props;

    let getContent = useContent();

    let isDirty = useSelector<StoreState, boolean>((state: StoreState) => state.dirtyState['protocol_recorder'] || false);

    // Store the current item index
    let [currentItemIdx, setCurrentItemIdx] = useState<number>(props.startIdx);

    // disable/enable the recorder
    let [recorderDisabled, setRecorderDisabled] = useState<boolean>(true);

    // disable/enable the media
    let [mediaDisabled, setMediaDisabled] = useState<boolean>(false);

    // Store mic permission state
    let [micPermission, setMicPermission] = useState<boolean>(false);

    // Store selected device
    let [selectedDevice, setSelectedDevice] = useState<string|undefined>();

    // Store media loaded state
    let [mediaLoaded, setMediaLoaded] = useState<boolean>(false);

    // call onProtocolStart callback when protocol is loaded
    useEffect(
        () => {
            loadMedia(props.protocol).then(() => setMediaLoaded(true));
        }, [props.protocol] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            // Update the item recording state if there is item
            if (currentItemIdx < props.protocol.items.length) {
                let item = props.protocol.items[currentItemIdx];
                let hasAudioOrVideo = (
                    (item.audio !== undefined) ||
                    (item.video !== undefined)
                )
                setRecorderDisabled(hasAudioOrVideo);

                // Allow media to be played again
                setMediaDisabled(false);
            }
        }, [currentItemIdx] // eslint-disable-line react-hooks/exhaustive-deps
    )

    function loadMedia(protocol: ProtocolData): Promise<void[]> {
        let promises: Promise<void>[] = [];
        protocol.items.forEach(item => {
            if (item.image) {
                promises.push(new Promise((resolve, reject) => {
                    let img = new Image();
                    img.onload = () => resolve();
                    img.onerror = () => reject();
                    img.src = item.image!;
                }));
            }
            if (item.audio) {
                promises.push(new Promise((resolve, reject) => {
                    let audio = new Audio();
                    audio.onloadeddata = () => resolve();
                    audio.onerror = () => reject();
                    audio.src = item.audio!;
                }));
            }
            if (item.video) {
                promises.push(new Promise((resolve, reject) => {
                    let video = document.createElement('video');
                    video.onloadeddata = () => resolve();
                    video.onerror = () => reject();
                    video.src = item.video!;
                }));
            }
        });
        return Promise.all(promises);
    }

    function goToNextItem() {
        // Update the current item index
        setCurrentItemIdx(currentItemIdx + 1);

        // If it's the last item, call the parent callback
        if (currentItemIdx === props.protocol.items.length - 1) {
            onProtocolEnd();
            return;
        }
    }

    // The stop recording callback must call the parent function.
    function onRecordingStop(blob: Blob) {

        // Call the parent function
        props.onRecordingStop(currentItem, blob);

        goToNextItem();
    }

    function onSkipItem(purpose: string) {

        // Call the parent function
        onSkipped(currentItem, purpose);

        goToNextItem();
    }

    function onMediaEnd() {
        setRecorderDisabled(false);
        setMediaDisabled(true);
    }

    if (micPermission === false) {
        return <MicPermission onSuccess={() => setMicPermission(true)} />;
    }
    
    if (selectedDevice === undefined) {
        return <DeviceSelection onSelect={(deviceID) => setSelectedDevice(deviceID)} />;
    }

    if (mediaLoaded === false) {
        return <RowSpinner className={'mt-3'} show/>;
    }

    if (currentItemIdx >= props.protocol.items.length) {
        return null;
    }

    let currentItem = props.protocol.items[currentItemIdx]!;
    let completed = Math.floor(((currentItemIdx+1) / props.protocol.items.length) * 100);
    let mediaEndedPlaceholder = 
    <div className={'bg-info-light m-auto text-center w-50 media-place-holder'}>
            <h3>{getContent('protocol_item__media_ended')}</h3>
    </div>


    return (
        <Container className={'protocol d-flex  flex-column justify-content-between'} fluid>
            <Row className={'d-flex flex-column'}>
            <h3 className='protocol-header'>
                {props.protocol.name} 
                <FontAwesomeIcon 
                    className={'ml-3 ' + ( isDirty  ? ' display' : ' d-none')}
                    icon={faCircleNotch}
                    color={'#68a7e6'}
                    spin={true}
                />
            </h3>
            <Row >
                <Col sm={1} />
                <Col sm={10}>
                    <div className="progress">
                        <div 
                            className={"progress-bar"}
                            role={"progressbar"}
                            style={{width: completed.toString() + '%'}}
                            aria-valuenow={completed}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >({currentItemIdx+1}/{props.protocol.items.length})</div>
                    </div>
                </Col>
                <Col sm={1} />
            </Row>
            <Row className='task-body '>
                <Col>
                    <Container fluid>

                    {/** Show title if there is title */}
                    {currentItem.title && <Row>
                        <Col>
                            <h5 className='protocol-item-title'>{currentItem.title}</h5>
                        </Col>
                    </Row>}

                    {/** Show the trigger as parsed HTML*/}
                    <Row>
                        <Col>
                            <div
                                className={'text-left'}
                                dangerouslySetInnerHTML={{'__html': currentItem.trigger}}
                            />
                        </Col>
                    </Row>

                    {/** Show the image if there is image*/}
                    {currentItem.image && 
                        <Row className='mt-3'>
                            <Col className={'protocol-item-stimuly'}>
                                <img className={'img-fluid m-auto'} alt='protocol item' src={currentItem.image} />
                            </Col>
                        </Row>
                    }

                    {/** Show the audio if there is audio*/}
                    {currentItem.audio && 
                        <Row className='mt-3'>
                            <Col className={('protocol-item-stimuly' + (mediaDisabled ? ' d-none' : ' d-block'))}>
                                <audio
                                    title={currentItem.trigger}
                                    onEnded={onMediaEnd} 
                                    src={mediaDisabled? '#' : currentItem.audio}
                                    controls 
                                />
                            </Col>
                            <Col className={mediaDisabled ? 'display' : 'd-none'}>
                            {mediaEndedPlaceholder}
                            </Col>
                        </Row>
                    }

                    {/** Show the video if there is video*/}
                    {currentItem.video &&
                        <Row className='mt-3 protocol-item-stimuly'>
                            <Col  className={('protocol-item-stimuly' + (mediaDisabled ? ' d-none' : ' d-block'))}>
                                <video
                                    className={'img-fluid h-auto'}
                                    onEnded={onMediaEnd}
                                    src={mediaDisabled? '#' : currentItem.video}
                                    controls
                                />
                                 {currentItem.video.includes('tell_item_8_video_compressed.mp4') && 
                                    <small className='font-italic d-block'>“FUGU” (2012), Short animation film by Arthur Philippe.</small>}
                                {currentItem.video.includes('fishing_boat.mp4') && 
                                    <small className='font-italic d-block'>This video was cut from the original version: CGI animated short film (2018), “Joy &amp; Heron”.<br></br>
                                    by Passion Pictures Australia. <a href={"https://www.youtube.com/watch?v=1lo-8UWhVcg"} className={'d-inline'}> https://www.youtube.com/watch?v=1lo-8UWhVcg </a><br></br>
                                    This video is licenced under CC BY 4.0:<a href={"https://creativecommons.org/licenses/by/4.0/deed.en"} className={'d-inline'} > https://creativecommons.org/licenses/by/4.0/deed.en</a></small>}
                                    
                            </Col>
                            <Col className={mediaDisabled ? 'display' : 'd-none'}>
                            {mediaEndedPlaceholder}
                            </Col>
                        </Row>
                    }
                    </Container>
                </Col>
            </Row>
            </Row>
            <Row>
                <Recorder
                    deviceID={selectedDevice}
                    onRecordingStart={onRecordingStart}
                    onRecordingStop={onRecordingStop}
                    onMicDisconected={onMicDisconected}
                    onSkipItem={onSkipItem}
                    protocolResID={props.protocolResID}
                    disabled={recorderDisabled}
                    minDuration={currentItem.min_duration}
                    maxDuration={currentItem.max_duration}
                    skippable={currentItem.skippable}
                />
            </Row>
        </Container>
    );
};

export default ProtocolItems;
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useContent } from '../../Content/cms';
import Apirest from '../../Content/Apirest';

import DataFrame from '../../Commons/Components/DataFrame';
import Modal from 'react-bootstrap/Modal';
import { IconButton } from '../../Commons/MultipleComponents';
import { faDownload, faAngry, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { PopUpError, PopUpSuccess } from '../../Commons/Helpers';


import moment, { Moment } from 'moment';
import RenderIfManager from '../../Admin/RenderIfManager';


/** Deprecate the fields at the end and bring all the examiner info as it could be different that the user */

interface Takeout {
    id: number
    uuid: string
    file_name: string
    created_at: Moment
    updated_at: Moment
    deleted_at: null | Moment
    status: "failed" | "complete" | "pending"
    examiner: number

    md5: null    
    number_of_times_request_presigned: number
    size: number
    user: number
}

const Takeout : React.FC = () => {

    const mobiles = require('is-mobile');
    let mobile = mobiles();
    let getContent = useContent();

    let [show, setShow] = useState<boolean>(false);
    let [creating, setCreating] = useState<boolean>(false);
    let [takeouts, setTakeouts] = useState<Takeout[]>([]);
    let [orgTakeouts, setOrgTakeouts] = useState<Takeout[]>([]);


    useEffect(getTakeouts, []);

    useEffect(
        () => {
            if (takeouts.some(t => t.status === 'pending')) {
                setTimeout(getTakeouts, 30*1000);
            }
        }, [takeouts]
    )

    useEffect(getOrgTakeouts, []);

    useEffect(
        () => {
            if (orgTakeouts.some(t => t.status === 'pending')) {
                setTimeout(getTakeouts, 30*1000);
            }
        }, [orgTakeouts]
    )

    function getTakeouts() {
        Apirest.get_all_takeouts(setTakeouts);
    }

    function getOrgTakeouts() {
        Apirest.get_all_org_takeouts(setOrgTakeouts);
    }

    function createTakeout() {
        setCreating(true);
        Apirest.create_takeout(
            () => {
                setCreating(false);
                PopUpSuccess(getContent('takeout__modal_success'));
                getTakeouts();
            },
            (response) => {
                setCreating(false);
                PopUpError(
                    getContent('takeout__modal__error__' + response.responseJSON.error)
                )
            }
         );
    }

    function createOrgTakeout() {
        setCreating(true);
        Apirest.create_org_takeout(
            () => {
                setCreating(false);
                PopUpSuccess(getContent('takeout__modal_success'));
                getOrgTakeouts();
            },
            (response) => {
                setCreating(false);
                PopUpError(
                    getContent('takeout__modal__error__' + response.responseJSON.error)
                )
            }
         );
    }

    const ICONS = {
        'failed': faAngry,
        'complete': faDownload,
        'pending': faHourglassHalf
    }

    /** Apirest download_takeout will silent fail if not completed
        and then no action will be taken as expected. 
        Thus, no need to check if status complete for the onClick action */
    function takeout2row(takeout: Takeout) {
        return [
            <div>{takeout.created_at.format('LL')}</div>,
            <IconButton
                onClick={() => Apirest.download_takeout(takeout.uuid)}
                icon={{ icon: ICONS[takeout.status], color: "#5CB6E7"}}
                text={getContent('takeout__tooltip__' + takeout.status)}
            />
        ]
    }

    function allTakeout2Row(takeouts: Takeout[]) {
        return takeouts.map(takeout => takeout2row(takeout));    
    }

    function creationDisabled(takeouts: Takeout[]) {
        return creating || takeouts.some(t => t.created_at.isSame(moment(), 'date'));
    } 

    // Return a modal with the data of the takeouts in a dataframe
    return <>
        <button onClick={() => setShow(true)} className={'link-button'}>
            {getContent('takeout__menu__btn')}
        </button>
        <Modal show={show} onHide={() => setShow(false)} size={'lg'} centered >
            <Modal.Header closeButton>
                <Modal.Title>{getContent('takeout__modal__title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Container className={'p-3'} >
                    <Row className={'mb-3'}>
                        <div dangerouslySetInnerHTML={{__html: getContent('takeout__modal__body')}} />
                    </Row>
                    <Container className={'border-bototm'}>
                    <Row>
                        <h3 className={'text-primary'}>{getContent('takeout__modal__examiner_takeouts__subtitle')}</h3>
                    </Row>
                    <Row className={'pt-3'}>
                        {takeouts.length > 0 && <DataFrame
                            dataframe={allTakeout2Row(takeouts)}
                            pageSize={5}
                            striped={true}
                            clickeableRow={true}
                            columnClassName={'text-right'}
                        />}
                        <Button 
                            onClick={createTakeout}
                            disabled={creationDisabled(takeouts)}
                            className={mobile ? 'w-100' : 'w-50'}
                            >
                            {!creating && getContent('takeout__modal__create_btn')}
                            {creating && <Spinner animation="border" variant="primary" />}
                        </Button>

                        {takeouts.length === 0 && <div>
                            <Alert variant={'warning'} className={'p-3 w-100'}>
                                {getContent('takeout__modal__empty')}
                            </Alert>
                        </div>}
                    </Row>
                    </Container>
                    <RenderIfManager>
                        <Container className={'mt-3'}>
                            <Row className={'mt-3'}>
                                <h3 className={'text-primary'}>{getContent('takeout__modal__org_takeouts__subtitle')}</h3>
                            </Row>
                            <Row className={'pt-3'}>
                                {orgTakeouts.length > 0 && <DataFrame
                                    dataframe={allTakeout2Row(orgTakeouts)}
                                pageSize={5}
                                striped={true}
                                clickeableRow={true}
                                    columnClassName={'text-right'}
                                />}
                                <Button
                                    onClick={createOrgTakeout}
                                    disabled={creationDisabled(orgTakeouts)}
                                    className={mobile ? 'w-100' : 'w-50'}
                                >
                                    {!creating && getContent('takeout__modal__create_btn')}
                                    {creating && <Spinner animation="border" variant="primary" />}
                                </Button>

                                {orgTakeouts.length === 0 && <div>
                                    <Alert variant={'warning'} className={'p-3 w-100'}>
                                        {getContent('takeout__modal__empty')}
                                    </Alert>
                                </div>}
                            </Row>
                        </Container>
                    </RenderIfManager>
                </Container>
            </Modal.Body>
        </Modal>
    </>;
}

export default Takeout;
import { UserInfo } from "../../interfaces/user";
import { AllActionsTypes } from "../actions_all";

// The global UserInfo store state interface
export interface UserStoreState {
    info?: UserInfo
};

// The UserInfo store action names
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

// The UserInfo store actions interfaces
interface setUserInfoAction {
    type: typeof SET_USER_INFO
    payload: UserInfo
}

interface clearUserInfoAction {
    type: typeof CLEAR_USER_INFO
}

// Export the final UserInfo store action states as a single type
export type UserActionTypes =
    | setUserInfoAction
    | clearUserInfoAction
    | AllActionsTypes;
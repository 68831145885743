import * as React from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router";



// Import interfaces
import {Participant} from "../../../../Dashboard/interfaces";

// Import store
import {StoreState} from "../../../../store";
import {useContent} from '../../../../Content/cms';


// Import Components
import { Col, Container, Row, Nav } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';

import Menu from "./Menu";
import Survey from "../../../../Dashboard/Surveys/Form";
import ProtocolLink from "../../../../Dashboard/Protocol/protocolLink";

import "./ShortHeader.css";
import FeatureToggle from '../../../../ProtectedRoutes/FeatureToggle';


interface HeaderProps {
    fetch_data?: boolean
    runTutorial?: () => void
}

const Header : React.FC<HeaderProps> = (props) => {

    let {runTutorial} = props;
    let getContent = useContent();

    const mobiles = require('is-mobile');
    const isImpersonify = JSON.parse(localStorage.getItem('impersonification') || "null");

    let mobile = mobiles();

    let {participant_id} = useParams<{participant_id: string}>();
    let participant = useSelector<StoreState, Participant | undefined>(state => {
        let pid = parseInt(participant_id);
        return state.participants.find(p => p.id === pid);
    });

    if (!participant) return null;

    let toggleMargin = isImpersonify ? ' pt-3' : 'pt-0';
    
    return (
        <Container fluid className={(' bg-white sticky-top ') + toggleMargin} >
            <Row className={'pb-0 border-bottom border-muted'}>
                <Col  xs={ mobile ? 12 : 5} className={'d-flex justify-content-end flex-column pl-0 ml-0 pb-2 mb-0'}>
                    <Nav  className={'no-border justify-content-start pl-0 mb-0 pb-0'}>
                        <Menu runTutorial={runTutorial} />
                        { participant.active === false &&
                        <Badge variant="dark" className={'p-3 font-weight-bold'}> {getContent('participant__archived__flag')}</Badge>
                        }
                    </Nav>

                </Col>
                <Col className={'d-flex justify-content-end  align-items-end pb-2 pt-2' + (mobile ? ' mobile-NavRow' : '')}>
                    <Row >
                        <Nav className={"justify-content-end"}>
                            <FeatureToggle featureNames={['recordSurvey']}>
                                <Nav.Item>
                                    <Survey participant_id={participant_id} />
                                </Nav.Item>
                            </FeatureToggle>
                            <FeatureToggle featureNames={['recordProtocol']}>
                                <Nav.Item>
                                    <ProtocolLink participant_id={participant_id} />
                                </Nav.Item>
                            </FeatureToggle>
                        </Nav>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;

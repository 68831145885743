import { CLEAR_ALL } from "../actions_all";
import { ExaminerActionTypes, ExaminerStoreState, SET_EXAMINER, CLEAR_EXAMINER } from "./types";
import ReactGA from 'react-ga';

const initialState: ExaminerStoreState = null;

export function ExaminerReducer(state= initialState, action: ExaminerActionTypes): ExaminerStoreState {
    switch (action.type) {

        case SET_EXAMINER:
            ReactGA.set({ userId: action.payload.id });
            return {...action.payload};

        case CLEAR_ALL:
        case CLEAR_EXAMINER:
            return initialState;

        default:
            return state;
    }
}
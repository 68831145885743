import { CLEAR_ALL } from "../actions_all";
import {ServerActionTypes, ServerState, SET_SERVER_ERROR, CLEAR_SERVER_ERROR, SET_LOGGED_IN} from "./types";

const initialState: ServerState = {
    logged_in: false
};

export function ServerReducer(state=initialState, action: ServerActionTypes): ServerState {

    let newState = {...state};

    switch (action.type) {

        case SET_SERVER_ERROR:
            newState.error = action.payload;
            break;

        case SET_LOGGED_IN:
            newState.logged_in = action.payload;
            break;

        case CLEAR_ALL:
        case CLEAR_SERVER_ERROR:
            newState = initialState;
            break;

        default:
            break;
    }

    return newState;
}
import { ContentDict } from "../../Content/cms";
import { AllActionsTypes } from "../actions_all";

export type LocalCode = 'ES'|'EN'|'FR'|'PT';

export type Locale = string;

export interface i18nState {
    locale?: Locale,
    content?: ContentDict,
    localCode:  LocalCode
}

export const SET_LOCALE = 'SET_LOCALE';
export const SET_CONTENT = 'SET_CONTENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const SET_LOCAL_CODE = 'SET_LOCAL_CODE';
export const CLEAR_I18N = 'CLEAR_I18N';

export interface setLocale {
    type: typeof SET_LOCALE,
    payload: Locale
}

export interface setContent {
    type: typeof SET_CONTENT,
    payload: ContentDict
}

export interface deleteContent {
    type: typeof DELETE_CONTENT
}
export interface setLocalCode {
    type: typeof SET_LOCAL_CODE
    payload:  LocalCode
}

export interface clearI18NAction {
    type: typeof CLEAR_I18N
}

export type i18nActionTypes =
    | setLocale
    | setContent
    | deleteContent
    | setLocalCode
    | clearI18NAction
    | AllActionsTypes;

// create a react page to only show children if user.info.roles.is_examiner is true
import * as React from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import SiteMap from "../Commons/SiteMap";

import { StoreState } from "../store";
import { UserStoreState } from "../store/user/types";
import NotFound from "../NotFound";


const Admin: React.FC = ({children}) => {
    let user = useSelector<StoreState, UserStoreState>(state => state.user);

    if (!user.info || !user.info.roles.is_manager) {
        return null;
    }

    return (
        <Switch>
            {SiteMap.getAdminRoutingMap()}
            <Route path="/admin" component={NotFound} />
        </Switch>
    );
};

export default Admin;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Apirest from "../Content/Apirest";
import SiteMap from "../Commons/SiteMap";

// Import Store
import { StoreState } from '../store';
import { UserStoreState } from '../store/user/types';
import { SET_EXAMINER, CLEAR_EXAMINER } from "../store/examiner/types";
import { SET_PARTICIPANTS, CLEAR_PARTICIPANTS } from "../store/participants/types";
import { SET_SURVEYS, CLEAR_SURVEY } from '../store/surveys/types';

// Import types
import { Examiner, Participant } from "./interfaces";
import { Survey } from "./Surveys/types";

// Import JSX Components
import LoaderSpinner from "../Commons/Components/LoaderSpinner";
import NotFound from "../NotFound";

const Dashboard: React.FC = ({children}) => {

    let user = useSelector<StoreState, UserStoreState>(state => state.user);

    let [examinerLoaded, setExaminerLoaded] = useState<boolean>(false);
    let [participantsLoaded, setParticipantsLoaded] = useState<boolean>(false);
    let [surveysLoaded, setSurveysLoaded] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {

        // If the user is not an examiner, we don't show the page and we don't need to load the data.
        // Just in case, for security reasons, we clear the data.
        if (!user.info || !user.info.roles.is_examiner) {

            dispatch({
                type: CLEAR_EXAMINER
            });

            dispatch({
                type: CLEAR_PARTICIPANTS
            });

            dispatch({
                type: CLEAR_SURVEY
            });
            return;
        }
        
        Apirest.get_examiner_info((response: Examiner) => {
            dispatch({
                type: SET_EXAMINER,
                payload: response
            });

            // Set doctor ID to analytics to be able to separate by doctor
            ReactGA.set({ userId: response.id });

            setExaminerLoaded(true);
        });

        Apirest.get_participants((response: Participant[]) => {
            dispatch({
                type: SET_PARTICIPANTS,
                payload: response
            });

            setParticipantsLoaded(true);
        })

        Apirest.get_examiner_surveys((response: Survey[]) => {
            dispatch({
                type: SET_SURVEYS,
                payload: response
            });

            setSurveysLoaded(true);
        });

    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    // If the user is not an examiner, we don't show the page
    if (user.info && !user.info.roles.is_examiner) {
        return null;
    }

    if (!examinerLoaded || !participantsLoaded || !surveysLoaded) {
        return <LoaderSpinner />
    }

    return (
        <Switch>
            {SiteMap.getDashboardRoutingMap()}
            <Route path={'/dashboard'} component={NotFound} />
        </Switch>
    );
};

export default Dashboard;

import { useSelector } from "react-redux";
import ServerConnection, {ResponseCallback} from "../ServerConnection";
import config from "../../Config";
import urls from "./urls.json";

// Import the store
import { store, StoreState } from '../../store';
import { Locale } from "../../store/i18n/types";

// Recursive type for content.json
type ContentLeaf = {
    text: string
    locale: {
        lang: string
        code: Locale,
        country: string
    }
}
export interface ContentDict {
    [key: string] : ContentLeaf
}

export type getContentFunction = (key: string) => string;

export function getContentStatic(key: string) : string {

    let content = store.getState().i18n.content;

    if (content !== undefined && content[key] !== null && content[key] !== undefined) {
        return content[key].text;
    }
    return key;
}

export function useContent() {

    let content = useSelector<StoreState, ContentDict|undefined>(
        state => state.i18n.content
    );

    return (key: string) : string => {
        if (content !== undefined && content[key] !== null && content[key] !== undefined) {
            return content[key].text;
        }
        return key;
    };
} 

const CMS = {

    conextion: new ServerConnection(config.cms.baseURL),

    get_content: (locale: string, success: ResponseCallback) => {

        CMS.conextion.get(
            urls.get_content.replace('{locale}',locale),
            {},
            success
        )
    },

    get_locales: (success: ResponseCallback<string[]>) => success(['es-ES', 'es-AR', 'es-MX', 'en-US'])

    /*
    CMS.conextion.get(
        urls.get_locales,
        {},
        success
    )*/
};

export default CMS;
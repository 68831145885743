import { CLEAR_ALL } from "../actions_all";
import { ProtocolActionTypes, ProtocolStoreState, SET_PROTOCOL_RES, CLEAR_PROTOCOL_RES, ARCHIVE_PROTOCOL_RES, RESTORE_PROTOCOL_RES } from "./types";

const initialState: ProtocolStoreState = {
    all_protocolres: [],
};

export function ProtocolReducer(state= initialState, action: ProtocolActionTypes): ProtocolStoreState {
    switch (action.type) {

        case SET_PROTOCOL_RES:
            return {
                all_protocolres: [...action.payload]
            }

        case ARCHIVE_PROTOCOL_RES:
            return {
                all_protocolres: state.all_protocolres?.map(p => {
                    if (p.id === action.payload) {
                        p.active = false;
                    }
                    return p;
                })
            }
        
        case RESTORE_PROTOCOL_RES:
            return {
                all_protocolres: state.all_protocolres?.map(p => {
                    if (p.id === action.payload) {
                        p.active = true;
                    }
                    return p;
                })
            }

        case CLEAR_ALL:
        case CLEAR_PROTOCOL_RES:
            return initialState;

        default:
            return state;
    }
}
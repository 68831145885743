import {Participant} from "../../Dashboard/interfaces";
import { AllActionsTypes } from "../actions_all";

// The global participants store state interface
export type ParticipantsStoreState = Participant[];

// The participants store action names
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const ADD_PARTICIPANTS = 'ADD_PARTICIPANTS';
export const ADD_OR_UPDATE_PARTICIPANT = 'ADD_OR_UPDATE_PARTICIPANT';
export const CLEAR_PARTICIPANTS = 'CLEAR_PARTICIPANTS';
export const ARCHIVE_PARTICIPANT = 'ARCHIVE_PARTICIPANT';
export const RESTORE_PARTICIPANT = 'RESTORE_PARTICIPANT';

// The participants store actions interfaces
interface setParticipantsAction {
    type: typeof SET_PARTICIPANTS
    payload: Participant[]
}

interface addParticipantsAction {
    type: typeof ADD_PARTICIPANTS
    payload: Participant[]
}

interface addOrUpdateParticipantAction {
    type: typeof ADD_OR_UPDATE_PARTICIPANT
    payload: Participant
}

interface clearParticipantsAction {
    type: typeof CLEAR_PARTICIPANTS
}

interface archiveParticipantAction {
    type: typeof ARCHIVE_PARTICIPANT
    payload: number
}

interface restoreParticipantAction {
    type: typeof RESTORE_PARTICIPANT
    payload: number
}

// Export the final participants store action states as a single type
export type ParticipantsActionTypes =
    | setParticipantsAction
    | addParticipantsAction
    | addOrUpdateParticipantAction
    | clearParticipantsAction
    | archiveParticipantAction
    | restoreParticipantAction
    | AllActionsTypes;
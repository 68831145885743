import { CLEAR_ALL } from "../actions_all";
import {
    CalendarActionTypes,
    CalendarStoreState,
    SET_CALENDAR_EVENTS,
    CLEAR_CALENDAR_EVENTS,
} from "./types";

const initialState: CalendarStoreState = {
    protocolres: [],
    surveys_res:[]
};

export function CalendarReducer(state = initialState, action: CalendarActionTypes): CalendarStoreState{
    switch (action.type) {
        case SET_CALENDAR_EVENTS:
            return action.payload;

        case CLEAR_ALL:
        case CLEAR_CALENDAR_EVENTS:
            return initialState;

        default:
            return state
    }
}
import {Moment} from 'moment';
import { Protocol } from '../../Dashboard/interfaces';
import { AllActionsTypes } from '../actions_all';

export interface ServerProtocolresData {
    id: number
    protocol: Protocol
    timestamp: Moment
    participant: number
}

export interface ServerSurveyresData {
    id: number
    uuid: string
    participant: number
    date_created: Moment
}

// The global participants store state interface
export interface CalendarStoreState {
    protocolres: ServerProtocolresData[]
    surveys_res: ServerSurveyresData[]
}

// The calendar store action names
export const SET_CALENDAR_EVENTS = 'SET_CALENDAR_EVENTS';
export const CLEAR_CALENDAR_EVENTS = 'CLEAR_CALENDAR_EVENTS';

// The calendar store actions interfaces
interface setCalendarEventsActions {
    type: typeof SET_CALENDAR_EVENTS
    payload: CalendarStoreState
}

interface clearCalendarEventsAction {
    type: typeof CLEAR_CALENDAR_EVENTS
}

// Export the final calendar store action states as a single type
export type CalendarActionTypes =
    | setCalendarEventsActions
    | clearCalendarEventsAction
    | AllActionsTypes;
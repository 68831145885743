import React from 'react';
import { useContent } from '../../../../Content/cms';

import { ServerFieldError } from '../types';
import { Alert } from 'react-bootstrap';

interface ServerErrorProps {
    serverErrors?: ServerFieldError[];
    useCMS?: boolean;
    prefix: string;
}

const ServerErrorAlert: React.FC<ServerErrorProps> = (props) => {

    let getContent = useContent();

    if (!props.serverErrors) {
        return null;
    }
    
    return (<>
        {props.serverErrors.map(
            (message, idx) =>
                <Alert key={idx} className={"mt-2"} variant={'danger'}>
                    {!props.useCMS && message}
                    {props.useCMS && getContent(props.prefix + '__' + message.trim().toLowerCase().replaceAll(' ', '_'))}
                </Alert>
        )}
    </>);
};

export default ServerErrorAlert;
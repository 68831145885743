import languages from "./language_and_texts.json";
import {useSelector} from "react-redux";
import {StoreState} from "../../store";
import { LocalCode } from "../../store/i18n/types";


export type LocalLanguagesSchema = {
    [key in LocalCode]: {[key: string]: string}
}

export type useLocalContentFunction = (key: LocalCode) => string;


export function useLocalContent() {
    
    const localLanguages: LocalLanguagesSchema = languages;

    let codeLang : LocalCode = useSelector<StoreState, LocalCode>(
        state => state.i18n.localCode
    );

    return (key: string) : string => {
        if (localLanguages[codeLang][key] !== null && localLanguages[codeLang][key] !== undefined) {
            return localLanguages[codeLang][key];
        }
        return key;
    };

    
} 


export default useLocalContent;
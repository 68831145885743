import React, {ComponentType} from 'react';
import { useFeatureToogle } from './hook';
import { useContent } from '../../Content/cms';

// Import components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface PageToggleProps {
    featureNames?: string[]
}

const PageToggle = (WrappedComponent: ComponentType, featureNames?: string[]) => {

    const WithHooks: React.FC<PageToggleProps> = (props) => {
        const {featureEnabled} = useFeatureToogle();
        const getContent = useContent();

        if (featureNames === undefined || featureEnabled(featureNames)) {
            return <WrappedComponent {...props} />;
        }

        return (
            <Container className={'bg-light content-minheight'} fluid>
                <Row className={''}>
                    <Col sm={6} className={'d-flex justify-content-end margin-logo-col'}>
                        <img src="/TELL_V2_blue 1.svg" alt={'logo'} className={'w-50'}/>
                    </Col>
                    <Col xs={6} className={'margin-text-col'}>
                        <h3 className={'style-403'}>403</h3>
                        <h4 className={'font-weight-bold'}>{getContent('not_toggle__title')}</h4>
                        <h5>{getContent('not_toggle__text')}</h5>
                    </Col>
                </Row>
            </Container>
        );
    };

    return class extends React.Component {
        render() {
            return <WithHooks {...this.props} />;
        }
    };
};

export default PageToggle;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Apirest from '../../Content/Apirest';

import { StoreState } from '../../store';
import { ToggleDict, SET_TOGGLE_DICT } from '../../store/featureToggle/types';

import Spinner from 'react-bootstrap/Spinner';


const FeatureToggleProvider: React.FC = (props) => {


    let toggleDict = useSelector<StoreState, ToggleDict|undefined>(s => s.featureToggle.table);

    let dispatch = useDispatch();
    
    useEffect(
        () => {
            Apirest.get_toggle_table(
                (response: ToggleDict) => dispatch({
                    type: SET_TOGGLE_DICT,
                    payload: response
                })
            );
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (toggleDict === undefined) {
        return <Spinner animation="border" variant="primary" />;
    }
    
    return <>{props.children}</>
}

export default FeatureToggleProvider;
import * as React from "react";

import {Row, Container, Col, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";


export interface SaveCancelprops {
    save?: (event: any)=>void
    cancel:()=>void
    saveText?: string
    saveClassName?: string
    cancelText?: string
    cancelClassName?:string
    saveBtnVariant?:string
    cancelBtnVariant?:string

}


export const SaveCancel: React.FC<SaveCancelprops> = (props) => {

    return (
        <Container fluid className={'mb-0'}>
        <Row className={'mx-3 mb-0 mt-3 justify-content-around'}>
         <Col lg={5} >
            <Button
                className={props.saveClassName ? props.saveClassName : ' border-0'}
                variant={props.saveBtnVariant ? props.saveBtnVariant : "outline-primary"}
                type={!props.save ? 'submit' : 'button'}
                onClick={props.save}
                block
            >
                <FontAwesomeIcon icon={faCheckCircle} />
                <p className={'d-inline pl-2'}>{props.saveText ? props.saveText : ' Guardar' }</p>
           </Button>
         </Col>
         <Col lg={5}>
                 <Button
                     className={props.cancelClassName ?  props.cancelClassName : ' border-0'}
                     variant={props.cancelBtnVariant ? props.cancelBtnVariant :  "outline-danger"}
                     onClick={props.cancel}
                     block
                 >
                    <FontAwesomeIcon  icon={faTimesCircle} />
                    <p className={'d-inline pl-2'}>{props.cancelText ? props.cancelText : ' Cancelar' }</p>
                </Button>
            </Col>
        </Row>
    </Container>
    );
};

export default SaveCancel;

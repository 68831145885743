const getSubdomain = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    return subdomain;
};


export const getDesign = () => {
    switch (getSubdomain()) {
        case 'eurofarma':
            return {
                logos: {
                    login: "/eurofarma/logo_2.png",
                    spinner: "/eurofarma/logo_1.png",
                    header: {
                        browser: "/eurofarma/logo_1.png",
                        mobile: "/eurofarma/logo_2.png"
                    }
                },
                pages: {
                    permission_denied: "/eurofarma/logo_2.png",
                    not_found: "/eurofarma/logo_2.png"
                }
            };

        // Default goes to tell
        default:
            return {
                logos: {
                    login: "/tell/logo_login.svg",
                    spinner: "/tell/logo_header_browser.svg",
                    header: {
                        browser: "/tell/logo_header_browser.svg",
                        mobile: "/tell/logo_header_mobile.svg"
                    }
                },
                pages: {
                    permission_denied: "/tell/logo_blue.svg",
                    not_found: "/tell/logo_blue.svg"
                }
            };
    }
};

const loadCSS = (href: string) => {
    const link = document.createElement('link');
    link.id = 'dynamic-css';
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
};
  
  
export const applyLabelStyles = () => {
    const subdomain = getSubdomain();
    switch (subdomain) {
        case 'eurofarma':
            loadCSS('/css/eurofarma.css');
            break;

        default:
            loadCSS('/css/tell.css');
            break;
    }
};
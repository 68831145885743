import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useContent } from '../../Content/cms';
import { Row, Container } from 'react-bootstrap';
import Apirest from '../../Content/Apirest';
import { ImpersonificationData } from './types';


import { StoreState } from '../../store';
import { UserStoreState } from '../../store/user/types';

import './index.css';

const ImpersonificationModeBar: React.FC = () =>{
    
    let getContent = useContent();

    let [impersonificationMode, setImpersonificationMode]= useState(false)
    let [impersonifications, setImpersonifications] = useState<ImpersonificationData[]>([]);

    let user = useSelector<StoreState, UserStoreState>(state => state.user);
   
    useEffect(
        () => {
            Apirest.get_available_impersonifications(setImpersonifications);
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )
    
    useEffect( ()=>{
        const isImpersonify = JSON.parse(localStorage.getItem('impersonification') || "null");
        if (isImpersonify){
            setImpersonificationMode(true)
        } else {
            setImpersonificationMode(false)
        };
       
    }, [impersonificationMode, user]) // Uses user to update the useEffect when the user changes and recheck the localStorage

    const isImpersonify = JSON.parse(localStorage.getItem('impersonification') || "null");
    
    let im_name = impersonifications && isImpersonify ? impersonifications.find(imp => imp.examiner.id ===  isImpersonify.examiner_id) : undefined;
    return (
            <Container fluid className='impersonificationBarContainer'>
                <Row>{impersonificationMode === true && 
                    <div className={'impersonificationBar d-flex justify-content-center'}>
                        <small className={'px-3'}> {getContent("impersonification_bar__text")}</small>
                        <small className={'px-3'}>{ getContent("impersonification_bar__text__as_examiner") + ' ' + im_name?.examiner.first_name + ' ' + im_name?.examiner.last_name } </small>
                        <small className={'px-3'}>{ getContent("impersonification_bar__text__imp__mode") + ' ' + im_name?.mode}</small>
                    </div>}
                </Row>
            </Container>
        );
}

export default ImpersonificationModeBar;
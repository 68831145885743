import * as React from 'react';
import {useEffect} from "react";
import {Beforeunload} from 'react-beforeunload';
import {useContent} from '../../Content/cms';

// Import de store
import {StoreState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {CLEAR_DIRTY_STATE} from "../../store/dirtyState/types";

// Import components
import {withRouter, Prompt, RouteComponentProps} from 'react-router';


const RoutingTriggers : React.FC<RouteComponentProps>  = (props) => {

    let getContent = useContent();

    let dispatch = useDispatch();
    let clearDirtyState = () => dispatch({type: CLEAR_DIRTY_STATE});

    let isDirty = useSelector<StoreState, boolean>(
        state => Object.values(state.dirtyState).some(v => v)
    );

    useEffect(
        () => {
            console.log('%c LOCATION:', 'color:#F350A0', props.history.location);
            window.scrollTo(0, 0);
            clearDirtyState();
        },
        [props.history.location] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const onBeforeUnloadHandler = () => {
        if (isDirty) {
            return getContent("commons__prevent_leaving_dirty_state");
        }
    };

    return (
        <Beforeunload onBeforeunload={onBeforeUnloadHandler}>
            <Prompt when={isDirty} message={getContent("commons__prevent_leaving_dirty_state") as string}/>
            {props.children}
        </Beforeunload>
    )
};

export default withRouter(RoutingTriggers);
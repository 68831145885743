import * as React from 'react';
import {useParams} from "react-router";
import { useState, useEffect, useRef} from "react";
import Apirest from "../../../Content/Apirest";
import { useContent } from '../../../Content/cms';
import moment from 'moment';
import { buildRegexExpession, boldRegexMatch, PopUpError } from "../../Helpers";
import { useFeatureToogle } from '../../../ProtectedRoutes/FeatureToggle/hook';

// Import interface
import { Participant } from "../../../Dashboard/interfaces";
import { ParticipantFile } from "./types";

// Store state
import { StoreState } from "../../../store";
import { useSelector } from "react-redux";

// Import Components
import { Container, Modal, Row, Col, Form, Spinner, Alert, InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataFrame from "../DataFrame";
import {faFilter, faTimes, faFileUpload} from "@fortawesome/free-solid-svg-icons";


import "./index.css"


const fileComparator = (a: ParticipantFile, b: ParticipantFile) => {
    if (moment(a.date_created).isBefore(b.date_created)) {
        return 1;
    } else if (moment(a.date_created).isAfter(b.date_created)) {
        return 1;
    }

    return 0;
};

const FilesButton : React.FC = () => {

    let getContent = useContent();

    let {toggleElement} = useFeatureToogle();

    let file_placeholder = getContent("files__form__placeholder");
    let {participant_id} = useParams<{participant_id: string}>();

    let [showFilesModal, setShowFilesModal] = useState<boolean>(false);
    let [filename, setFilename] = useState<string[]>([file_placeholder]);
    //let [date, setDate] = useState<string>(new Date().toISOString().substring(0, 10)) => future optional date assign to file

    let [uploadingFiles, setUploadingFiles] = useState<string[]>([]); // Use name because not yet uploaded files are not ParticipantFiles with id
    let [dropping, setDropping] = useState<boolean>(false);

    let [participantFiles, setParticipantFiles] = useState<ParticipantFile[]>([]);
    let [filteredParticipantFiles, setFilteredParticipantFiles] = useState<ParticipantFile[]>([]);
    let [filteringParticipantFiles, setFilteringParticipantFiles] = useState<boolean>(false);

    let inputFilterRef = useRef<any>();

    let participant = useSelector<StoreState, Participant | undefined>(state => {
		let pid = parseInt(participant_id);
        return state.participants.find(p => p.id === pid);
	});

    useEffect(
        () => {
            if (participant !== undefined) {
                Apirest.get_files(participant_id, setParticipantFiles)
            }
        }, [participant] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Main trigger
    const handleOnClick = () => setShowFilesModal(true);

    const handleOnHide = () => {
        setFilename([file_placeholder]);
        setDropping(false);
        setShowFilesModal(false)
    };

    //submit Form
    const handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        let files: FileList = event.target.elements['file-input'].files;

        setUploadingFiles(Array.from(files).map(file => file.name));
        Apirest.upload_files(
            participant_id,
            files,
            (response: ParticipantFile) => {
                setParticipantFiles(prev => [...prev, response].sort(fileComparator));
                setUploadingFiles(prev => prev.filter(f => f !== response.filename));
            },
            (qXHR: any, data: FormData) => {
                let file = data.get('file') as File;
                setUploadingFiles(prev => prev.filter(f => f !== file.name));
                PopUpError(file.name + ': ' + getContent('files__upload__error__' + qXHR.responseJSON.error))
            }
        );

        // Reset form
        event.target.reset();
        setFilename([file_placeholder]);
    };

    //on select a file
    const handleFileSelected = ( files: FileList) => {

        if (files.length > 0) {
            setFilename(Array.from(files).map(f => f.name))
        } else {
            setFilename([file_placeholder]);
        }
    };

    const handleDeleteFile = (row: React.ReactNode[], index: number, rows_data: ParticipantFile[]) => {
        const file = rows_data[index];

        return Apirest.delete_file(
            participant_id,
            file.id.toString(),
            () => {
                setFilteredParticipantFiles(prev => prev.filter(f => f.id !== file.id));
                setParticipantFiles(prev => prev.filter(f => f.id !== file.id));
            }
        );
    };

    const downloadFile = (file_id: string) => {
        if (participant !== undefined) {
            Apirest.download_file(
                participant.id.toString(),
                file_id
            );
        }
    };

    const filterParticipantFiles = (filter: string) => {

        if (filter === '') {
            setFilteredParticipantFiles([]);
            setFilteringParticipantFiles(false);
        } else {
            let regex = buildRegexExpession(filter.replace(' ',''));
            let newFilteredFiles = [];

            for (let file of participantFiles) {
                let [matches, formattedMatch] = boldRegexMatch(regex, file.filename);
                if (matches) {
                    newFilteredFiles.push({
                        ...file,
                        label: formattedMatch
                    });
                }
            }

            setFilteredParticipantFiles(newFilteredFiles);
            setFilteringParticipantFiles(true);
        }
    };

    const onClearFilter = () => {
        (inputFilterRef as any).current.value ='';
        setFilteredParticipantFiles([]);
        setFilteringParticipantFiles(false);
    };

    // Don't show until a valid participant is loaded from the store
    if (participant === undefined) return null;

    let filesHeader = [
        getContent("files__file_header__date"),
        getContent("files__file_header__file_name"),
        ''
    ];

    let filesToShow = filteringParticipantFiles ? filteredParticipantFiles : participantFiles;
    let files = filesToShow.map(file => [
        file.date_created.format('L'),
        <button
            onClick={toggleElement(['readFiles'], () => downloadFile(file.id.toString()))}
            className={'btn text-btn p-0 m-0'}
        >{file.label !== undefined ? file.label : file.filename}</button>
    ]);

    // Calculate max 3 file names for the label
    let file_labels = filename.slice(0,3).join(', ');
    if (filename.length > 3) {
        file_labels = file_labels + ', ...'
    }


    //dragging files
    const dragOverHandler = (ev : any) => {
        setDropping(true);
    }

    const dragLeaveHandler = (ev : any | undefined) => {
        setDropping(false);
    }

    const dropHandler = (ev:any | undefined) =>{
        setDropping(false);
    }

    const dropping_file = <div><p><b>{getContent('files__dragging_text')}</b></p></div>

    let uploadFilesForm = <Form onSubmit={handleSubmit}>
    <Form.Group controlId={'formFileMultiple'} className={'mb-3'}>
        <Row>
            <Col>
            <div id="drop_zone" className={'position-relative mb-4 pb-2'}>
            <Form.File
                id={'file-input'}
                name={'file-input'}
                label={<div  id={'drag-zone-label'} className={ (filename[0] !== file_placeholder || dropping === true ? 'active ' : null) + ' mt-2'}><FontAwesomeIcon icon={faFileUpload} color={filename[0] !== file_placeholder || dropping === true ? '#5DBD33' : '#bdbdbd'} size={'lg'}/><p className={'pt-2'}>{dropping === true ? dropping_file : file_labels}</p></div>}
                type={'file'}
                className={'custom-file-label drop-area easy px-0' }
                onChange={ (e:any) => handleFileSelected(e.target.files) }
                onDragOver={ (e: any) => dragOverHandler(e)}
                onDragLeave={ (e: any) => dragLeaveHandler(e)}
                onDrop={(e: any) => dropHandler(e)}
                required
                    multiple
                custom
            />
            </div>

                <div className={'d-flex justify-content-between py-3 w-100'}>

                <button
                    type={"submit"}
                    className={" btn btn-primary drop-area-submit w-100"}
                >
                    {uploadingFiles.length > 0 && <Spinner animation={'border'} size={'sm'} />}
                    {uploadingFiles.length === 0 && getContent("files__form__submit_button")}
                </button>
                </div>

            </Col>
        </Row>
        <Row>
            <ul style={{listStyleType: 'none'}}  className={'px-3'}>
                {uploadingFiles.map(
                    (file, idx) => (
                        <li key={idx}>
                        <Button variant={'outline-primary'} className={'easy px-0 mt-2'}>
                            <Spinner animation={'border'} size={'sm'} as={'span'} />
                            {' '}
                            {file}
                        </Button>
                        </li>
                    )
                )}
            </ul>
        </Row>
    </Form.Group>
    </Form>

    let toggle_files_modal= toggleElement(['editFiles'], uploadFilesForm,  <Alert variant={'light'} className={'m-5 text-center'}>{getContent('toggle_content__unable_feature__alert')}</Alert> )

    return (
        <Container>
            <button onClick={handleOnClick} className={'link-button'}>
                {getContent('files__menu_btn')}
            </button>

            {/* files modal */}
            <Modal size={files.length < 1 ? 'lg' : 'xl'}  show={showFilesModal} onHide={handleOnHide}>
                <Modal.Header closeButton>
                    <Modal.Title className={'text-primary'}>
                        {getContent('files__modal__title')}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
    
                    <Row>
                        {participantFiles.length > 0 && <Col sm={12} lg={8}>
                            <Row className={'d-flex justify-content-end '} sm={12} md={8}>
                                <Col xs={5} className={'position-absolute search-div pr-0'}>
                                    <InputGroup className={"mb-2"}>
                                        <InputGroup.Prepend >
                                            <InputGroup.Text className={'bg-white'}>
                                                {filteringParticipantFiles 
                                                    ? <Spinner animation={'border'} size={'sm'}/>
                                                    : <FontAwesomeIcon icon={faFilter}/>
                                                }
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            id={"inlineFormInputGroup"}
                                            ref={inputFilterRef}
                                            placeholder={getContent("files__filter__placeholder")}
                                            onChange = {(event: any) => filterParticipantFiles(event.target.value)}
                                        />
                                        <InputGroup.Prepend className={'filter-search-reset'}>
                                            <button
                                            onClick={onClearFilter}
                                            className={(filteredParticipantFiles.length === 0 ? 'd-none' : null) + ' bg-transparent no-border'}
                                            > <FontAwesomeIcon icon={faTimes} color={ filteredParticipantFiles.length === 0 ? '#fff' : '#495057'}/>
                                            </button>
                                        </InputGroup.Prepend>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                {files.length < 1 && <Alert variant={'warning'} className={'mt-1'}>{getContent("files__empty_alert")}</Alert>}
                                <DataFrame
                                    header={filesHeader}
                                    className={'header-left header-bordered text-left'}
                                    columnWidths={[20,70,10]}
                                    dataframe={files}
                                    rows_data={filesToShow}
                                    pageSize={6}
                                    onRemove={toggleElement(['editFiles'], handleDeleteFile)}
                                    remove_tooltip_disabled_text={getContent('files__disabled_delete_tooltip_text')}
                                />
                            </Row>
                        </Col>}
                                    
                        {/** Upload File */}
                       
                            <Col>
                                {toggle_files_modal}
                            </Col>
                        

                    </Row>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default FilesButton;

import React from 'react';
import {useState} from "react";

import SessionManager from '../../Authentication/SessionManager';
import { useContent } from '../../Content/cms';
import { useHistory } from 'react-router-dom';
import SiteMap from "../../Commons/SiteMap";
import { getDesign } from '../../Commons/WhiteLabeling';

// Import de Store
import { useSelector } from "react-redux";
import {StoreState} from "../../store";
import { UserStoreState } from '../../store/user/types';

// Import the interfaces
import { Participant } from '../../Dashboard/interfaces';

// Import css styles
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './header.css';

// Import JSX components
import { faUserCircle, faPeopleArrows, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "../../Commons/SiteMap/Link";
import ParticipantSearch from "./ParticipantSearch";
import Calendar from "../../Dashboard/Calendar";
import ArchivedParticipants from '../../Dashboard/Participant/archived';
import Takeout from '../../Dashboard/Takeout';
import FeatureToggle from '../FeatureToggle';
import Impersonification from '../Impersonification';
import RenderIfExaminer from '../../Dashboard/RenderIfExaminer';
import RenderIfManager from '../../Admin/RenderIfManager';


const Header: React.FC = () => {

    let getContent = useContent();

    let history = useHistory();

    let user = useSelector<StoreState, UserStoreState>(state => state.user);

    let [showImpersonificationModal, setShowImpersonificationModal]= useState<boolean>(false);
    let [showAppointmentModal, setShowAppointmentModal] = useState<boolean>(false);
    
    const isImpersonify = JSON.parse(localStorage.getItem('impersonification') || "null");

    const whiteLabels = getDesign();
    
    const mobiles = require('is-mobile');
    let mobile = mobiles();
    let logo = mobile ?
        whiteLabels.logos.header.mobile :
        whiteLabels.logos.header.browser;


    const handleOnSelect = (selection: Participant[]) => {
        history.push(
            SiteMap.getFullURL('participant', [selection[0].id])
        );
    };

    if (!user.info) {
        return null;
    }

    let menuIcon = isImpersonify === null ? faUserCircle : faUserFriends
    let menu_title = <>{user.info.first_name} {user.info.last_name} <FontAwesomeIcon icon={menuIcon} /></> ; 

    return (
        <Navbar id={'main-header'} as={'header'} expand={'sm'} variant={'light'} bg={'white'}  fixed={'top'} className={(mobile ? 'mobile-header p-3' : 'p-3') + (isImpersonify ? ' mt-3' : ' mt-0')}>
            
            <div className={'w-25'}>
                <Link to={user.info.roles.is_examiner ? 'dashboard' : 'admin'} >
                    <Navbar.Brand>
                        <img 
                            className={mobile ? 'mobile-logo mr-2' : 'desktop-logo mr-2'}
                            alt={"Logo"}
                            src={logo}
                        />
                    </Navbar.Brand>
                </Link>
            </div>

            <div className={'w-75 d-flex justify-content-end'} id={'doctor-menu-dropdown'}>
                <RenderIfExaminer> 
                    <div className={'d-none d-md-block '}>
                        <ParticipantSearch
                            onSelect={handleOnSelect}
                            placeholder={getContent("base__header__search_participant__placeholder") as string}
                            clearOnSelect
                        />
                    </div>
                </RenderIfExaminer>

                <NavDropdown
                    title={menu_title}
                    id={"nav-dropdown"}
                    className={'ml-3'}
                >
                    <RenderIfExaminer>
                        <NavDropdown.Item as={'div'}>
                            <button
                                className={'link-button'}
                                onClick={() => setShowAppointmentModal(true)}
                            > {getContent("base__header__examiner_menu__agenda")}
                            </button>
                        </NavDropdown.Item>
                        <NavDropdown.Divider  />
                    </RenderIfExaminer>

                    <RenderIfManager>
                        <FeatureToggle featureNames={['admin']} >
                            <NavDropdown.Item as={'div'}>
                                <Link to={'admin'} className={'secondary-link'}>
                                    {getContent("base__header__examiner_menu__manage_examiners")}
                                </Link>
                            </NavDropdown.Item>
                            < NavDropdown.Divider />
                        </FeatureToggle>
                    </RenderIfManager>

                    <RenderIfExaminer>
                        <FeatureToggle featureNames={['archiveParticipant']} >
                            <NavDropdown.Item as={'div'}>
                                <ArchivedParticipants />
                            </NavDropdown.Item>
                            <NavDropdown.Divider  />
                        </FeatureToggle>
                    </RenderIfExaminer>
                    
                    <FeatureToggle featureNames={['downloadProtocolRes']} >
                        <NavDropdown.Item as={'div'}>
                            <Takeout />
                        </NavDropdown.Item>
                        < NavDropdown.Divider />
                    </FeatureToggle>
                    
                    <NavDropdown.Item as={'div'}>
                        <button
                            className={'ml-0 mt-1 pr-3 text-btn text-left bg-transparent no-border'}
                            onClick={() => setShowImpersonificationModal(true)}
                        > <FontAwesomeIcon icon={faPeopleArrows} className={'mr-2'} />{getContent("base__header__examiner_menu__impersonification")}
                        </button>
                    </NavDropdown.Item>
                    <NavDropdown.Divider  />
                    

                    <NavDropdown.Item onClick={SessionManager.logout}>
                        {getContent("base__header__examiner_menu__logout")}
                    </NavDropdown.Item>

                </NavDropdown>
            </div>
            
            <Calendar
                show={showAppointmentModal}
                onHide={() => setShowAppointmentModal(false)}
                
            />
            
            <Impersonification
                show={showImpersonificationModal}
                onHide={() => setShowImpersonificationModal(false)}
            />
            
        </Navbar>
    );
};

export default Header

import React, { useEffect } from 'react';
import Apirest from '../../../Content/Apirest';
import { useContent } from '../../../Content/cms';

import { useFormFields } from '../../../Commons/Components/FormFields';
import { getEmptyValue, PopUpSuccess } from '../../../Commons/Helpers';

// Import interfaces
import { Participant, Locale } from "../../interfaces";
import { FormSubmitData } from '../../../Commons/Components/FormFields/types';
import { FormSectionScheme, FieldScheme, Option } from '../../../Commons/Components/FormFields/types';

// Import the store
import { ADD_OR_UPDATE_PARTICIPANT } from "../../../store/participants/types";
import { store } from "../../../store";

// Import JSX Components
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FormField } from "../../../Commons/Components/FormFields/Field";
import { RowSpinner } from "../../../Commons/MultipleComponents";


// Load form schema
import form_schema from './form_schema.json';


const CreatePatient : React.FC = () => {

    let getContent = useContent();
    let today= new Date().toISOString().split("T")[0];
    
    const {
        sections,
        setSections,
        submitHandler,
        onChange,
        state
    } = useFormFields();

    useEffect(
        () => {
            const new_sections : FormSectionScheme[] = [{
                name: '',
                description: '',
                fields: form_schema as FieldScheme[]
            }];

            // Add form options labels from CMS
            for (let section of new_sections) {
                for (let field of section.fields) {
                    if (typeof field.options !== 'undefined') {
                        field.options = field.options.map((option: Option) => ({
                            ...option,
                            label: getContent(option.label)
                        }));
                    }
                }
            }

            // Ad max date on diagnose date
            for (let section of new_sections) {
                for (let field of section.fields) {
                    if (field.name === "diagnose_date") {
                        field.max = today;
                    } else if (field.name === "birthday"){
                        field.max = today;
                    }
                }
            }
            
            // Add locales to the form
            Apirest.get_locales((response: Locale[]) => {
                for (let section of new_sections) {
                    for (let field of section.fields) {
                        if (field.name === 'locale' && field.options === undefined) {
                            field.options = response.map(locale => ({
                                value: locale.id.toString(),
                                label: locale.code
                            }));           
                        }
                    }
                }

                setSections(new_sections);
            });
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function onSubmit(data: FormSubmitData) {
        let promise = new Promise<void>(
            (resolve, reject) => {
                Apirest.create_participant(
                    data,
                    (participant: Participant) => {
                        store.dispatch({
                            type: ADD_OR_UPDATE_PARTICIPANT,
                            payload: participant
                        });
                        PopUpSuccess(
                            getContent("home__form__server_response__success").replace('{participant_full_name}', participant.code)
                        );
                        resolve();
                    },
                    (jqXHR: JQueryXHR) => {
                        reject(jqXHR.responseJSON);
                    }
                );
            }
        );

        return promise;
    }

    if (sections.length === 0) {
        return <RowSpinner show className='mt-3'/>;
    }

    return (
        <Container className={'sticky-top h-100 mb-0'}>
            <Form onSubmit={submitHandler(onSubmit)} className={'text-center mb-3 sticky-top first'}>
                <h3 className={'text-primary py-3 my-3'}>{getContent('home__form__form_title')}
                </h3>
                <Form.Group className={'text-left'}>
                    <Container>
                        <Row className={'d-flex justify-content-center completeName'}>
                            {sections[0].fields.map((field,idx) =>
                                <Col key={idx} xs={10}>
                                    <FormField
                                        {...field}
                                        serverErrors={state.errors[field.name]}
                                        value={state.values[field.name] || getEmptyValue(field.type)}
                                        onChange={onChange}
                                        useCMS={false /*TODO(Lao): customize error messages in backend and then use CMS*/}
                                    />
                                </Col>
                            )}
                            </Row>
                        
                        <Row className={'d-flex justify-content-center mt-2'}>
                            <Button className={'m-2 col-9 registerParticipant'} variant={'primary'} type={'submit'} block disabled={state.sending}>
                                {!state.sending && getContent('home__form__register_button')}
                                {state.sending && <Spinner animation={'border'} size={'sm'} color={'#FFFFFF'} />}
                            </Button>
                        </Row>
                    </Container>
                </Form.Group>
            </Form>
        </Container>
    );
};

export default CreatePatient;

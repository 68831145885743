import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useContent } from '../../Content/cms';

// Import JSX Components
import {Container, Row, Col, Alert} from "react-bootstrap";
import CreatePatient from './Form';
import ParticipantsList from "./ParticipantsList";
import UserHeader from "../../Commons/Components/Examiner/Header";
import Joyride, { CallBackProps } from 'react-joyride';
import {registerParticipantSteps} from '../Tutorials'

import './index.css';
import { StoreState } from "../../store";
import { Examiner } from "../interfaces";
import { useFeatureToogle } from '../../ProtectedRoutes/FeatureToggle/hook'


const Home: React.FC = () =>{

    let getContent = useContent();
    let {toggleElement} = useFeatureToogle();


    let [runRegisterParticipant, setRunRegisterParticipant] = useState<boolean>(false);
    let [stepIndex, setStepIndex] = useState<number>(0);

    let examiner = useSelector<StoreState, Examiner|null>(s => s.examiner);
    let localeStore = useSelector<StoreState>(l => l.i18n.localCode);

    useEffect(
        () => {
            if (examiner !== null) {
                let ls_key = examiner.id.toString() + '_home_tutorial_viewed';
                let already_shown_tutorial = localStorage.getItem(ls_key);
                if (already_shown_tutorial !== 'true') {
                    localStorage.setItem(ls_key, 'true');
                    setRunRegisterParticipant(true);
                }
            }
        },
        [examiner]
    );

    const runTutorial = () =>{
        setRunRegisterParticipant(true)
    };

    const handleJoyrideCallback = (data: CallBackProps) => {
        if (data.type === 'step:after') {
            // Update state to advance the tour
            setStepIndex( data.index + (data.action === 'prev' ? -1 : 1) );
        } else if ((data.status === 'finished'|| data.status === "paused") || data.action === 'close') {
            // Need to set our running state to false, so we can restart if we click start again.
            setRunRegisterParticipant(false);
            setStepIndex(0);
        }
    };

    let toggle_patient_form = toggleElement(['createParticipant'], <CreatePatient/>, <Alert variant={'light'} className={'m-5 text-center'}>{getContent('toggle_content__unable_feature__alert')}</Alert>)
    
    return (
        <Container  fluid  className={'content-wrapper'}>
            <Joyride
                 run={runRegisterParticipant}
                 steps={registerParticipantSteps(getContent)}
                 callback={handleJoyrideCallback}
                 debug={true}
                 continuous={true}
                 floaterProps ={{placement:'auto'}}
                 showProgress={true}
                 scrollOffset={150}
                 locale={localeStore === 'ES' ?  { back: 'Atras', close: 'Cerrae', last: 'Ultimo', next: 'Siguiente', open: 'Abrir', skip: 'Saltar' } : undefined}
                 styles={{
                    options: {
                        beaconSize: 26,
                        primaryColor: '#5CB6E7'
                    }
                 }}
                stepIndex={stepIndex}

           />
           <Container className={'fluid d-flex-column bg-white '} fluid>
                <UserHeader runTutorial={runTutorial} />
            </Container>
            <Row className={'content-home'}>
                <Col xs={12} md={8} className={'bg-white'}>
                    <ParticipantsList />
                </Col>
                
                <Col xs={12} md={4} className={'bg-light '}>
                     {toggle_patient_form}
                </Col>
            </Row>
        </Container>
    );
};


export default Home;

import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Apirest from '../../Content/Apirest';
import { useContent } from '../../Content/cms';


import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Import interfaces
import { FeatureExtractionSummary, Population, FeatureExtractionSummaryProps } from './FeatureExtraction';
import { ProtocolResData, ProtocolItemResData, FeatureExtractionResData, PopulationDistribution} from '../Protocol/types';
import { StoreState } from '../../store';

// Import components
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import WordCloudChart from '../../Commons/Components/Charts/WordCloudChart';


import Section, { SubSection } from "../../Commons/Section";
import { IconButton } from "../../Commons/MultipleComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask, faDownload, faComment } from "@fortawesome/free-solid-svg-icons";
import { RowSpinner } from "../../Commons/MultipleComponents";
import ParticipantHeader from '../../Commons/Components/Participant/Header';
import GoBackButton from '../../Commons/Components/GoBackButton';


import './index.css';
import { useSelector } from 'react-redux';

const seconds = 2;


function sortedProtocolResData(data: ProtocolResData) {
    let ordered_data = { ...data };
    ordered_data.responses = data.responses.sort(
        (a, b) => a.protocol_item.order - b.protocol_item.order
    );
    return ordered_data;
}

interface ProtocolResUrlParams {
    participant_id: string
    protocolres_id: string
}

const ProtocolRes : React.FC = () => {

    let getContent = useContent();
    const mobiles = require('is-mobile');
    let mobile = mobiles();


    let {participant_id, protocolres_id} = useParams<ProtocolResUrlParams>();

    let populationFeatureDistributions = useSelector<StoreState, Map<string, PopulationDistribution[]>|undefined>(state => state.data.population_feature_distributions);

    let [protocolRes, setProtocolResData] = useState<ProtocolResData|undefined>();
    let [protocolItemRes, setProtocolItemRes] = useState<ProtocolItemResData|undefined>();
    let [protocolItemResAudioUrl, setTranscriptionTaskAudioUrl] = useState<string|undefined>();
    let [protocolItemResAudioSrc, setTranscriptionTaskAudioSrc] = useState<string|undefined>();
    let [protocolItemFeatures, setProtocolItemFeatures] = useState<FeatureExtractionSummaryProps|undefined>();

    const setServerData = (data: ProtocolResData) => {

        setProtocolResData(sortedProtocolResData(data));

        // If there is no current protocol item assigned or the one assigned is not in the new server data,
        // Then it set the first protocol item.
        // Else set the updated protocol item data of the current protocol item data
        setProtocolItemRes(prevProtocolItemRes => {

            let prevProtocolItemResExists = (
                prevProtocolItemRes !== undefined &&
                data.responses.find(t => t.id === prevProtocolItemRes.id) !== undefined
            );

            if (prevProtocolItemResExists) {
                return prevProtocolItemRes;
            } else {
                return data.responses[0];
            }
        });
    };

    // Initial on mount trigger
    useEffect(
        () => {
            Apirest.get_protocolres(
                participant_id,
                protocolres_id,
                setServerData
            );

            // Clean up
            return () => {
                setProtocolResData(undefined);
                setProtocolItemRes(undefined);
                setTranscriptionTaskAudioUrl(undefined);
                setTranscriptionTaskAudioSrc(undefined);
            }
        },
        [protocolres_id] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function keep_pooling() {
        if (protocolRes !== undefined) {

            /* NO responses */
            if (protocolRes.responses.length === 0) {
                // There in no transcription tasks yet
                return true;
            }
            /*  end */

            /* feature extraction tasks not ready */
            if (protocolRes.responses.some(r => r.feature_extraction_res.some(fer => fer.status === 'pending'))) {
                // There are items of the protocol still not transcribed
                return true;
            }
            /*  end */
        }

        return false;
    };

    // pooling of transcription task transcript and features
    // Each time the protocolRes data changes the useEffect is triggered
    // if there is data to pool, then the useWEffect fetches new data in 10 seconds
    // and updates the protocolRes data, then a new useEffect is triggered and
    // keep pooling is called again and if still not all ready then continues pooling.
    // Once keep_pooling returns false, then the useEffect is not triggered anymore
    useEffect(
        () => {
            // if there is at least one transcription task without the transcript or the feature, then ask data again in 10 seconds
            if (keep_pooling()) {

                console.log('%c Protocol data not ready, request transcription task data again in ' + seconds.toString() + ' seconds', 'color: #F945A8');
                setTimeout(
                    () => Apirest.get_protocolres(
                        participant_id,
                        protocolres_id,
                        setProtocolResData
                    ),
                    1000*seconds
                );
            }
        },
        [protocolRes]  // eslint-disable-line react-hooks/exhaustive-deps
    );


    // Calculate and set the protocol item features to be displayed
    const calculateAndSetProtocolItemFeatures = (currProtocolItemRes: ProtocolItemResData) => {

        let clinical_populations: Population[] = [];

        if (protocolRes !== undefined && populationFeatureDistributions !== undefined) {
            
            clinical_populations = currProtocolItemRes.feature_extraction_res.map(
                (fer: FeatureExtractionResData) => {

                    // Get the population, if protocol item category and language 
                    // are undefined, then the key will be undefined in the map
                    // and the populations will be undefined, so it will return an empty array
                    const categoryId = currProtocolItemRes?.protocol_item?.category.id;
                    const funcId = fer.feature_extraction_func.id;
                    const languageId = protocolRes?.protocol?.locale?.lang?.id;
                    const key = `${categoryId}-${funcId}-${languageId}`;

                    let populations = populationFeatureDistributions?.get(key);
                    
                    if (populations === undefined) {
                        return [];
                    }

                    return populations.map(
                        (population: PopulationDistribution) => {
                            return {
                                name: population.population.name,
                                feature_name: population.feature_extraction_func.name + '__' + population.score_name,
                                fill_color: population.population.fill_color,
                                stroke_color: population.population.stroke_color,
                                histogram: population.distribution
                            };
                        }
                    );
                }
            ).flat();
        }

        // Warning: it could happen the populations are not the same as the scores, but this is not 
        // a problem, because the scores are shown and populations filter inside each score. 
        // Then, the populations are shown only for those scores that have a population distribution

        let tell_populations: Population[] = currProtocolItemRes.feature_extraction_res
        .filter((x: FeatureExtractionResData) => (x.status === 'ready'))
        .map((fer: FeatureExtractionResData) => {
            return Object.entries(fer.feature_population_distribution.data).map(
                ([score, histogram]: [string, {x: number[], y: number[]}]) => {
                    return ({
                        name: getContent('protocol_result__populations__feature_extraction'),
                        feature_name: fer.feature_extraction_func.name + '__' + score,
                        fill_color: '#5dbd3361',
                        stroke_color: '#5DBD33',
                        histogram: histogram
                    })
                }
            )
        }).flat();

        // concatenate ia_populations and control_populations
        let populations = clinical_populations.flat().concat(...tell_populations);

        let scores = currProtocolItemRes.feature_extraction_res.map(
            (fer: FeatureExtractionResData) => {
                // Show a single row with the feature name and status if the feature is not ready
                if (fer.status !== 'ready') {
                    return [{
                        name: fer.feature_extraction_func.name,
                        status: fer.status
                    }]
                }
                
                // Show a row for each score of the feature if the feature is ready
                return Object.entries(fer.scores).map(
                    ([score, value]: [string, number]) => {
                        return ({
                            name: fer.feature_extraction_func.name + '__' + score,
                            status: fer.status,
                            value: value
                        })
                    }
                )
            }
        ).flat();
        
        setProtocolItemFeatures({
            loadingPopulations: populationFeatureDistributions === undefined,
            data: scores,
            populations: populations
        })
    };

    useEffect(
        () => {
            if (protocolItemRes !== undefined) {
                calculateAndSetProtocolItemFeatures(protocolItemRes);
            }

        }, [protocolItemRes, populationFeatureDistributions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function getAudioURL() {
        if (protocolRes && protocolItemRes) {
            console.log('######### getAudioURL #########');
            console.log('participant_id:', participant_id !== undefined);
            console.log('protocolRes:', protocolRes !== undefined);
            console.log('protocolItemRes:', protocolItemRes);
            Apirest.get_protocol_res_audio_url_presigned(
                participant_id,
                protocolRes.id.toString(),
                protocolItemRes.protocol_item.id.toString(),
                setTranscriptionTaskAudioUrl
            );
            Apirest.get_protocol_res_audio_src(
                participant_id,
                protocolRes.id.toString(),
                protocolItemRes.protocol_item.id.toString(),
                setTranscriptionTaskAudioSrc
            );

        }
    }

    // get current item audio URL
    useEffect(
        () => getAudioURL(),
        [protocolItemRes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function downloadAudio() {
        if (protocolRes && protocolItemRes) {
            Apirest.download_protocol_res_audio(
                participant_id.toString(),
                protocolRes?.id.toString(),
                protocolItemRes.protocol_item.id.toString()
            );
        }
    }

    // Show spinner if data not already fetched
    if (!protocolRes || !protocolItemRes || !protocolItemFeatures) {
        return (
            <Container fluid className={'mt-5'}>
                <RowSpinner show />;
            </Container>
        );
    }

    // TODO: move all computations from next block to a useEffect listening protocolItemRes to not be recalculated in every render
    /** ################################# */

    let transcription = protocolItemRes?.feature_extraction_res.find(
        (f: FeatureExtractionResData) => f.feature_extraction_func.entry === 'transcript'
    );

    let wordcloud = protocolItemRes?.feature_extraction_res.find(
        (f: FeatureExtractionResData) => f.feature_extraction_func.entry === 'words-count'
    );

    let all_pending = protocolItemRes?.feature_extraction_res.every(
        fer => fer.status !== 'ready'
    );

    let all_failed = protocolItemRes?.feature_extraction_res.every(
        fer => fer.status === 'failed'
    );

    /** ################################# */
    
    let colums_adjustment= mobile === false ? 6 : 12;

    return (
        <Container fluid>
            <ParticipantHeader fetch_data />
            <Row className={'bg-info p-2 d-flex justify-content-between'}>
                <Col className={'py-2'}>
                <h5 className={'text-white font-weight-bold mb-0'}>
                    <FontAwesomeIcon icon={faFlask}/>  {getContent("mind_lab__title")}
                </h5>
                </Col>
                <GoBackButton/>
            </Row>
            <Row>

                {/* Protocol item nav bar */}
                <Col xs={12} className={'my-0 p-0 '} >
                    <Row className={mobile ? 'd-flex py-3 px-0 ': 'd-flex p-3'}>
                        <Col xs={colums_adjustment}>
                            { mobile ? <h4 className={"font-weight-bold mx-0 px-3 text-primary "}>
                            {getContent("protocolres_prefix_name") + " " + protocolRes.protocol.name}
                        </h4> : <h3 className={"font-weight-bold mx-3 px-3 text-primary"}>
                            {getContent("protocolres_prefix_name") + " " + protocolRes.protocol.name}
                        </h3> }
                        </Col>
                        <Col xs={colums_adjustment} className={'align-bottom'}>
                        <p className={(mobile ? ' px-3 mx-0 small' : 'text-right mb-0 pb-0  px-3 mx-3 mt-2')}>
                        {getContent("protocol_result__date_creation") + ": " + protocolRes.date_created.format('LLL')}
                        </p>
                        </Col>
                        <Col>
                        { protocolRes.comment !== "" &&
                        <p className={'mx-3 px-3 p-2 border-bottom'}> <FontAwesomeIcon icon={faComment} color={'#5CB6E7'}/> <small className={'px-2'}><em>{protocolRes.comment}</em></small></p>
                        }
                        </Col>
                        
                    </Row>
                </Col>

                {/* Ready protocol transcription task */}
                {protocolItemRes &&
                    <Col className={(mobile ? 'bg-light' : 'bg-light p-5')}>
                        {mobile === false ? 
                            <Nav variant="pills" defaultActiveKey={0} className={'text-center align-content-center d-flex'}>
                                {protocolRes?.responses.map((response, idx) =>
                                    <Nav.Item key={idx} className={'m-1'}>
                                        <Nav.Link
                                            eventKey={idx}
                                            onClick={() => setProtocolItemRes(response)}
                                        >
                                            {response.protocol_item.title}
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav> :
                            <Row className={'mt-3'}>
                                <Col sm={12}> 
                                    <Nav>
                                        <Dropdown>
                                        <Dropdown.Toggle id={'protocolResItem'} className={'btn-primary'}>{getContent("protocol_result__dropdown_items_title")}</Dropdown.Toggle>
                                            <Dropdown.Menu className={'protocolResItemsManu'}>
                                            {protocolRes?.responses.map((response, idx) =>
                                                <Dropdown.Item key={idx} onClick={() => setProtocolItemRes(response)}>
                                                {response.protocol_item.title}
                                                </Dropdown.Item> 
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Nav>
                                </Col>
                            </Row>
                        }
                        
                        <Section
                            /*title={getContent("protocol_result__title")}*/
                            className={'secondary-section'}
                            /*headerClassName={'secondary-section-header'}*/
                            bodyClassName={'secondary-section-body'}
                        >
                            <Row>
                                <Col xs={colums_adjustment} className={'description-section'}>
                                    <SubSection
                                        title={getContent("protocol_result__slogan")}
                                        className={'secondary-subsection mt-3'}
                                        foldable={mobile ? true : false}
                                    >
                                        <div className={'protocol-title'} dangerouslySetInnerHTML={{__html: protocolItemRes.protocol_item.trigger}} />
                                        {protocolItemRes.protocol_item.image !== undefined && <div className={'text-center'}>
                                            <img
                                                className={'img-fluid mt-3'}
                                                alt={getContent("protocol_result__title")}
                                                src={protocolItemRes.protocol_item.image}
                                            />
                                        </div>}
                                        {protocolItemRes.protocol_item.audio !== undefined && <div className={'text-center'}>
                                            <audio
                                                className={'mt-4 fluid'}
                                                title={getContent('protocol_result__title')}
                                                src={protocolItemRes.protocol_item.audio}
                                                preload={'auto'}
                                                controls
                                            />
                                        </div>}
                                        {protocolItemRes.protocol_item.video !== undefined && <div className={'text-center'}>
                                            <video
                                                className={'mt-4 img-fluid'}
                                                title={getContent('protocol_result__title')}
                                                src={protocolItemRes.protocol_item.video}
                                                controls
                                            />
                                        </div>}

                                    </SubSection>
                                </Col>
                                {protocolItemRes.is_skipped ?

                                <Col>
                                <Alert variant={'danger'}>
                                        <h3>{getContent("protocol_result__skipped_item")}</h3>
                                        {protocolItemRes.skipped_purpose && 
                                            <p>{protocolItemRes.skipped_purpose}</p>
                                        }
                                </Alert>
                                </Col>
                                
                            :
                                <Col xs={colums_adjustment}>
                                    <SubSection
                                        title={getContent("protocol_result__audio")}
                                        className={'secondary-subsection mt-3'}
                                        foldable={mobile ? true : false}
                                    >
                                        <Container fluid>
                                            <RowSpinner show={!protocolItemResAudioUrl} />
                                            <Row className={'mt-3'}>
                                                <Col xs={10} >
                                                    {protocolItemResAudioUrl && <audio
                                                        className={'w-100'}
                                                        title={protocolItemRes.protocol_item.title}
                                                        src={protocolItemResAudioSrc}
                                                        preload={'auto'}
                                                        controls
                                                    />}
                                                </Col>
                                                <Col xs={2} className={'p-3 text-center'}>
                                                    {<IconButton
                                                        onClick={downloadAudio}
                                                        icon={{icon:faDownload,color:"#5CB6E7"}}
                                                        text={getContent('protocol_result__feature__download_audio')}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        </Container>
                                    </SubSection>
                                    {/* There is already some summary metrics processed*/}
                                    {transcription &&
                                            <SubSection
                                                title={getContent('protocol_result__transcript_section_title')}
                                                className={'secondary-subsection mt-3 '}
                                                foldable={mobile ? true : false}
                                            >
                                                <div className={'p-2'}>
                                                    {transcription.data.transcript === "" ? <Alert variant={'success'}>
                                                         <p>{getContent("protocol_result__transcript_empty")}</p>
                                                    </Alert> : transcription.data.transcript }
                                                </div>
                                            </SubSection>}

                                    {/** If transcription task is still in progress, then show a spinner */}
                                    {all_pending && <SubSection
                                        title={getContent("protocol_result__transcript_in_progress")}
                                        className={'secondary-subsection mt-3'}
                                    >
                                        <RowSpinner show />
                                    </SubSection>}

                                    {/** Show an alert if there is not transkiption task because it failed */}
                                    {all_failed && <Alert variant={'danger mt-3'}>
                                        <p>{getContent("protocol_result__transcript_failed")}</p>
                                    </Alert>}

                                </Col>}
                            </Row>
                        </Section>

                        {/* There is already some summary metrics processed*/}
                        {wordcloud &&
                            <Section
                                title={getContent("protocol_result__wordcloud__title")}
                                className={'secondary-section'}
                                headerClassName={'secondary-section-header'}
                                bodyClassName={'secondary-section-body'}
                            >
                                <Row>
                                    <Col>
                                        <WordCloudChart words={wordcloud.data.words_count} />
                                    </Col>
                                </Row>
                            </Section> 
                        } 

                        {/* There is already some summary metrics processed*/}
                        
                        {protocolItemFeatures &&
                            <Row>
                                { protocolItemRes.is_skipped ?
                                null :
                                <FeatureExtractionSummary
                                    loadingPopulations={protocolItemFeatures.loadingPopulations}
                                    data={protocolItemFeatures.data}
                                    populations={protocolItemFeatures.populations}
                                />}
                            </Row> 
                        } 
                    </Col>
                }
            </Row>
        </Container>
    );
};

export default ProtocolRes;


export async function downloadProtocolRes(getContent: (key: string) => string, participant_id: string, protocolres_id: string, examiner_last_name: any) {
    
    Apirest.get_protocolres(
        participant_id,
        protocolres_id,
        async (data: ProtocolResData) => {

            let zip = new JSZip();

            let protocolName = (
                participant_id + '_' +
                examiner_last_name + '_' +
                data.date_created.format('YYYY-MM-DD:HH:mm:ss')
            );
            
            let all_summary :string[][] = [
                ['protocol item', 'metric', 'value']
            ];

            // Create an array to hold the promises
            let promises: Promise<void>[] = [];

            data.responses.forEach((protocolItemRes: ProtocolItemResData) => {

                let itemName = 'item_' + (protocolItemRes.protocol_item.order+1);
                if (protocolItemRes.protocol_item.title) {
                    itemName = protocolItemRes.protocol_item.title;
                }

                // Create a new folder that I now it will success as item names are different
                let folder = zip.folder(itemName)!;

                promises.push(
                    new Promise<void>((resolve, reject) => {
                        Apirest.get_protocol_res_audio_url_presigned(
                            participant_id,
                            protocolres_id,
                            protocolItemRes.protocol_item.id.toString(),
                            async (presignedUrl: string) => {

                                // Fetch the audio file from the presigned URL
                                const response = await fetch(presignedUrl);
                                const arrayBuffer = await response.arrayBuffer();

                                // Add the audio file to the zip
                                folder.file('response.webm', arrayBuffer, {binary: true});

                                // Resolve the promise
                                resolve();
                            }
                        );
                    })
                );

                folder.file(
                    "trigger.txt",
                    protocolItemRes.protocol_item.trigger
                );

                let transcription = protocolItemRes.feature_extraction_res.find(
                    (f: FeatureExtractionResData) => f.feature_extraction_func.entry === 'transcript'
                );

                folder.file(
                    "transcript.txt",
                    (transcription !== undefined 
                        ? transcription.data.transcript
                        : 'Transcription not present'
                    )
                );

                let summary: string[][] = [['metric', 'value']];
                protocolItemRes.feature_extraction_res.forEach((row: FeatureExtractionResData) => {
                    
                    Object.entries(row.scores).forEach(
                        ([score, value]: [string, number]) => {
                            let name = getContent("protocol_result__feature_extraction__" + row.feature_extraction_func.name + '__' + score + "__name")
                            summary.push([name, value.toString()]);
                            all_summary.push([itemName, name, value.toString()]);
                        }
                    )
                });

                folder.file(
                    "summary.csv",
                    summary.map(row => row.join(';')).join('\n')
                );
            });

            // Wait for all the promises to resolve
            await Promise.all(promises);

            zip.file(
                'summary.csv',
                all_summary.map(row => row.join(';')).join('\n')
            );

            zip.generateAsync({type:"blob"}).then(function(content) {
                saveAs(content, protocolName);
            });
        }
    );
}
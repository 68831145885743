import { AllActionsTypes } from "../actions_all";

export type Method = 'GET' | 'POST' | 'DELETE' | 'PATCH';

export interface ServerError {
    error: string
    url: string
    method: Method
    data: any
}

export interface ServerState {
    error?: ServerError
    logged_in: boolean
}

export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const CLEAR_SERVER_ERROR = 'CLEAR_SERVER_ERROR';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';

export interface setLoggedInAction {
    type: typeof SET_LOGGED_IN,
    payload: boolean
}

export interface setServerErrorAction {
    type: typeof SET_SERVER_ERROR,
    payload: ServerError
}

export interface clearServerErrorAction {
    type: typeof CLEAR_SERVER_ERROR
}

export type ServerActionTypes =
    | setLoggedInAction
    | setServerErrorAction
    | clearServerErrorAction
    | AllActionsTypes;

import * as React from 'react';
import { useContent } from '../../Content/cms';
import { useDispatch, useSelector } from 'react-redux';
import Apirest from '../../Content/Apirest';

import { StoreState } from '../../store';
import { RESTORE_PARTICIPANT } from '../../store/participants/types';
import { Participant } from '../interfaces';

import DataFrame from '../../Commons/Components/DataFrame';
import { IconButton } from '../../Commons/MultipleComponents';
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';


const ArchivedParticipants : React.FC = () => {

    let getContent = useContent();

    let [show, setShow] = React.useState<boolean>(false);

    let participants = useSelector<StoreState, Participant[]>(
        state => state.participants.filter(p => !p.active)
    );

    // dispatch participant responses to store
    const dispatch = useDispatch();
    const restoreParticipantInStore = (participant_id: number) => dispatch({
        type: RESTORE_PARTICIPANT,
        payload: participant_id
    });

    function restoreParticipant(participant_id: number) {
        // Update participant in front and back independently
        // if backend update fails, the participant will be re-archived in the next refresh
        // and it's not a big problem but a bug to solve when they report it
        restoreParticipantInStore(participant_id);
        Apirest.restore_participant(participant_id.toString());
    }

    function participant2row(participant: Participant) {
        return [
            <div>
               <p className={'p-0 m-0'}>{participant.code}</p>
               {' '}
               <small className={'text-muted'}>{participant.last_name} {participant.first_name}</small> 
            </div>,
            <IconButton
                onClick={() => restoreParticipant(participant.id)}
                icon={{ icon: faTrashRestore, color: "#5CB6E7" }}
                text={getContent('archived__participant__restore__tooltip')}
            />
        ]
    }

    function allParticipants2row(participants: Participant[]) {
        return participants.map(participant => participant2row(participant));    
    }

    // Return a modal with the data of the participant responses in a dataframe
    return <>
        <button onClick={() => setShow(true)} className={'link-button'}>
            {getContent('archived__participants__menu_btn')}
        </button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{getContent('archived__participants__title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {participants.length > 0 && <DataFrame
                    dataframe={allParticipants2row(participants)}
                    pageSize={5}
                    striped={true}
                    clickeableRow={true}
                    columnClassName={'text-right'}
                />}

                {participants.length === 0 && <div>
                    <Alert variant={'warning'}>
                        {getContent('archived__participants__empty')}
                    </Alert>
                </div>}
            </Modal.Body>
        </Modal>
    </>;
}

export default ArchivedParticipants;
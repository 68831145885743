import { Examiner } from "../../Dashboard/interfaces";
import { AllActionsTypes } from "../actions_all";

// The global examiner store state interface
export type ExaminerStoreState = Examiner | null;

// The examiner store action names
export const SET_EXAMINER = 'SET_EXAMINER';
export const CLEAR_EXAMINER = 'CLEAR_EXAMINER';

// The examiner store actions interfaces
interface setExaminerAction {
    type: typeof SET_EXAMINER
    payload: Examiner
}

interface clearExaminerAction {
    type: typeof CLEAR_EXAMINER
}

// Export the final examiner store action states as a single type
export type ExaminerActionTypes =
    | setExaminerAction
    | clearExaminerAction
    | AllActionsTypes;
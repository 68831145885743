import { AllActionsTypes } from "../actions_all";

export interface ToggleDict {
    [role: string]: {
        [feature : string]: boolean
    }
};

export interface FeatureToggleState {
    table?: ToggleDict
}

export const SET_TOGGLE_DICT = 'SET_TOGGLE_DICT';
export const CLEAR_FEATURE_TOGGLE = 'CLEAR_FEATURE_TOGGLE';

export interface setToggleDictAction {
    type: typeof SET_TOGGLE_DICT,
    payload: ToggleDict
}

export interface clearFeatureToggleAction {
    type: typeof CLEAR_FEATURE_TOGGLE
}

export type FeatureToggleActionTypes =
    | setToggleDictAction
    | clearFeatureToggleAction
    | AllActionsTypes;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useContent } from '../../../Content/cms';
import { useSelector } from 'react-redux';

import { Survey } from '../types';
import { faFile } from "@fortawesome/free-solid-svg-icons";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton'
import { IconButton, RowSpinner } from "../../../Commons/MultipleComponents";
import SurveyForm from './Form';
import { StoreState } from '../../../store';
import SaveCancel from '../../../Commons/Components/SaveCancelButtons';
import { ModalBody } from 'react-bootstrap';


interface SurveyFormProps {
    participant_id: string,
    onlyIcon?: boolean,
    align?: string
}

const Surveys : React.FC<SurveyFormProps> = (props) => {

    let getContent = useContent();

    let [currentSurvey, setCurrentSurvey] = useState<Survey|undefined>();

    let [show, setShow] = useState<boolean>(false);
    let [showAlert, setShowAlert] = useState<boolean>(false);

    let surveys = useSelector<StoreState, Survey[]|undefined>(
        (state: StoreState) => state.survey.surveys
    );

    let isDirty = useSelector<StoreState, boolean>((state: StoreState) => state.dirtyState['form'] || false);
    
    useEffect(
        () => {
            if (surveys !== undefined && surveys.length > 0) { 
                if (currentSurvey === undefined || surveys.find(s => s.id === currentSurvey!.id)) {
                    setCurrentSurvey(surveys[0]);
                }
            } else if (currentSurvey !== undefined) {
                setCurrentSurvey(undefined);
            }
        }, [surveys] // eslint-disable-line react-hooks/exhaustive-deps
    )

    function changeSelectedSurvey(event: React.ChangeEvent<HTMLSelectElement>) {

        if (surveys === undefined) {
            throw new Error("Surveys is undefined");
        }

        setCurrentSurvey(surveys[event.target.selectedIndex]);
    }
    
    let AlertModal = <Modal show={showAlert} className={'bg-dark-transparent'} centered>
                       <ModalBody className={'text-center'}>
                            <p className={'mb-0 pb-0'}>{getContent("survey_dirty_state_close_modal_text")}</p>
                        </ModalBody>
                            <SaveCancel 
                                 save={()=>AlertaHandler('continue')} 
                                 cancel={()=>AlertaHandler('cancel')} 
                                  saveText={getContent("survey_dirty_state_close_modal_continue_button")} 
                                 cancelText={getContent("survey_dirty_state_close_modal_cancel_button")}>
                            </SaveCancel>
                    </Modal>

    let AlertaHandler = (d:string)  => {
        if (d === 'continue'){
            setShowAlert(false);
            setShow(false);
        } else {
            setShowAlert(false)
        }
    }

    let dirtyCloseModal = () => {
        if (isDirty === false){
            setShow(false);
        } else {
           setShowAlert(true);
        }
    }

    return (
        <Container className={props.align}>
            <button
                className={'ml-0 mb-0 px-3 text-btn bg-transparent no-border'}
                onClick={() => setShow(true)}
            >
                <IconButton
                    text={getContent("survey__open__icon_tooltip")}
                    icon={{icon:faFile,color:"#5CB6E7"}}
                />
                {!props.onlyIcon && ' ' + getContent("survey__open__text")}
            </button>
            
            <Modal show={show} onHide={() => setShow(false)} size={'lg'} backdrop={'static'}>
                <Modal.Header>
                    <Col md={10}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>{getContent("survey__select__label")}</Form.Label>
                    <Form.Control
                        size={'sm'}
                        as={'select'}
                        onChange={changeSelectedSurvey}
                        value={currentSurvey ? currentSurvey.id : undefined}
                    >
                        {surveys !== undefined && surveys.map(s => (
                            <option key={s.id} value={s.id}>{s.name}</option>
                        ))}

                        {surveys === undefined && <RowSpinner show/>}
                    </Form.Control>
                    </Form.Group>
                    </Col>
                    <Col md={2}>
                        <CloseButton onClick={dirtyCloseModal}/>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    {AlertModal}
                    {currentSurvey && <SurveyForm participant_id={props.participant_id} {...currentSurvey} setShow={setShow} />}
                </Modal.Body>
            </Modal>
            
        </Container>
    );
};

export default Surveys;

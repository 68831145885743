import * as React from 'react';

import SiteMap from "../Commons/SiteMap";
import { Switch, Route } from 'react-router-dom'
import NotFound from '../NotFound';

const Authentication : React.FC = () => {

    return (
         <Switch>
               {SiteMap.getAuthRoutingMap()}
               <Route component={NotFound} />
         </Switch>
    );
};

export default Authentication;

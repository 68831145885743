import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContent } from '../../../Content/cms';
import Apirest from '../../../Content/Apirest';

import Chart from './Chart/index';
import ChartPlaceholder from './Chart/ChartPlaceholder';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { RowSpinner } from '../../../Commons/MultipleComponents';


import { ProtocolsEvolution } from './types';

import './index.css';

// TODO: remove clinical_history from CMS keys
const EvolutionChart: React.FC = () => {

    let getContent = useContent();

    let {participant_id} = useParams<{participant_id: string}>();

    let [protocolresData, setProtocolresData] = useState<ProtocolsEvolution|undefined>();
    let [currentProtocolItem, setCurrentProtocolItem] = useState<{protocol: string, item: string}|undefined>();

    useEffect(
        () => {
            Apirest.get_participant_protocolres_evolution_data(
                participant_id,
                setProtocolResMetrics,
                (e: any) => {console.error('Error fetching ProtocolRes evolution data', e)}
            );
        },
        [participant_id]  // eslint-disable-line react-hooks/exhaustive-deps
    );

    function setProtocolResMetrics(data: ProtocolsEvolution) {
        setProtocolresData(data);
        let protocols = Object.keys(data).sort();
        if (protocols.length > 0) {
            let protocol = protocols[0];
            let item = Object.keys(data[protocol]).sort()[0];
            setCurrentProtocolItem({
                protocol: protocol,
                item: item,
            });
        }
    }

    let content = null;

    // Data still loading
    if (protocolresData === undefined || (Object.keys(protocolresData).length > 0 && currentProtocolItem === undefined)) {
        content = <RowSpinner show className='mt-3' />;
    
    
    // No data available
    } else if (Object.keys(protocolresData).length === 0) {
        content = <ChartPlaceholder />;


    // Data available
    } else {

        let {protocol,item} = currentProtocolItem!;

        let chart = (
            <Chart
                section={'protocols'}
                data={protocolresData![protocol][item]}
                protocol_item_trigger={item}
            />
        );

        let protocolsToPlot = Object.keys(protocolresData).sort().map((protocol) => ({
            protocol: protocol,
            items: Object.keys(protocolresData![protocol]).sort()
        }));

        let menu = (
            <div className={'bg-light p-3 mb-3'}>
                <p>{getContent('evolution_chart__select_protocol_placeholder')}</p>
                <Nav variant={'pills'} defaultActiveKey={0}>
                    {protocolsToPlot.map(({protocol, items}, protocol_idx) => 
                        <DropdownButton
                            key={protocol_idx.toString()}
                            id={'dropdown-button-drop-' + protocol_idx.toString()}
                            size="sm"
                            title={protocol}
                            className={'mx-3'}
                        >
                            {items.map((item, item_idx) =>
                                <Dropdown.Item
                                    key={item + ' -- ' + item_idx.toString()} 
                                    eventKey={item}
                                    onClick={() => setCurrentProtocolItem({protocol: protocol, item: item})}
                                >
                                    {item}
                                </Dropdown.Item>
                            )}
                        </DropdownButton>
                    )}
                </Nav>
            </div>
        );
    
        content = <>{menu}{chart}</>;
    }

    return (
        <Container fluid>
            {content}
        </Container>
    );
}

export default EvolutionChart;
import { CLEAR_ALL } from "../actions_all";
import {
    i18nState, i18nActionTypes, SET_LOCALE, SET_CONTENT,
    DELETE_CONTENT, SET_LOCAL_CODE, CLEAR_I18N
} from "./types";

const initialState: i18nState = { localCode: 'ES' };

export function i18Reducer(state=initialState, action: i18nActionTypes): i18nState {

    let newState = {...state};

    switch (action.type) {

        case SET_LOCALE:
            newState.locale = action.payload;
            break;

        case SET_CONTENT:
            newState.content = {...action.payload};
            break;

        case DELETE_CONTENT:
            newState.content = undefined;
            break;
        
        case SET_LOCAL_CODE:
            newState.localCode = action.payload;
            break;

        case CLEAR_ALL:
        case CLEAR_I18N:
            return initialState;

        default:
            break;
    }

    return newState;
}
import { CLEAR_ALL } from "../actions_all";
import { DataActionTypes, DataState, SET_POPULATION_FEATURE_DISTRIBUTIONS, CLEAR_POPULATION_FEATURE_DISTRIBUTIONS } from "./types";


const initialState: DataState = {};

export function DataReducer(state=initialState, action: DataActionTypes): DataState {

    let newState = {...state};

    switch (action.type) {

        case SET_POPULATION_FEATURE_DISTRIBUTIONS:
            newState.population_feature_distributions = action.payload;
            break;

        case CLEAR_ALL:
        case CLEAR_POPULATION_FEATURE_DISTRIBUTIONS:
            newState = initialState;
            break;

        default:
            break;
    }

    return newState;
}
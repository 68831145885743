import React, { useState, useEffect } from "react"
import { useContent } from '../../../../Content/cms';
import { FormFieldValue } from "../../../../Commons/Components/FormFields/types";
import { useVolumeLevel } from "../../Recorder/audioVisualizer/useVolume";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import { FormField } from "../../../../Commons/Components/FormFields/Field";
import { RowSpinner } from "../../../../Commons/MultipleComponents";
import { PopUpError } from "../../../../Commons/Helpers";


interface MicPermissionProps {
    onSelect: (deviceId: string) => void;
}

const DeviceSelection : React.FC<MicPermissionProps> = (props) => {

    let getContent = useContent();

    // Store the available devices
    let [devices, setDevices] = useState<{value: string, label: string, disabled: boolean}[]>([]);

    // Store selected device
    let [selectedDevice, setSelectedDevice] = useState<string>('');

    // Store checking device state
    let [checkingDevice, setChechingDevice] = useState<boolean>(false);

    // Store the volume level to check if the mic is working
    let {startVolumeLevel, stopVolumeLevel} = useVolumeLevel();


    // Get the available devices
    useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(
                (devices) => {
                    setDevices(devices.filter(device => device.kind === 'audioinput' && device.deviceId !== '').map(device => ({
                        value: device.deviceId,
                        label: device.label,
                        disabled: false
                    })));
            });
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function handleSelectDevice(name: string, value: FormFieldValue) {
        
        let deviceID = value as string;

        let volumes: number[] = [];
        setChechingDevice(true);
        startVolumeLevel((volume) => volumes.push(volume), deviceID);
        setTimeout(() => {
            stopVolumeLevel();

            let totalVolumes = volumes.reduce((a, v) => a + v, 0);

            if (totalVolumes === 0) {
                PopUpError(getContent('protocol__mic_permission__no_volumne_error'));
                setSelectedDevice('');
            } else {
                setSelectedDevice(deviceID);
            }
            setChechingDevice(false);
        }, 2000);
    }

    return (
        <Container className={'protocol'} fluid>
            <h4 className='protocol-header'>
                {getContent('protocol__device_selection__title')}
            </h4>
            <Row className={'task-body'}>
            <Col>
                <p>{getContent('protocol__device_selection__text')}</p>
                <h4>{getContent('protocol__device_selection__instructions_title')}</h4>
                <p dangerouslySetInnerHTML={{__html: getContent('protocol__device_selection__instructions_text') as string}}></p>
                </Col>
            </Row>
            {(devices.length > 0 && !checkingDevice) && <Row className={'task-body'}>
                <Col>
                    <FormField
                        type='select'
                        name='select_mic'
                        label={getContent('protocol__device_selection__label')}
                        value={selectedDevice}
                        options={devices}
                        onChange={handleSelectDevice}
                    />
                </Col>
            </Row>}
            {(devices.length === 0 || checkingDevice) && <RowSpinner show/>}
            <Row className={'task-body'}>
                <Col>
                    <Button
                        className='recorder-button'
                        onClick={() => props.onSelect(selectedDevice!)}
                        disabled={selectedDevice === ''}
                        block
                    >
                        {getContent('protocol__device_selection__btn')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default DeviceSelection;
import * as React from "react";
import {useEffect, useState} from "react";
import Apirest from "../../Content/Apirest";
import {useContent} from '../../Content/cms';


// Import interfaces
import {Participant} from "../interfaces";

// Import components
import { Container, Row, Col, Alert, Modal } from "react-bootstrap";
import {RowSpinner} from "../../Commons/MultipleComponents";

import {EventInput, EventContentArg} from "@fullcalendar/core";
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

// Import store
import {StoreState} from "../../store";
import {
    CalendarStoreState,
    CLEAR_CALENDAR_EVENTS,
    SET_CALENDAR_EVENTS
} from "../../store/calendar/types";
import { useDispatch, useSelector } from "react-redux";
import './index.css'
import ParticipantSearch from "../../ProtectedRoutes/Header/ParticipantSearch";
import { Locale } from "../../store/i18n/types";


interface CalendarProps {
    participant_id?: string
    show?: boolean
    onHide?: () => void
}

const Calendar : React.FC<CalendarProps> = (props) => {

    let getContent = useContent();

    let [fetchingData, setFetchingData] = useState<boolean>(false);
    let [calendarEvents, setCalendarEvent] = useState<EventInput[]>([]);


    let participants = useSelector<StoreState, Participant[]>(state => state.participants);
    let events = useSelector<StoreState, CalendarStoreState>(state => state.calendar);
    let participant = participants.find( p => p.id.toString() === props.participant_id);

    let [selectedParticipants, setSelectedParticipants] = useState<Participant[]>([]);
    let [filteredCalendarEvents, setFilteredCalendarEvents] = useState<EventInput[]>([]);

    let locale = useSelector<StoreState, Locale | undefined>(state => state.i18n.locale);

    const dispatch = useDispatch();

    const setCalendarEvents = (response: CalendarStoreState) => dispatch({
        type: SET_CALENDAR_EVENTS,
        payload: response
    });

    const clearCalendarEvents = () => dispatch({
        type: CLEAR_CALENDAR_EVENTS
    });

    const filterParticipantOnSelect = (participants: Participant[]) => {
        setSelectedParticipants(participants);

        setFilteredCalendarEvents(
            calendarEvents.filter((e: EventInput) => participants.some(p => p.id === e.extendedProps!.id))
        )
    };

    const filterParticipantOnClear = () => {
        setSelectedParticipants([]);
        setFilteredCalendarEvents([]);
    };

    useEffect(
        () => {
            setFetchingData(true);
            Apirest.get_calendar_events((response) => {
                setCalendarEvents(response);
                setFetchingData(false);
            });

            // Clean up
            return () => {
                clearCalendarEvents();
            };
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            // Notes, filter is needed for synchronization purposes
            // Set protocolres events
            const protocolres : EventInput[] = events.protocolres
                .filter(p => participants.find(pa => pa.id === p.participant) !== undefined)
                .map(p => {
                    const part = participants.find(pa => pa.id === p.participant)!;
                    return {
                        id: p.id.toString(),
                        title: part.code + ' Protocolo: ' + p.protocol.name,
                        start: p.timestamp.valueOf(),
                        backgroundColor: '#9165ca',
                        borderColor: '#9165ca',
                        textColor: '#ffffff',
                    };
                });
                const surveyres : EventInput[] = events.surveys_res
                .filter(s => participants.find(pa => pa.id === s.participant) !== undefined)
                .map(s => {
                    const part = participants.find(pa => pa.id === s.participant)!;
                    return {
                        id: s.id.toString(),
                        title: part.code + ' Cuestionario: ' ,
                        start: s.date_created.valueOf(),
                        backgroundColor: '#6bd9ef',
                        borderColor: '#6bd9ef',
                        textColor: '#ffffff',
                    };
                });
            setCalendarEvent([...protocolres, ...surveyres]);
        },
        [events, participants]
    );

    const eventClassName = (e: EventContentArg) => 'cursor ' + (e.isPast ? 'passed-event' : '');
   

    let calendar_filtered = (
        <FullCalendar
            eventClassNames={eventClassName}
            locale={locale}
            initialView={'listMonth'}
            height= {500}
            noEventsContent={<Alert variant={'warning'}>{getContent("calendar__no_events_content__calendar_filtered")}</Alert>}
            events={filteredCalendarEvents}
            headerToolbar={{right: "prev,next"}}
            plugins={[listPlugin, interactionPlugin]}
        />
    );

    let calendar = (
        <FullCalendar
            locale={locale}
            eventClassNames={eventClassName}
            initialView={'listMonth'}
            height= {500}
            noEventsContent={<Alert variant={'warning'}>{getContent("calendar__no_events_content__calendar_mobile")}</Alert>}
            events={calendarEvents}
            headerToolbar={{right: "prev,next"}}
            plugins={[listPlugin, interactionPlugin]}
        />
    );

    
    let fullCalendar = (
        <Container fluid >
               
            <Row className={'mt-2 px-3 d-flex'}>
                <Col sm={12} >
                    <ParticipantSearch
                        onSelect={filterParticipantOnSelect}
                        placeholder={getContent('calendar__filter_participant')}
                        onClear={filterParticipantOnClear}
                    />
                </Col>
            </Row>

            <Row className={'mt-4 px-3 pb-3'}>
                <Col xs={12}>
                    {selectedParticipants.length > 0 && calendar_filtered}
                    {selectedParticipants.length === 0 && calendar}
                </Col>
            </Row>
        </Container>
    );

        return (
            <Modal
                show={props.show}
                onHide={props.onHide}
                size={'lg'}
            >
                {fetchingData && 
                 <RowSpinner show />}
                <Modal.Header className={'mb-0 pb-0 mr-2'} closeButton>
                <Modal.Title>{participant !== undefined ? <p className={' pl-2 ml-5 mb-0'}> {participant.first_name} {participant.last_name}</p> : null}
                <p className={' pl-2 ml-5 pt-0 modal-header-subtitle'}> {getContent("calendar__home_modal__subtitle")}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body className={'no-border'}>
                    {fullCalendar}
                </Modal.Body>
            </Modal>
        );

};

export default Calendar;

import React from 'react';
import {useContent} from '../../../Content/cms';

import './footer.css';
import {Navbar} from "react-bootstrap";

const Footer: React.FC = () => {

    let getContent = useContent();

    return (
        <Navbar sticky={'bottom'} as={'footer'} bg={'dark'} >
            <Navbar.Text className={"d-flex text-white pr-2"}>
                <a
                    href={getContent("base__footer__policy_link")}
                    target={"_blank"}
                    className={'text-white'}
                    rel={'noopener noreferrer'}>
                <small>{getContent("base__footer__policy")} /</small>
                </a>
            </Navbar.Text>

            <Navbar.Text>
                <a
                    href={getContent("base__footer__terms_link")}
                    target={"_blank"}
                    className={'text-white'}
                    rel={'noopener noreferrer'}>
                 <small>{getContent("base__footer__terms")}</small>
                </a>
            </Navbar.Text>
        </Navbar>
    );
};

export default Footer;

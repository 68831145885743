import * as React from "react";
import {useContent} from '../../../../Content/cms';
import {useSelector} from "react-redux";

// Import Components
import {Col, Row} from "react-bootstrap";
import {IconButton} from "../../../MultipleComponents";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

// Import the store
import {StoreState} from "../../../../store";
import { UserStoreState } from "../../../../store/user/types";

interface UserHeaderProps {
    runTutorial?: () => void
}

const UserHeader : React.FC<UserHeaderProps> = ({runTutorial}) => {

    let getContent = useContent();

    let user = useSelector<StoreState, UserStoreState>((state: StoreState) => state.user);

    if (user.info === undefined) return null;

    return (
        <Row lg={12} className={' p-5 doctor-header w-100 flex-reverse'}>
            <Col lg={5}>
                <h3 className={'text-white py-3'}>{getContent("home__header__welcome")} {user.info.first_name} {user.info.last_name} 
                <small className={'align-middle'}><IconButton
                    text={getContent('tutorials__register_participant__icon_tooltip')}
                    icon={{icon:faQuestionCircle,color:"#5CB6E7"}}
                    onClick={runTutorial}
                    className={'thisTutorial'}
                /></small></h3>
            </Col>
        </Row>
    );
};

export default UserHeader;

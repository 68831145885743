import React from "react"
import { useContent } from '../../../../Content/cms';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import { PopUpError } from "../../../../Commons/Helpers";

interface MicPermissionProps {
    onSuccess: () => void;
    onFail?: (err: string) => void;
}

const MicPermission : React.FC<MicPermissionProps> = (props) => {

    let getContent = useContent();

    let onFail = props.onFail || ((err: string) => {console.error(err); PopUpError(err)});

    function handleMicPermission() {

        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        }).then((mic: MediaStream) => {
            // Free the mic
            mic.getTracks().forEach(track => track.stop());
            props.onSuccess();
        }).catch((error: DOMException) => {
            onFail(error.message);
        });
    }

    return (
        <Container className={'protocol'} fluid>
            <h4 className='protocol-header'>
                {getContent('protocol__mic_permission__title')}
            </h4>
            <Row className={'task-body justify-content-center'}>
            <Col>
                {getContent('protocol__mic_permission__text')}
                <br/><br/>
                <h4>{getContent('protocol__mic_permission__instructions_title')}</h4>
                <br/>
                <p dangerouslySetInnerHTML={{__html: getContent('protocol__mic_permission__instructions_text') as string}}></p>
                </Col>
            </Row>
            <Row className={'task-body'}>
                <Col>
                    <Button
                        className='recorder-button'
                        onClick={handleMicPermission}
                        block
                    >
                        {getContent('protocol__mic_permission__btn')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default MicPermission;
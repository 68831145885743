import * as React from 'react'

// Import components
import ReactWordcloud, {Word} from "react-wordcloud";

export interface WordCloudProps {words: Word[]}

/*
    * WordCloudChart
    *
    * A component that renders a word cloud chart.
    * 
    * @param {Word[]} words - The words to be displayed in the word cloud.
    * @returns {React.FC<WordCloudProps>}
    * @example
    * 
    * const words = [
    *     {text: 'Lorem', value: 10},
    *     {text: 'Ipsum', value: 7},
    *     {text: 'Dolor', value: 9},
    *     {text: 'Sit', value: 8},
    *     {text: 'Amet', value: 11},
    * ];
    * 
    * <WordCloudChart words={words} />
    *   
*/

const WordCloudChart : React.FC<WordCloudProps> = ({words}) => (
    <ReactWordcloud
        words={words}
        options={{
            colors: [
                '#1f77b4',
                '#ff7f0e',
                '#2ca02c',
                '#d62728',
                '#9467bd',
                '#8c564b',
            ],
            enableTooltip: true,
            deterministic: true,
            fontSizes: [15, 60],
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: 'bold',
            rotations: 1,
            rotationAngles: [0, 0],
            scale:'linear',
            spiral: 'archimedean',
            transitionDuration: 1000,
        }}
    />
);

export default WordCloudChart;
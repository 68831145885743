import * as React from "react";

// Import components
import {Button, Col, Row, Spinner, OverlayTrigger, Tooltip, Toast, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import Link from "../Commons/SiteMap/Link";


// Import CSS
import './multiple_components.css';

interface Icon{
    icon: IconDefinition
    color: string

}

interface IconButtonProps {
    text: string
    onClick?: () => void
    icon: Icon
    className?: string
    placement?: string  
}

export const IconButton : React.FC<IconButtonProps> = (props) => {

    return (
        <OverlayTrigger
            key= "top"
            placement="top"
            overlay={
                <Tooltip id={`tooltip-${"top"}`}>
                    {props.text}
                </Tooltip>
            }
        >
        <FontAwesomeIcon
            className={props.className + ' mx-1 cursor'}
            icon={props.icon.icon}
            color={props.icon.color}
            onClick={props.onClick}
        />
        </OverlayTrigger>
    );
};

interface TagProps {
    text: string
    onRemove: (l: string) => void
}

export const Tag : React.FC<TagProps> = (props) => {

    let {text, onRemove} = props;

    return (
      <Button
          className={'mr-1 mb-2 tag'}
          variant={"outline-warning"}
      >{text} <FontAwesomeIcon icon={faTimes} onClick={() => onRemove(text)} /></Button>
  );
};

interface RowSpinnerProps {
    show: boolean
    className?: string
    variant?:
        | 'primary'
        | 'secondary'
        | 'success'
        | 'danger'
        | 'warning'
        | 'info'
        | 'light'
        | 'dark';
}

export const RowSpinner : React.FC<RowSpinnerProps> = (props) => {

    if (!props.show) return null;

    return (
        <Row className={props.className} >
            <Col className={'text-center'} >
                <Spinner animation={'border'} variant={props.variant} />
            </Col>
        </Row>
    );
};

interface updateAlertProps {
    title?: string
    text: string
    remove: () => void
    link?: {to: string, params: (string|number)[], text: string}
    autohide?: boolean
    delay?: number
}

export const UpdateAlertProps: React.FC<updateAlertProps> = (props) => (
    <Container className={'text-center toast-alert'} fluid>
        <Toast>
            <Toast.Header>
              <strong className="mr-auto">{props.title}</strong>
            </Toast.Header>
            <Toast.Body>
                <p>{props.text}</p>
                <p>{props.link && <Link className={'text-btn link'} to={props.link.to} params={props.link.params}>{props.link.text}</Link>}</p>
            </Toast.Body>
         </Toast>
    </Container>
);

import React, {FC, useState, useEffect, useRef} from 'react';
import { useVolumeLevel } from './useVolume';

import './index.css'

interface AudioVisualizerProps {
    mic: MediaStream|null;
}

const AudioVisualizer: FC<AudioVisualizerProps> = (props) => {

    let {startVolumeLevel, stopVolumeLevel, volumeLevel} = useVolumeLevel(props.mic);
    let [volumes, setVolumes] = useState<number[]>([]);

    // Create ref for canvas
    let canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(
        () => {
            console.log('[AudioVisualizer] Start volume level');
            startVolumeLevel();
            return () => {
                console.log('[AudioVisualizer] Stop volume level');
                stopVolumeLevel();
            }
        },
    []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(
        () => setVolumes(volumes => [volumeLevel, ...volumes].slice(0, 100)),
        [volumeLevel] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(
        () => {
            const canvas = canvasRef.current;

            if (canvas !== null) {
                const height = canvas.height;
                const width = canvas.width;
                let context = canvas.getContext('2d')!;
                const sliceWidth = (width * 1.0) / 100;
                
                context.lineWidth = 1;
                context.strokeStyle = '#395fa9';
                context.lineCap= 'round';
                context.clearRect(0, 0, width, height);
                context.beginPath();
                
                let x = width;
                for (const item of volumes) {
                    const barHeight = (item / 255.0) * height;
                    context.moveTo(x, (height / 2) + (barHeight / 1));
                    context.lineTo(x, (height / 2) - (barHeight / 1));
                    x -= sliceWidth;
                }
                context.stroke();
            }
        },
        [volumes]
    )

    // Render canvas with ref
    return <canvas className={'audio-visualizer'} ref={canvasRef} />;
};

export default AudioVisualizer;
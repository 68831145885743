import * as React from 'react';
import { useContent } from "../../../../../Content/cms";

import './index.css';

const ChartPlaceholder : React.FC = () => {

    let getContent = useContent();

    let text : React.ReactNode = getContent('protocol__evolution_chart__empty');

    return (
        <div className={'chart-placeholder'}>
            <div>
                <div className={'placeholder-text text-white w-50'} >
                    {text}
                </div>
                <div className={'placeholder-image'}>
                    <img
                        src={'/charts/protocols.svg'}
                        alt={'chart placeholder'}
                    />
                </div>
            </div>
        </div>
    );
};

export default ChartPlaceholder

import * as React from "react";
import {useEffect, useState} from "react";

// Import de store
import {StoreState} from "../../../store";
import {REMOVE_POP_UP_ALERT, popUpAlertStoreState, PopUpAlertMessage} from "../../../store/popUpAlert/types";
import {connect} from "react-redux";

// Import components
import {Container, Alert, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

interface AlertType {
    in: boolean
    message: PopUpAlertMessage
}

const mapStateToProps = (state: StoreState) : popUpAlertStoreState => {
    return {...state.popUpAlert};
};

const mapDispatchToProps =  {
    removePopUpAlert: (timestamp: number) => ({
        type: REMOVE_POP_UP_ALERT,
        payload: timestamp
    })
};

type popUpAlertProps = typeof mapDispatchToProps & popUpAlertStoreState;

const PopUpAlert: React.FC<popUpAlertProps> = (props) => {

    let [alerts, setAlerts] = useState<AlertType[]>([]);

    useEffect(
        () => {
            console.log("UPDATE INS STATE", alerts);
        },
        [alerts]
    );

    useEffect(
        () => {
          setAlerts( prevState => [
              ...prevState
                  .filter(a => !props.message_list.find(m => a.message.timestamp === m.timestamp))
                  .map(m => ({in: false, message: m.message})),
              ...props.message_list
                  .map(m => ({in: true, message: m}))
          ]);
        },
        [props.message_list]
    );

    const clearAlert = (timestamp: number) => {
        setAlerts(
            prevState => prevState.filter(m => m.message.timestamp !== timestamp)
        );
    };

    return (
        <Container className={'text-center pop-up-alert'} fluid>
            {alerts.map(
                (alert, idx) => (
                    <Collapse
                        key={idx}
                        in={alert.in}
                        onExited={() => clearAlert(alert.message.timestamp)}
                        appear
                    >
                        <div id={'collapsable-alert-' + alert.message.timestamp.toString() + '-' + idx.toString()}>
                            <Alert
                                className={'mb-1 d-flex flex-row'}
                                variant={alert.message.variant}
                                dismissible
                                onClose={() => props.removePopUpAlert(alert.message.timestamp)}
                            >
                                <FontAwesomeIcon
                                    className={'mr-1'}
                                    icon={alert.message.variant === 'success' ? faCheckCircle : faExclamationCircle}
                                    color={alert.message.variant === 'success' ? '#118625' : '#721c24'}
                                /> {alert.message.text}
                            </Alert>
                        </div>
                    </Collapse>
                )
            )}
        </Container>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpAlert);

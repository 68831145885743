// create a react page to only show children if user.info.roles.is_examiner is true
import * as React from "react";
import { useSelector } from "react-redux";

import { StoreState } from "../../store";
import { UserStoreState } from "../../store/user/types";


const RenderIfManager: React.FC = ({children}) => {
    let user = useSelector<StoreState, UserStoreState>(state => state.user);

    if (!user.info || !user.info.roles.is_manager) {
        return null;
    }

    return <>{children}</>;
};

export default RenderIfManager;
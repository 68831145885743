import { CLEAR_ALL } from "../actions_all";
import {
    popUpAlertStoreState,
    PopUpAlertActionTypes,
    ADD_POP_UP_ALERT,
    REMOVE_POP_UP_ALERT,
    CLEAR_POP_UP_ALERTS
} from "./types";

const initialState: popUpAlertStoreState = {
    message_list: []
};

export function PopUpAlertReducer(state=initialState, action: PopUpAlertActionTypes): popUpAlertStoreState {

    let newState = {...state};

    switch (action.type) {

        case ADD_POP_UP_ALERT:
            newState.message_list = [
                ...newState.message_list,
                {...action.payload}
            ];
            break;

        case REMOVE_POP_UP_ALERT:
            newState.message_list = newState.message_list.filter(m => m.timestamp !== action.payload);
            break;

        case CLEAR_ALL:
        case CLEAR_POP_UP_ALERTS:
            newState.message_list = [];
            break;

        default:
            break;
    }

    return newState;
}
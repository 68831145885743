import * as React from 'react';
import { useEffect, useState } from 'react';

// Import styles
import './index.css';

// Import interfaces
import { Row, DataFrameProps } from "./types";

// Import JSX components
import DataFramePagination from "./DataFramePagination";
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import  Alert  from 'react-bootstrap/Alert';
import { IconButton } from '../../MultipleComponents';
import { faTrash, faUndo, faExpand, faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContent } from '../../../Content/cms';


const DataFrame : React.FC<DataFrameProps> = (props) => {

    let getContent = useContent();

    let [cpn, setCPN] = useState<number>(0); // cpn = Current Page Number
    let [deletingItem, setDeletingItem] = useState<number|undefined>();

    let {header, subheader, dataframe} = props;
    let [headersNumber, setHeadersNumber] = useState<number|undefined>();
    let [viewAll, setViewAll] = useState<boolean>(false);

    let {hiddenColumns, columnWidths} = props;
    

    const handleViewAll = () =>{
        setViewAll(!viewAll)
        setCPN(0)
    }

    // For FeatureTogge functionalities
    if (hiddenColumns !== undefined && hiddenColumns.some(v => v)) {

        if (header !== undefined) {
            header = header.filter((_,i) => !hiddenColumns![i]);
        }
    
        if (subheader !== undefined) {
            subheader = subheader.filter((_,i) => !hiddenColumns![i]);
        }

        dataframe = dataframe.map(p => p.filter((_,i) => !hiddenColumns![i]));

        if (columnWidths !== undefined) {
            columnWidths = columnWidths.filter((_,i) => !hiddenColumns![i]);

            // redistribute column widths equally, check if this is correct
            let totalWidth = columnWidths.reduce((partialSum, a) => partialSum + a, 0);
            let totalHiddenWidths = 100 - totalWidth;
            columnWidths = columnWidths.map(w => w + (w/totalWidth)*totalHiddenWidths);
        }
    }

    useEffect(() => {
        let pageSize = Math.max(1, props.pageSize || dataframe.length);
        setCPN(prev => Math.max(0, Math.min(prev,Math.ceil(dataframe.length / pageSize)-1)));
        setDeletingItem(undefined);
    }, [dataframe, props.pageSize, viewAll]);

    useEffect(() => {
        let pageSize =  props.header ? props.header.length : undefined;
        setHeadersNumber(pageSize)
    }, [props.header]);

    const onRemove = (row: Row, index: number) => {
        if (props.onRemove) {
            setDeletingItem(index);
            props.onRemove(row, index, props.rows_data || []);
        }
    };

    const getRemoveIcon = (row: Row, index: number) => {

        if (deletingItem === index) {
            return <Spinner animation='border' size='sm' />;
        }

        if (deletingItem === undefined && (props.canRemove === undefined || props.canRemove(row, index, props.rows_data || []))) {
            return (
                <IconButton
                    onClick={() => onRemove(row, index)}
                    text={props.remove_tooltip_enabled_text || getContent('commons__components__dataframe__delete_tooltip_text')}
                    icon={{icon:faTrash,color:"#5CB6E7"}}
                />
            );
        }

        return (
            <IconButton
                text={props.remove_tooltip_disabled_text || getContent('commons__components__dataframe__disabled_delete_tooltip_text')}
                icon={{icon:faTrash,color:"#c1c5c8"}}
            />
        );
    };

    let pageSize = viewAll ? dataframe.length : props.pageSize || dataframe.length;
    const pageOffset = cpn*pageSize;
    const totalPages = viewAll ? 1 :  Math.ceil(dataframe.length / pageSize);
    let currentPage = dataframe.slice(pageOffset,pageOffset+pageSize);

    let start = ((cpn + 1) * pageSize) - (pageSize - 1);
    let end = Math.min(start + pageSize - 1, dataframe.length); 

    let totalItems =  props.showAllOption && dataframe.length >= pageSize ? 
        start + ' - ' + end + ' ' + getContent('commons__components__dataframe__total_items_n_of') + ' ' + dataframe.length
        :
        dataframe.length + ' ' + getContent('commons__components__dataframe__total_items_n_of') + ' ' + dataframe.length

                    
    return (
        <Container fluid >
           
           <div className='d-flex mr-1 my-2'>
                <p className=' text-muted text-left bg-transparent mb-0 mt-1 ml-2'> { getContent('commons__components__dataframe__showing') + ' ' + totalItems}</p>
                { props.showAllOption && dataframe.length >= pageSize ? 
                <button onClick={handleViewAll} className={'mt-1 mb-0  ml-2 text-btn text-left bg-transparent no-border'}> 
                    <FontAwesomeIcon icon={ viewAll ? faLayerGroup : faExpand} className='mx-2'/>
                    {!viewAll ? getContent('commons__components__dataframe__view_all_button') : getContent('commons__components__dataframe__paginate_button') }
                </button>   : null}
            </div> 
             
            <Table responsive className={props.bordered || 'dark table borderless ' + props.className} striped={props.striped} >
               {/* if dataframe filter result is null show alert */}
               {dataframe.length === 0 && 
                <Alert  variant={'info'} className={'my-3 w-100'}>
                    {getContent('commons__components__dataframe__no_items_to_show__alert')}
                </Alert>
                }
            <colgroup span={headersNumber}></colgroup>
                {header &&
                <thead >
                <tr className={'border-top-0'} >
                    {header.map((e, i) => <th className={'pb-0'} key={i} >{e}</th>) }
                    {props.onRemove && <th > </th>}
                    {props.onReset && <th > </th>}
                </tr>
                </thead>
                }
                {subheader &&
                <tr>
                    {subheader.map((e, i) => <th colSpan={headersNumber} scope="colgroup" className={'p-0 w-100'} key={i} >{e}</th>) }
                </tr>   
                }
                <tbody >
                     
                {currentPage.map((row, rdx) =>
                    <tr key={(pageOffset+rdx).toString() + '-row'} className={ props.clickeableRow ? 'cursor border-top-0' : 'border-top-0'}>
                        {row.map((e,cdx) =>
                            <td
                                key={(pageOffset+rdx).toString() + '-' + cdx.toString()} // unique identifier for each dataframe cell
                                style={{width: (columnWidths ? columnWidths[cdx] + '%' : 'auto')}}
                            >{e}</td>
                        )}
                        {props.onRemove &&
                        <td style={{width: '10%'}}>
                            {getRemoveIcon(row, pageOffset+rdx)}
                        </td>
                        }
                        {props.onReset &&
                        <td style={{width: '10%'}}>
                            <IconButton
                                onClick={() => {
                                    if (!props.modifiedRows || props.modifiedRows[rdx]) {
                                        props.onReset!(row, pageOffset+rdx, props.rows_data || [])
                                    }
                                }}
                                text={props.reset_tooltip_text || getContent('commons__components__dataframe__reset_tooltip_text')}
                                icon={{
                                    icon:faUndo,
                                    color: (!props.modifiedRows || props.modifiedRows[rdx]) ? "#5CB6E7" : "#DDDDDD"
                                }}
                            />
                        </td>
                        }
                    </tr>
                )}
                </tbody>
            </Table>

            { totalPages >=1 &&
            <DataFramePagination
            cpn={cpn}
            totalPages={totalPages}
            setCPN={setCPN}
            />}
            
        </Container>
    );
};

export default DataFrame;
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContentDict } from "../../Content/cms";
import CMS from "../../Content/cms";

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-ca';
import 'moment/locale/pt';

// Import store
import { StoreState } from "../../store";
import { UserStoreState } from "../../store/user/types";
import { Locale, SET_CONTENT, SET_LOCALE, DELETE_CONTENT } from "../../store/i18n/types";

// Import component
import LoaderSpinner from "../../Commons/Components/LoaderSpinner";

// Provisory fix
import {withRouter, RouteComponentProps} from 'react-router';

const I18NProvider: React.FC<RouteComponentProps> = (props) => {

    let user = useSelector<StoreState, UserStoreState>(state => state.user)
    let locale = useSelector<StoreState, Locale | undefined>(state => state.i18n.locale);
    let content = useSelector<StoreState, ContentDict | undefined>(state => state.i18n.content);

    const dispatch = useDispatch();

    // Set locale as the user locale
    useEffect(
        () => {
            if (user.info !== undefined) {
                dispatch({
                    type: SET_LOCALE,
                    payload: user.info.locale.code
                });
            }
        },
        [dispatch, props.history.location] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Set content
    useEffect(
        () => {
            if (locale !== undefined) {

                dispatch({type: DELETE_CONTENT})

                // Set correct locale to moment time library
                // We set 'en' or 'es' and not the full locales for
                // simplicity as moment.js does not handle any
                // locale and is hard to consider all cases
                if (locale.startsWith('es-')) {
                    moment.locale('es');
                } else if (locale.startsWith('en-')) {
                    moment.locale('en-ca');
                } else if (locale.startsWith('pt-')) {
                    moment.locale('pt');
                }
                else if (locale.startsWith('fr-')) {
                    moment.locale('fr');
                } else {
                    // throw new Error('Current language ' + locale + ' is not yet available for the time formating lib. Please add it to the available locales');
                    moment.locale('en-ca')
                }

                CMS.get_content(locale, (response) => {
                    dispatch({
                        type: SET_CONTENT,
                        payload: response
                    })
                });

                document.documentElement.lang = locale;
            }
        },
        [locale, dispatch]
    );

    if (content === undefined) {
        return <LoaderSpinner/>;
    }

    return <>{props.children}</>;
};

export default withRouter(I18NProvider);
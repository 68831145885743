import * as React from "react";
import SiteMap from "./index";
import {Params} from "./index";
import { useFeatureToogle } from "../../ProtectedRoutes/FeatureToggle/hook";

// Import components
import {Link as RouterLink} from "react-router-dom";

interface LinkProps {
    to: string
    onClick?: () => void
    params?: Params
    className?: string
    featureNames?: string[]
}

const Link : React.FC<LinkProps> = (props) => {
    let {to, params, className, featureNames} = props;

    let {featureEnabled} = useFeatureToogle();
    
    // if Link is for a not enabled feature
    if (featureNames && !featureEnabled(featureNames)) {
        return <>{props.children}</>;
    }

    return (
        <RouterLink 
            to={SiteMap.getFullURL(to,params)}
            className={className}
            onClick={props.onClick}
        >
            {props.children}
        </RouterLink>
    );
};

export default Link;
import React, {FC, useState} from 'react';
import { useContent } from '../../../Content/cms';

import SaveCancel from '../../../Commons/Components/SaveCancelButtons';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';

import { faStepForward} from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '../../../Commons/MultipleComponents';


interface SkipButtonProps {
    onSubmit: (purpose:string) => void
    disable: boolean
    className: string
}

const SkipButton:FC<SkipButtonProps> = (props) => {

    let getContent = useContent();

    let [purpose, setPurpose] = useState<string>('');
    let [show, setShow] = useState<boolean>(false);

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) =>{
        const value = event.target.value;
        setPurpose(value);
    }
    
    const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.onSubmit(purpose);
        setShow(false);
        setPurpose('');
    }

    return (
        <>
            <Button
                className={props.className}
                onClick={() => setShow(true)}
                disabled={props.disable}
            >
                <IconButton
                    text={props.disable ? getContent('protocol_item__skip_item__disable_tooltip') : getContent('protocol_item__skip_item__available_tooltip')}
                    icon={{ icon: faStepForward, color: "#fff" }}
                />

            </Button>
            <Modal show={show} backdrop={'static'} centered>
                <Modal.Body>
                    <p>{getContent('protocol_item__skip_item__title')}</p>
                    <Form className={'mt-3'} onSubmit={handleSubmit}>
                        <FormGroup controlId={'skip_form'}>
                            <Form.Control
                                as="textarea"
                                placeholder={getContent('protocol_item__skip_item__purpose_placeholder')}
                                rows={3}
                                value={purpose}
                                onChange={handleInput}
                                required
                            />
                        </FormGroup>
                        <SaveCancel
                          save={handleSubmit}
                          cancel={() => setShow(false)}
                          saveText= {getContent('protocol_item__skip_item__purpose_send')}
                          saveClassName={'mb-0 mt-2'}
                          saveBtnVariant={'primary'}
                          cancelText= {getContent('protocol_item__skip_item__purpose_cancel')}
                          cancelClassName={'mb-0 mt-2'}
                          cancelBtnVariant={'danger'}
                          
                        />
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SkipButton;
import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useContent } from '../../Content/cms';
import { useDispatch, useSelector } from 'react-redux';
import Apirest from '../../Content/Apirest';
import { useFeatureToogle } from '../../ProtectedRoutes/FeatureToggle/hook';

import { StoreState } from '../../store';
import { RESTORE_PROTOCOL_RES, SET_PROTOCOL_RES } from '../../store/protocol/types';
import { ProtocolResDataShort } from "../Protocol/types";

import DataFrame from '../../Commons/Components/DataFrame';
import Link from '../../Commons/SiteMap/Link';
import { IconButton } from '../../Commons/MultipleComponents';
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';


const ArchivedProtocolRes : React.FC = () => {

    let getContent = useContent();

    let {featureEnabled} = useFeatureToogle();

    let {participant_id} = useParams<{participant_id: string}>();

    let [show, setShow] = React.useState<boolean>(false);

    let protocolResponses = useSelector<StoreState, ProtocolResDataShort[]>(
        state => state.protocol.all_protocolres?.filter(p => !p.active));

    // dispatch protocol responses to store
    const dispatch = useDispatch();
    const setProtocolResInStore = (protocolResponses: ProtocolResDataShort[]) => dispatch({
        type: SET_PROTOCOL_RES,
        payload: protocolResponses
    });
    const restoreProtocolResInStore = (protocolResId: number) => dispatch({
        type: RESTORE_PROTOCOL_RES,
        payload: protocolResId
    });

    useEffect(
        () => {
            Apirest.get_participant_all_protocolres(
                participant_id, setProtocolResInStore
            )
        },
        [participant_id] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function restoreProtocolRes(participant_id: string, protocolres_id: number) {
        // Update protocols in front and back independently
        // if backend update fails, the protocol will be re-archived in the next refresh
        // and it's not a big problem but a bug to solve when they report it
        restoreProtocolResInStore(protocolres_id);
        Apirest.restore_protocol_res(
            participant_id,
            protocolres_id.toString()
        );
    }

    function protocol2row(protocol: ProtocolResDataShort) {

        return [
            <Link
                to={'protocolres'}
                params={[participant_id, protocol.id]}
                className={'muted-link'}
                featureNames={['readProtocolRes']}
            >
                {protocol.date_created.format('ll')}
            </Link>,

            <IconButton
                onClick={() => restoreProtocolRes(participant_id, protocol.id)}
                icon={{ icon: faTrashRestore, color: "#5CB6E7" }}
                text={getContent('archived__protocol_res__restore__tooltip')}
            />
        ]
    }

    function allProtocolRess2row(protocols: ProtocolResDataShort[]) {
        return protocols.map(protocol => protocol2row(protocol));    
    }

    // Return a modal with the data of the protocol responses in a dataframe
    return <>
        <button onClick={() => setShow(true)} className={'link-button'}>
            {getContent('archived__protocol_res__menu_btn')}
        </button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{getContent('archived__protocol_res__title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {protocolResponses.length > 0 && <DataFrame
                    dataframe={allProtocolRess2row(protocolResponses)}
                    pageSize={5}
                    striped={true}
                    clickeableRow={true}
                    hiddenColumns={[false, !featureEnabled(['archiveProtocolRes'])]}
                    columnClassName={'text-right'}
                />}

                {protocolResponses.length === 0 && <p>
                    <Alert variant={'warning'}>
                        {getContent('archived__protocol_res__empty')}
                    </Alert>
                </p>}
            </Modal.Body>
        </Modal>
    </>;
}

export default ArchivedProtocolRes;
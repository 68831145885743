import { CLEAR_ALL } from "../actions_all";
import { SurveyActionTypes, SurveysStoreState, 
    SET_SURVEYS, CLEAR_SURVEY, SET_SURVEY_RESPONSES,
    CLEAR_SURVEY_RESPONSES, ARCHIVE_SURVEY_RESPONSE,
    RESTORE_SURVEY_RESPONSE 
} from "./types";

const initialState: SurveysStoreState = {
    responses: []
};

export function SurveyReducer(state= initialState, action: SurveyActionTypes): SurveysStoreState {
    
    switch (action.type) {

        case SET_SURVEYS:
            return {
                ...state,
                surveys: [...action.payload]
            }
        
        case SET_SURVEY_RESPONSES:
            return {
                ...state,
                responses: [...action.payload]
            }
        
        case CLEAR_SURVEY_RESPONSES:
            return {
                ...state,
                responses: []
            }
        
        case ARCHIVE_SURVEY_RESPONSE:
            return {
                ...state,
                responses: state.responses.map((response) => {
                    if (response.id === action.payload) {
                        response.active = false;
                    }
                    return response;
                })
            }
        
        case RESTORE_SURVEY_RESPONSE:
            return {
                ...state,
                responses: state.responses.map((response) => {
                    if (response.id === action.payload) {
                        response.active = true;
                    }
                    return response;
                })
            }

        case CLEAR_ALL:
        case CLEAR_SURVEY:
            return initialState;

        default:
            return state;
    }
}
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Apirest from '../../../Content/Apirest';
import { Locale } from '../../../Dashboard/interfaces';
import { useContent } from '../../../Content/cms';

import { User, Examiner } from '../../../Dashboard/interfaces';

import { useFormFields } from '../../../Commons/Components/FormFields';
import { FormFieldValue, FormSubmitData } from '../../../Commons/Components/FormFields/types';
import { getEmptyValue, PopUpAlert, PopUpSuccess } from '../../../Commons/Helpers';
import { ImpersonificationData } from '../../../ProtectedRoutes/Impersonification/types';
import { StoreState } from '../../../store';
import { UserStoreState } from '../../../store/user/types';

import { ProtocolDataShort } from '../../../Dashboard/Protocol/types';
import { Survey } from '../../../Dashboard/Surveys/types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Badge from 'react-bootstrap/Badge';
import { FormField } from '../../../Commons/Components/FormFields/Field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputGroup, Spinner } from 'react-bootstrap';
import { RowSpinner } from '../../../Commons/MultipleComponents';
import ServerErrorAlert from '../../../Commons/Components/FormFields/Field/serverError';


interface UserFormProps {
    show: boolean
    onHide: () => void
    onSaveChanges: () => void
    availableProtocols: ProtocolDataShort[]
    availableSurveys: Survey[]
    availableTags: string[]
    impersonificationModes: string[]
    organizationOwnerID: number
    users: User[]
    locales: Locale[]
    user?: User
}

const UserForm: React.FC<UserFormProps> = (props) => {

    let getContent = useContent();

    let manager_user = useSelector<StoreState, UserStoreState>((state: StoreState) => state.user);


    let [fetchingEnabledData, setFetchingEnabledData] = useState<boolean>(false);

    let [enabledSurveys, setEnabledSurveys] = useState<Survey[]>([]);
    let [enabledProtocols, setEnabledProtocols] = useState<ProtocolDataShort[]>([]);
    let [enabledImpersonifications, setEnabledImpersonifications] = useState<{examiner_id:number, mode:string}[]>([]);
    let [assignedTags, setAssignedTags] = useState<string[]>([]);


    let availableTags = props.availableTags;
    

    const {
        setFormValues,
        submitHandler,
        setServerErrors,
        onChange,
        resetForm,
        state
    } = useFormFields(true, false);

    // Manage form values when props change
    useEffect(() => {
        if (!props.show) {
            resetForm();
            setEnabledSurveys([]);
            setEnabledProtocols([]);
            setEnabledImpersonifications([]);
            setServerErrors({});
            setAssignedTags([]);
        } else if (props.user) {
            setFormValues({
                first_name: props.user.first_name,
                last_name: props.user.last_name,
                email: props.user.email,
                locale: props.user.locale.id,
                is_manager: props.user.roles.manager_id !== null,
                is_examiner: props.user.roles.examiner_id !== null
            });

            setAssignedTags(props.user.tags)

            Apirest.get_impersonifications(props.user.id, (response: ImpersonificationData[]) => {
                setEnabledImpersonifications(
                    response.map(i => ({
                        examiner_id: i.examiner.id,
                        mode: i.mode
                    }))
                    
                );
            });

            if (props.user.roles.examiner_id !== null) {
                setFetchingEnabledData(true);

                // Load enabled surveys and protocols
                Apirest.get_examiner(props.user.roles.examiner_id!.toString(), (response: Examiner) => {

                    let examiner_surveys = response.surveys.map(
                        (s: number) => props.availableSurveys.find((survey: Survey) => survey.id.toString() === s.toString())
                    ).filter((s: Survey | undefined) => s !== undefined) as Survey[];

                    let examiner_protocols = response.protocols.map(
                        (p: number) => props.availableProtocols.find((protocol: ProtocolDataShort) => protocol.id.toString() === p.toString())
                    ).filter((p: ProtocolDataShort | undefined) => p !== undefined) as ProtocolDataShort[];

                    setEnabledSurveys(examiner_surveys);
                    setEnabledProtocols(examiner_protocols);
                    setFetchingEnabledData(false);
                });
            }
        }
    }, [props.show, props.user]); // eslint-disable-line react-hooks/exhaustive-deps

    function addTag(tag_name: string) {
        tag_name = tag_name.toLowerCase();
        
        setAssignedTags(prev => {

            // add survey from props.availableSurveys if it's not already in enabledSurveys
            if (prev.find(t => t === tag_name) === undefined) {
                return [
                    ...prev,
                    tag_name
                ];
            }
            return prev;
        });
    }

    function createTag(e: any){
        
        e.preventDefault();

        let tag_name =  state.values['createTag'] as string;

        if (tag_name !== ''){
            addTag(tag_name) 
        }
    }

    function removeTag(tag_name: string) {
        tag_name = tag_name.toLowerCase();
        setAssignedTags(prev => prev.filter(t => t !== tag_name));
    }

    function addSurvey(field_name: string, survey_id: FormFieldValue) {

        let survey_id_str = (survey_id as number).toString();
        
        setEnabledSurveys(prev => {

            // add survey from props.availableSurveys if it's not already in enabledSurveys
            if (prev.find(s => s.id.toString() === survey_id_str) === undefined) {
                return [
                    ...prev,
                    props.availableSurveys.find(s => s.id.toString() === survey_id_str)!
                ];
            }
            return prev;
        });
    }

    function removeSurvey(survey_id: string) {
        setEnabledSurveys(prev => prev.filter(s => s.id !== survey_id));
    }

    function addProtocol(field_name: string, protocol_id: FormFieldValue) {

        let protocol_id_str = (protocol_id as number).toString();
        
        setEnabledProtocols(prev => {

            // add survey from props.availableSurveys if it's not already in enabledSurveys
            if (prev.find(s => s.id.toString() === protocol_id_str) === undefined) {
                return [
                    ...prev,
                    props.availableProtocols.find(s => s.id.toString() === protocol_id_str)!
                ];
            }
            return prev;
        });
    }

    function removeProtocol(protocol_id: string) {
        setEnabledProtocols(prev => prev.filter(s => s.id !== protocol_id));
    }

    function addImpersonification(e: any) {
        e.preventDefault();
        
        let examiner_id = parseInt(state.values['impersonification__examiner_id'] as string);
        let mode = state.values['impersonification__mode'] as string;

        setEnabledImpersonifications(prev => {
            if (prev.find(i => (i.examiner_id === examiner_id) && (i.mode === mode)) === undefined) {
                return [
                    ...prev,
                    {
                        examiner_id: examiner_id,
                        mode: mode
                    }
                ];
            } else {
                PopUpAlert(getContent('admin__form__impersonification__already_added'), 'danger', 6000);
            }

            return prev;
        });
    }

    function removeImpersonification(examiner_id: number, mode: string) {
        setEnabledImpersonifications(prev => {
            return prev.filter(
                i => (i.examiner_id !== examiner_id) || (i.mode !== mode)
            );
        });
    }

    function createUser(user_data: FormSubmitData, is_examiner: boolean, is_manager: boolean, promiseSettleHandler: (results: PromiseSettledResult<string>[]) => void) {

        if (props.user) {
            throw new Error('User prop should not be defined to create a new user');
        }

        // NOTE: it's worth noting that the allSettled method is called inside the first promise pushed to the promises array
        // this is not a problem since the promise is first created and then executed synchronously and the allSettled method is called
        // once all promises are pushed to the array. This is done to ensure the user exists before the rest of the apirest calls are made
        let promises: Promise<string>[] = [];

        promises.push(new Promise<string>((resolve, reject) => {
            Apirest.create_user(
                user_data, 
                (response: User) => {
                    
                    resolve('user_create');

                    // Update the impersonifications
                    promises.push(new Promise<string>((resolve, reject) => {
                        Apirest.update_impersonifiations(
                            response.id,
                            {'impersonifications': enabledImpersonifications},
                            () => {
                                resolve('impersonifications_update');
                            },
                            (jqXHR: JQueryXHR) => {
                                reject(jqXHR.responseJSON);
                            }
                        );
                    }));

                    if (is_examiner) {
                        let examiner_data = {
                            user_id: response.id,
                            surveys: enabledSurveys.map(s => s.id),
                            protocols: enabledProtocols.map(p => p.id)
                        };

                        promises.push(new Promise<string>((resolve, reject) => {
                            Apirest.create_examiner(
                                examiner_data,
                                () => {
                                    resolve('examiner_create');
                                },
                                (jqXHR: JQueryXHR) => {
                                    reject(jqXHR.responseJSON);
                                }
                            );
                        }));
                    }

                    if (is_manager) {
                        let manager_data = {
                            user_id: response.id
                        };

                        promises.push(new Promise<string>((resolve, reject) => {
                            Apirest.create_manager(
                                manager_data,
                                () => {
                                    resolve('manager_create');
                                },
                                (jqXHR: JQueryXHR) => {
                                    reject(jqXHR.responseJSON);
                                }
                            );
                        }));
                    }

                    Promise.allSettled(promises).then(promiseSettleHandler);

                }, (jqXHR: JQueryXHR) => {
                    reject(jqXHR.responseJSON);
                    Promise.allSettled(promises).then(promiseSettleHandler);
                }
            );
        }));
    }

    function updateUser(user_data: FormSubmitData, is_examiner: boolean, is_manager: boolean, promiseSettleHandler: (results: PromiseSettledResult<string>[]) => void) {

        if (!props.user) {
            throw new Error('User is required to update user');
        }

        let user = props.user;
        let promises: Promise<string>[] = [];

        promises.push(new Promise<string>((resolve, reject) => {
            Apirest.update_user(
                user.id,
                user_data,
                () => {
                    resolve('user_update');
                },
                (jqXHR: JQueryXHR) => {
                    reject(jqXHR.responseJSON);
                }
            )
        }));

        // Update the impersonifications
        promises.push(new Promise<string>((resolve, reject) => {
            Apirest.update_impersonifiations(
                user.id,
                {'impersonifications': enabledImpersonifications},
                () => {
                    resolve('impersonifications_update');
                },
                (jqXHR: JQueryXHR) => {
                    reject(jqXHR.responseJSON);
                }
            );
        }));

        if (is_examiner) {
            let examiner_data = {
                user_id: user.id,
                surveys: enabledSurveys.map(s => s.id),
                protocols: enabledProtocols.map(p => p.id)
            };

            // UPDATE already existing examiner
            if (user.roles.examiner_id) {
                promises.push(new Promise<string>((resolve, reject) => {
                    Apirest.update_examiner(
                        user.roles.examiner_id!.toString(),
                        examiner_data,
                        () => {
                            resolve('examiner_protocol_and_survey_update');
                        },
                        (jqXHR: JQueryXHR) => {
                            reject(jqXHR.responseJSON);
                        }
                    );
                }));

            // CREATE new examiner
            } else {
                promises.push(new Promise<string>((resolve, reject) => {
                    Apirest.create_examiner(
                        examiner_data,
                        () => {
                            resolve('examiner_create');
                        },
                        (jqXHR: JQueryXHR) => {
                            reject(jqXHR.responseJSON);
                        }
                    );
                }));
            }

        // DELETE examiner
        } else if (user.roles.examiner_id) {
            promises.push(new Promise<string>((resolve, reject) => {
                Apirest.delete_examiner(
                    user.roles.examiner_id!.toString(),
                    () => {
                        resolve('examiner_delete');
                    },
                    (jqXHR: JQueryXHR) => {
                        reject(jqXHR.responseJSON);
                    }
                );
            }));
        }

        // CREATE new manager
        if (is_manager && !user.roles.manager_id) {
            let manager_data = {
                user_id: user.id
            };

            promises.push(new Promise<string>((resolve, reject) => {
                Apirest.create_manager(
                    manager_data,
                    () => {
                        resolve('manager_create');
                    },
                    (jqXHR: JQueryXHR) => {
                        reject(jqXHR.responseJSON);
                    }
                );
            }));
        
        // DELETE manager
        } else if (!is_manager && user.roles.manager_id) {
            promises.push(new Promise<string>((resolve, reject) => {
                Apirest.delete_manager(
                    user.roles.manager_id!.toString(),
                    () => {
                        resolve('manager_delete');
                    },
                    (jqXHR: JQueryXHR) => {
                        reject(jqXHR.responseJSON);
                    }
                );
            }));
        }

        Promise.allSettled(promises).then(promiseSettleHandler);
    }

    function submitData(data: FormSubmitData) {

        let promise = new Promise<void>((resolve, reject) => {

            let user_data = {
                first_name: data['first_name'],
                last_name: data['last_name'],
                email: data['email'],
                locale: data['locale'],
                tags: assignedTags
            }

            // extract is_examiner and is_manager from user_data and remove them from user_data
            let is_examiner = data['is_examiner'] as boolean;
            let is_manager = data['is_manager'] as boolean;

            function promiseSettleHandler(results: PromiseSettledResult<string>[]) {

                const hasRejections = results.some(result => result.status === 'rejected');
    
                if (hasRejections) {
    
                    // Extract and show success pupups for all successful promises
                    results
                        .filter(result => result.status === 'fulfilled')
                        .forEach((result) => {
                            PopUpSuccess('admin__form__' + (result as PromiseFulfilledResult<string>).value + '__success');
                        });
                    
                    // Extract and merge all rejection reasons
                    const rejectionReasons = results
                        .filter(result => result.status === 'rejected')
                        .map(result => (result as PromiseRejectedResult).reason);
        
                    const mergedReasons = Object.assign({}, ...rejectionReasons);
                    reject(mergedReasons);
                } else {
                    PopUpSuccess(getContent('admin__form__user__saved__success'));
                    resolve();
                    props.onSaveChanges();
                    props.onHide();
                }
            }

            if (props.user) {
                updateUser(user_data, is_examiner, is_manager, promiseSettleHandler);
            } else {
                createUser(user_data, is_examiner, is_manager, promiseSettleHandler);
            }
        });

        return promise;
    }


    let protocol_options = props.availableProtocols.map((protocol: ProtocolDataShort) => {
        return { value: protocol.id, label: protocol.name };
    });

    let survey_options = props.availableSurveys.map((survey: Survey) => {
        return { value: survey.id, label: survey.name };
    });

    let examiner_options = (props.users
        .filter(user => user.roles.examiner_id !== null)
        .map(user => ({
            value: user.roles.examiner_id as number,
            label: user.first_name + ' ' + user.last_name
        }))
    );

    let impersonification_modes_options = props.impersonificationModes.map(m => ({
        value:m, 
        label: getContent('admin_user_form__impersonification_modes__' + m)
    }));

    let addImpersonificationButtonDisabled = state.values['impersonification__examiner_id'] === undefined || state.values['impersonification__mode'] === undefined;

    let isManagerCheckboxDisabled = (
        !manager_user.info ||
        !manager_user.info.roles.is_owner ||
        (props.user !== undefined && props.user.id === props.organizationOwnerID)
    );
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            className={'p-3'}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {getContent('adming_user_form__title__' + (props.user ? 'edit' : 'create'))}
                </Modal.Title>
            </Modal.Header>
            
            <Form onSubmit={submitHandler(submitData)}>
            <Modal.Body>
                <Container>
                    <Row className={'mb-2'}>
                        <Col>
                            <h5 className={'text-info'}>{getContent('admin_user_form__profile__title')}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Container className={'text-left w-100'}>
                            <Row className={'mb-2'}>
                                <Col>
                                    <FormField
                                        type={"text"}
                                        name={"first_name"}
                                        placeholder={getContent("admin__form__first_name")}
                                        value={state.values['first_name'] || getEmptyValue("text")}
                                        serverErrors={state.errors['first_name']}
                                        onChange={onChange}
                                        required
                                        useCMS
                                    />
                                </Col>
                                <Col>
                                    <FormField
                                        type={"text"}
                                        name={"last_name"}
                                        placeholder={getContent("admin__form__last_name")}
                                        value={state.values['last_name'] || getEmptyValue("text")}
                                        serverErrors={state.errors['last_name']}
                                        onChange={onChange}
                                        required
                                        useCMS
                                    />
                                </Col>
                            </Row>
                            <Row className={'mb-2'}>
                                <Col>
                                    <FormField
                                        type={"email"}
                                        name={"email"}
                                        placeholder={getContent("admin__form__email")}
                                        value={state.values['email'] || getEmptyValue("email")}
                                        serverErrors={state.errors['email']}
                                        onChange={onChange}
                                        disabled={props.user !== undefined} // email is not editable when editing a user
                                        required
                                        useCMS
                                    />
                                </Col>
                                <Col>
                                    <FormField
                                        type={"select"}
                                        name={"locale"}
                                        placeholder={getContent("admin__form__locale")}
                                        value={state.values['locale'] || getEmptyValue("select")}
                                        options={props.locales.map(locale => ({ value: locale.id, label: locale.code }))}
                                        serverErrors={state.errors['locale']}
                                        onChange={onChange}
                                        required
                                        useCMS
                                    />
                                </Col>
                            </Row>
                            <Row className={'mb-3 d-flex '}>
                                <Col sm={6}>
                                    <FormField
                                        type={"checkbox"}
                                        name={"is_manager"}
                                        label={getContent("admin__form__is_manager")}
                                        value={state.values['is_manager'] || getEmptyValue("checkbox")}
                                        serverErrors={state.errors['is_manager']}
                                        onChange={onChange}
                                        disabled={isManagerCheckboxDisabled}
                                        useCMS
                                    />
                                    </Col>
                                    <Col>
                                    <FormField
                                        type={"checkbox"}
                                        name={"is_examiner"}
                                        label={getContent("admin__form__is_examiner")}
                                        value={state.values['is_examiner'] || getEmptyValue("checkbox")}
                                        serverErrors={state.errors['is_examiner']}
                                        onChange={onChange}
                                        useCMS
                                    />
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                
                                <Accordion >
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} className='cursor' eventKey="0">
                                            <p className='pb-0 mb-0'>{getContent('add_tag__select_available_tag__title')}</p>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                {availableTags.map((tag, i) => 
                                                    assignedTags.includes(tag)
                                                        ? null :
                                                        <Badge
                                                            key={i.toString()}
                                                            className={'mr-1 ml-0 cursor badge-tags'}
                                                            onClick={()=>addTag(tag)}
                                                        >{tag}</Badge>
                                                )}
                                                </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                    <InputGroup>
                                        <Col sm={6} className='m-0 p-0 d-flex justify-content-center'>
                                            <FormField
                                                type={"text"}
                                                name={"createTag"}
                                                onChange={onChange}
                                                placeholder={getContent('add_tag__create__form__text_placeholder')} 
                                                className='m-0'               
                                            />
                                            </Col>
                                            <Col className='m-0 d-flex justify-content-center'>
                                                <Button type={'submit'} onClick={createTag} className={'create-tag-button pr-0'} size='sm'> {getContent('add_tag__create__form__button')} </Button> 
                                            </Col>
                                    </InputGroup>  
                                </Col>
                                <Col sm={'6'} className={'mb-2 bg-light p-3 '}>
                                <Row>
                                
                                    {assignedTags.length > 0 ? 
                                    <p className={'text-info pb-0 mb-2'}> {getContent('admin__form__assigned_tags__title')}</p> 
                                    : <p className={'text-center m-auto'}>{getContent('admin__form__tags_assigned__empty')}</p>}
                                </Row>
                                <Row className='d-flex flex-wrap'>
                                    {assignedTags.length <= 0 
                                        ?  null 
                                        :
                                        assignedTags.map((t, i) => 
                                            <div key={i.toString()} className={'d-flex align-items-center mr-3 mt-2'}>
                                                <Badge  className={'badge-tags'}>{t}</Badge>
                                                <FontAwesomeIcon icon={faTimes} onClick={()=> removeTag(t)} className={'m-auto delete-list-item-icon'}/>
                                            </div>
                                        )
                                    }
                                </Row>
                                </Col> 
                            </Row>
                            <Row>
                            
                            </Row>
                            <Row>
                            
                            </Row>
                        </Container>
                    </Row>
                </Container>

                <Container className='mt-3 pt-3' style={{ borderTop: "1px solid #ebebeb" }}>
                    <Row className={'mb-2'}>
                        <Col>
                            <h5 className={'text-info'}>{getContent('admin__form__impersonification__title')}</h5>
                        </Col>
                    </Row>
                    <Row className={'my-3'}>
                        <Col>
                        {enabledImpersonifications.length <= 0 ?
                            <p className='bg-light p-3 text-center'> {getContent('admin__form__impersonification__enabled_empty')}</p> 
                            :
                            <ListGroup as="ol"  className='bg-info-light'>
                                <p className={'pt-3 pl-3 '}>{getContent('admin__form__impersonification__enabled')}</p>
                                {enabledImpersonifications.map((impersonification, i) => {
                                    
                                    let current_user = props.users.find(user => user.roles.examiner_id === impersonification.examiner_id);

                                    if (!current_user || current_user.roles.examiner_id === null) {
                                        console.log("USER ERROR", current_user, impersonification, props.users);
                                        throw new Error('User not found or is not an examiner, this is a bug');
                                    }

                                    return (
                                    <ListGroupItem key={i} className='small-text '>
                                        <Row>
                                            <Col>
                                                <small>{(i+1).toString() + '. ' + current_user.first_name + ' ' + current_user.last_name}</small>
                                                <Badge variant="warning">{getContent('admin_user_form__impersonification_modes__' + impersonification.mode)}</Badge>
                                            </Col>
                                            <Col xs={2} className={'text-right'}>
                                                <FontAwesomeIcon icon={faTimes} onClick={() => removeImpersonification(impersonification.examiner_id, impersonification.mode)} className={'mr-0 pr-0 delete-list-item-icon'}/>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>);
                                }
                                )}
                            </ListGroup>}
                            <ServerErrorAlert
                                serverErrors={state.errors['impersonifications']}
                                prefix={'admin_user_form__impersonifications'}
                                useCMS
                            />
                        </Col>
                        <Col>
                            <Form.Group className={'text-left'} >
                                <FormField
                                    type={"select"}
                                    name={"impersonification__examiner_id"}
                                    placeholder={getContent("admin__form__add_impersonification__examiner_id")}
                                    value={state.values['impersonification__examiner_id'] || getEmptyValue("select")}
                                    options={examiner_options}
                                    onChange={onChange}
                                    useCMS
                                />

                                <FormField
                                    type={"select"}
                                    name={"impersonification__mode"}
                                    placeholder={getContent("admin__form__add_impersonification__mode")}
                                    value={state.values['impersonification__mode'] || getEmptyValue("select")}
                                    options={impersonification_modes_options}
                                    onChange={onChange}
                                    useCMS
                                />
                                
                                <Button onClick={addImpersonification} className={'mt-3'} disabled={addImpersonificationButtonDisabled}>
                                    {getContent('admin__form__add_impersonification')}
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>

                
                {state.values['is_examiner'] && <Container className='mt-3 pt-3' style={{ borderTop: "1px solid #ebebeb" }}>
      
                    <Row>
                        <Col>
                            <h5 className={'text-info'}>{getContent('admin__form__surveys_and_protocol__title')}</h5>
                        </Col>
                    </Row>

                    <RowSpinner show={fetchingEnabledData} />
                    
                    {!fetchingEnabledData && <Row className={'my-3'}>
                        <Col>
                        {enabledSurveys.length <= 0 ?
                            <p className='bg-light p-3 text-center'> {getContent('admin__form__enabled_surveys__empty')}</p> 
                            :
                            <ListGroup as="ol"  className={'bg-info-light'}>
                                <p className='pt-3 pl-3'>{getContent('admin__form__enabled_surveys__title')}</p>
                                {enabledSurveys.map((survey, i) => 
                                    <ListGroupItem key={survey.id} className='small-text '>
                                        <Row className={'w-100 mb-0'}>
                                            <Col >
                                                {(i+1).toString() + '. ' + survey.name}
                                            </Col>
                                            <Col xs={2} className={'text-right'}>
                                                <FontAwesomeIcon icon={faTimes} onClick={() => removeSurvey(survey.id)} className={'mr-0 pr-0 delete-list-item-icon'}/>
                                            </Col>
                                        </Row>
                                    </ListGroupItem>
                                )}
                            </ListGroup>}
                        </Col>
                        <Col >
                            <FormField
                                type={"select"}
                                name={"add_survey"}
                                placeholder={getContent("admin__form__add_surveys")}
                                value={getEmptyValue("select")}
                                options={survey_options}
                                onChange={addSurvey}
                                useCMS 
                            />
                        </Col>
                    </Row>}
                    {!fetchingEnabledData && <Row className='my-3'>
                        <Col>
                        {enabledProtocols.length <= 0 ?
                            <p className='bg-light p-3 text-center'> {getContent('admin__form__enabled_protocols_empty')}</p> 
                            :
                        <ListGroup as="ol" className={'bg-info-light'}>
                            <p className='pt-3 pl-3'>{getContent('admin__form__enabled_protocols__title')}</p>
                                {enabledProtocols.map((protocol, i) => 
                                    <ListGroupItem key={protocol.id} className='small-text ' >
                                         <Row className={'w-100 mb-0'}>
                                            <Col>
                                                {(i+1).toString() + '. ' + protocol.name}
                                             </Col>
                                             <Col xs={2}className={'text-right'}>
                                                <FontAwesomeIcon icon={faTimes} onClick={() => removeProtocol(protocol.id)} className={'mr-0 pr-0 delete-list-item-icon'} />
                                            </Col>
                                         </Row>
                                    </ListGroupItem>
                                )}
                            </ListGroup>}
                        </Col>
                        <Col>
                            <FormField
                                type={"select"}
                                name={"add_protocol"}
                                placeholder={getContent("admin__form__add_protocol")}
                                value={getEmptyValue("select")}
                                options={protocol_options}
                                onChange={addProtocol}
                                useCMS
                            />
                        </Col>
                    </Row>}
                </Container>}

            </Modal.Body>
            <Modal.Footer  className='mt-3 text-center' style={{ borderTop: "1px solid #ebebeb" }}>
                <Button type={'submit'} disabled={state.sending}>
                    {!state.sending && getContent('admin__form__save_changes')}
                    {state.sending && <Spinner animation="border" size={'sm'} as={'span'} />}
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UserForm;
import { CLEAR_ALL } from "../actions_all";
import { UserActionTypes, UserStoreState, SET_USER_INFO, CLEAR_USER_INFO } from "./types";

const initialState: UserStoreState = {};

export function UserReducer(state= initialState, action: UserActionTypes): UserStoreState {
    switch (action.type) {

        case SET_USER_INFO:
            return {
                ...state,
                info: {...action.payload}
            };

        case CLEAR_ALL:
        case CLEAR_USER_INFO:
            return initialState;

        default:
            return state;
    }
}
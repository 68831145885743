import { CLEAR_ALL } from "../actions_all";
import {dirtyState, SET_DIRTY_STATE, DirtyActionActionTypes, CLEAR_DIRTY_STATE} from "./types";

const initialState: dirtyState = {};

export function DirtyStateReducer(state=initialState, action: DirtyActionActionTypes): dirtyState {

    let newState = {...state};

    switch (action.type) {

        case SET_DIRTY_STATE:
            newState[action.payload[0]] = action.payload[1];
            break;

        case CLEAR_ALL:
        case CLEAR_DIRTY_STATE:
            newState = initialState;
            break;

        default:
            break;
    }

    return newState;
}
import { useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { ToggleDict } from '../../store/featureToggle/types';


export function useFeatureToogle() {

    let toggleDict = useSelector<StoreState, ToggleDict|undefined>(s => s.featureToggle.table);

    function featureEnabled(featureNames: string[]) {
        const impersonification = JSON.parse(localStorage.getItem('impersonification') || "null");
    
        if (impersonification === null) {
            return true;
        }

        return featureNames.some(
            f => (toggleDict !== undefined) && toggleDict[impersonification.mode][f]
        );
    }

    function toggleElement(featureNames: string[], toggled_element: any, toggled_alternative: any = undefined) {
        if (featureEnabled(featureNames)) {
            return toggled_element;
        }
    
        return toggled_alternative;
    }

    return {
        featureEnabled,
        toggleElement
    };
}
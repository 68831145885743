import ReactGA from 'react-ga';

const trackingMiddleware = (store: any) => (next: any) => (action: any) => {
  
  let label = JSON.stringify(action.payload || {});
  if (label.length > 8000) {
    label = 'Action payload to large (>8000)';
  }

  ReactGA.event({
    category: 'Store dispatch',
    action: action.type,
    label: label
  });

  return next(action);
};

export default trackingMiddleware;
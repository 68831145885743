import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {useContent} from '../../../Content/cms';

// Import Components
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const GoBackButton : React.FC<RouteComponentProps> = (props) => {

	let getContent = useContent();

    return (
		<button className={'link-button ml-3 mr-3 text-white'} onClick={props.history.goBack} >
			<FontAwesomeIcon icon={faAngleLeft} color={'white'} className={'mr-2'}/> 
			{getContent('commons__components__gobackbutton')}
		</button>
	);
};

export default withRouter(GoBackButton);

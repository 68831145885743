import { AllActionsTypes } from "../actions_all";

export type popUpAlertVariant = ('success' | 'danger');

export interface PopUpAlertMessage {
    timestamp: number
    text: React.ReactNode
    variant: popUpAlertVariant,
    duration?: number
}

export interface popUpAlertStoreState {
    message_list: PopUpAlertMessage[]
}

export const ADD_POP_UP_ALERT = 'ADD_POP_UP_ALERT';
export const REMOVE_POP_UP_ALERT = 'REMOVE_POP_UP_ALERT';
export const CLEAR_POP_UP_ALERTS = 'CLEAR_POP_UP_ALERTS';

export interface addPopUpAction {
    type: typeof ADD_POP_UP_ALERT,
    payload: PopUpAlertMessage
}

export interface removePopUpAlertAction {
    type: typeof REMOVE_POP_UP_ALERT
    payload: number
}

export interface clearPopUpAertAction {
    type: typeof CLEAR_POP_UP_ALERTS
}

export type PopUpAlertActionTypes =
    | addPopUpAction
    | removePopUpAlertAction
    | clearPopUpAertAction
    | AllActionsTypes;

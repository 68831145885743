import * as React from 'react';
import { useContent } from "../Content/cms";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Apirest from "../Content/Apirest";

// Store state
import {StoreState} from "../store";
import { CLEAR_SERVER_ERROR, ServerError } from "../store/server/types";

// Import components
import { Modal, Form, Button } from "react-bootstrap";
import { PopUpError, PopUpSuccess } from "../Commons/Helpers";

const ServerErrorModal : React.FC = () => {

    let getContent = useContent();

    let [comment, setComment] = useState<string>('');
    let serverError = useSelector<StoreState, ServerError|undefined>(state => state.server.error);

    const dispatch = useDispatch();
    const clearServerError = () => dispatch({type: CLEAR_SERVER_ERROR});

    const onSubmit = () => {
        if (serverError !== undefined) {
            Apirest.send_server_error_user_report(
                serverError,
                comment,
                () => {
                    PopUpSuccess(getContent('base__server_error__send_sucess'));
                    setComment('');
                    clearServerError();
                },
                () => {
                    PopUpError(getContent('base__server_error__send_fail'));
                    setComment('');
                    clearServerError();
                }
            );
        } else {
            console.error('Server error is undefined');
        }
    };

    return (
        <Modal show={serverError !== undefined} onHide={clearServerError}>
            <Modal.Header closeButton className={'border-muted'}>
                <Modal.Title className={'text-primary'}>{getContent('base__server_error__title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'border-muted'}>
                <strong>{getContent('base__server_error__subtitle')}</strong>
                <p className={'mb-0'}>{getContent('base__server_error__text')}</p>
                <Form>
                    <Form.Group>
                        <Form.Control
                            type={'input'}
                            as={'textarea'}
                            name={'user_comment'}
                            placeholder={getContent('base__server_error__comment_placeholder')}
                            value={comment}
                            onChange={(e: any) => setComment(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button className={"m-2 col-4"} variant={"primary"} onClick={onSubmit} block disabled={comment === ''}>
                        {getContent('base__server_error__submit')}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
};

export default ServerErrorModal;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Apirest from "../../Content/Apirest";
import { UserInfo } from '../../interfaces/user';
import history from '../../routerHistory';
import SiteMap from '../../Commons/SiteMap';

import { StoreState } from '../../store';
import { SET_USER_INFO, UserStoreState } from "../../store/user/types";

// Import JSX Components
import LoaderSpinner from "../../Commons/Components/LoaderSpinner";
import Impersonification from '../Impersonification';


const UserConfig: React.FC = ({children}) => {
    
    let [userConfigLoaded, setUserConfigLoaded] = useState<boolean>(false);
    let [showImpersonificationModal, setShowImpersonificationModal] = useState<boolean>(true);

    let user = useSelector<StoreState, UserStoreState>(state => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        
        Apirest.get_user_info((response: UserInfo) => {
            dispatch({
                type: SET_USER_INFO,
                payload: response
            });

            setUserConfigLoaded(true);
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!userConfigLoaded) {
        return <LoaderSpinner />
    }

    let is_examiner = user.info && (user.info.roles.is_examiner || user.info.is_impersonifing);
    let is_admin = user.info && (user.info.roles.is_manager || user.info.roles.is_owner);
    let page_is_admin = window.location.pathname === SiteMap.getFullURL('admin');

    // if the user is an examiner, or and admin in the admin page we show the page
    if (is_examiner || (is_admin && page_is_admin)) {
        return <>{children}</>;
    }

    // if is admin, redirect to the admin dashboard
    if (is_admin && !page_is_admin) {
        history.push(SiteMap.getFullURL('admin'));
        return null;
    }

    return (
        <Impersonification
            show={showImpersonificationModal}
            onHide={() => setShowImpersonificationModal(false)}
            disable_close_action
        />
    );
};

export default UserConfig;

import * as React from "react";
import { useState, useEffect } from "react";
import { useHistory} from 'react-router';
import SiteMap from "../../Commons/SiteMap";
import SessionManager from "../SessionManager";
import { getDesign } from "../../Commons/WhiteLabeling";

import { PopUpError } from "../../Commons/Helpers";
import { useLocalContent } from "../GetLanguageAuth/useLocalContent";

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from "react-bootstrap/Spinner";
import Link from "../../Commons/SiteMap/Link";
import AuthLanguageForm from "../GetLanguageAuth"

const Login: React.FC = () => {

    let history = useHistory();
    let getContent = useLocalContent();

    let [formValue, setFormValue] = useState({email:'', password:''});
    let [checkingLogin, setCheckingLogin] = useState<boolean>(false);
    let [errorFormClass, setErrorFormClass ] = useState<boolean>(false);

    const mobiles = require('is-mobile');
    let mobile = mobiles();

    // Initially check if loggin info is set
    useEffect(() => {
        if (localStorage.getItem('jwt_token') !== null) {
            history.push(SiteMap.getFullURL('dashboard'));
        }
    });

    const handleInput = (event: any) =>{
        const {name, value} = event.target;
        setFormValue({...formValue, [name]: value});
    }

    const handleSubmit = (event : React.FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        setCheckingLogin(true);
        SessionManager.login(
            formValue.email, formValue.password,
            () => {
                setCheckingLogin(false);
                setErrorFormClass(false);
            },
            () => {
                setFormValue({email:'', password:''});
                PopUpError(getContent('login__authentication__failed'));
                setCheckingLogin(false);
                setErrorFormClass(true);
            }
        );
    }

    const whiteLabels = getDesign();

    return (
        <Container fluid className={'deg-bg vh-100 pt-5'}>
            <Row className={'text-center d-flex align-items-center pt-5'}>
                <Col sm={5} className={'d-none d-lg-block pl-0'}>
                    <img
                        src={whiteLabels.logos.login}
                        alt={'logo'}
                        className={'w-50 pt-5 align-self-center'}
                    />
                </Col>
                <Col sm={12} lg={7}>
                <Card className={'bg-light m-auto p-3 mt-4 ' + (mobile ? 'w-100': 'w-50')}>
                    <Card.Body>
                        <Card.Title className={'d-none d-lg-block  m-auto'}>
                            <p>{getContent('login__authentication__title')}</p>
                        </Card.Title>
                        <Card.Text as='div'>
                            <Form className={'text-left'} onSubmit={handleSubmit}>
                                <Form.Group >
                                    <Form.Control 
                                        as={'input'}
                                        name={'email'}
                                        type={'email'}
                                        value={formValue.email}
                                        onChange={handleInput}
                                        placeholder={getContent('login__authentication__placeholder_email')}
                                        required
                                        isInvalid={errorFormClass}
                                    />
                                </Form.Group>
                                <Form.Group >
                                    <Form.Control
                                        as={'input'}
                                        type={'password'}
                                        name={'password'}
                                        value={formValue.password}
                                        onChange={handleInput}
                                        placeholder={getContent('login__authentication__placeholder_password')}
                                        required
                                        isInvalid={errorFormClass}
                                    />
                                    <Form.Control.Feedback type="invalid" className={'text-center'}>
                                        {getContent('login__authentication__invalid_inputs')}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button type={'submit'} disabled={checkingLogin} className={'w-100'}>
                                    {!checkingLogin && <p className={'p-0 mb-0'}>{getContent('login__authentication__button')}</p>}
                                    {checkingLogin && <Spinner animation="border" variant="primary" />}
                                </Button>
                            </Form>
                        </Card.Text>
                            <Link to={'reset_password'} className="mt-2">
                                {getContent('login__authentication__reset_password_button')}
                            </Link>
                    </Card.Body>
                    </Card>
                    <AuthLanguageForm />   
                </Col> 
            </Row>
        </Container>
    );
};

export default Login;
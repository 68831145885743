import * as React from 'react';
import {useState, useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Apirest from '../../Content/Apirest';
import { Moment } from 'moment';
import {useContent} from '../../Content/cms';
import { downloadSurveyResponse } from '../Surveys/Response';

// import interfaces
import { SurveyResponse } from '../Surveys/types';

// import store
import { SET_PROTOCOL_RES, ARCHIVE_PROTOCOL_RES } from '../../store/protocol/types';
import { SET_SURVEY_RESPONSES, ARCHIVE_SURVEY_RESPONSE } from '../../store/surveys/types';
import { StoreState } from '../../store';
import { ProtocolResDataShort } from "../Protocol/types";

// Import component
import Link from '../../Commons/SiteMap/Link';
import { IconButton } from '../../Commons/MultipleComponents';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask} from "@fortawesome/free-solid-svg-icons";
import Section from "../../Commons/Section";
import DataFrame from "../../Commons/Components/DataFrame";
import ParticipantHeader from '../../Commons/Components/Participant/Header';
import SurveyResponseView from '../Surveys/Response';
import EvolutionChart from './EvolutionChart';
import GoBackButton from '../../Commons/Components/GoBackButton';
import { faForward, faFileCsv, faArchive, faComment } from '@fortawesome/free-solid-svg-icons';
import FeatureToggle from '../../ProtectedRoutes/FeatureToggle';
import { useFeatureToogle } from '../../ProtectedRoutes/FeatureToggle/hook';


const Participant : React.FC = () => {

    let getContent = useContent();

    let {featureEnabled} = useFeatureToogle();
    let {toggleElement} = useFeatureToogle();
    
    const mobiles = require('is-mobile');
    let mobile = mobiles();

    let {participant_id} = useParams<{participant_id: string}>();

    let [showedSurveyResponse, setShowedSurveyResponse] = useState<SurveyResponse | undefined>(undefined);

    let [showedProtocolComment, setShowedProtocolComment] = useState<string | undefined>(undefined);

    let protocolResponses = useSelector<StoreState, ProtocolResDataShort[]>(
        state => state.protocol.all_protocolres.filter(p => p.active));

    // dispatch protocol responses to store
    const dispatch = useDispatch();
    const setProtocolResInStore = (protocolResponses: ProtocolResDataShort[]) => dispatch({
        type: SET_PROTOCOL_RES,
        payload: protocolResponses
    });
    const archiveProtocolResInStore = (protocolResId: number) => dispatch({
        type: ARCHIVE_PROTOCOL_RES,
        payload: protocolResId
    });

    let surveyResponses = useSelector<StoreState, SurveyResponse[]>(
        state => state.survey.responses.filter(s => s.active)
    );

    // dispatch survey responses to store
    const setSurveyResponsesInStore = (surveyResponses: SurveyResponse[]) => dispatch({
        type: SET_SURVEY_RESPONSES,
        payload: surveyResponses
    });
    const archiveSurveyResponseInStore = (surveyResponseId: number) => dispatch({
        type: ARCHIVE_SURVEY_RESPONSE,
        payload: surveyResponseId
    });

    // Effect triggered only once after mounting (ComponentDidMount).
    useEffect(
        () => {
            Apirest.get_participant_all_protocolres(participant_id, setProtocolResInStore);
            Apirest.get_participant_survey_responses(participant_id, setSurveyResponsesInStore);
        },
        [participant_id] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const activityHiddenColumns = [
        false, // link to protocol survey res (link desabled from Link element)
        mobile, // column hidden to mobile
        !featureEnabled(['archiveProtocolRes', 'archiveSurveyRes']),
        !featureEnabled(['downloadSurveyRes']), 
        !featureEnabled(['recordProtocol'])
    ];

    
    function protocolRes2row(protocolres: ProtocolResDataShort) {

        return [
            protocolres.date_created,

            <Link
                to={'protocolres'}
                params={[participant_id, protocolres.id]}
                className={'muted-link'}
                featureNames={['readProtocolRes']}
            >
                {!mobile && protocolres.date_created.format('ll')}
                {mobile && <div>
                    <b>{protocolres.protocol.name}</b>
                    <div className='d-flex justify-content-between'> 
                        <small className={'mb-0'}>
                            {protocolres.date_created.format('l')}
                        </small>
                    </div>
                </div>}
            </Link>,

            <Link
                to={'protocolres'}
                params={[participant_id, protocolres.id]}
                className={'muted-link'}
                featureNames={['readProtocolRes']}
            >
                {protocolres.protocol.name}
            </Link>,

            <IconButton
                onClick={() => archiveProtocolRes(participant_id, protocolres.id)}
                icon={{ icon: faArchive, color: "#5CB6E7" }}
                text={getContent('protocol_response__archive__tooltip')}
            />,

           '',

            !protocolres.is_completed ? 
                <Link
                    to={'resume_protocol'}
                    params={[participant_id, protocolres.id]}
                >
                    <IconButton
                        icon={{ icon: faForward, color: "#5CB6E7" }}
                        text={getContent('protocol_result__feature__resume')}
                    />
                </Link> : 
                (protocolres.comment !== "" ?  
                    <IconButton
                        onClick={() => setShowedProtocolComment(protocolres.comment)}
                        icon={{ icon: faComment, color: "#5CB6E7" }}
                        text={getContent('protocol_result__comment_Icon')}
                    /> :
                    undefined
                )
        ]
    }

    function surveyResponse2row(survey_response: SurveyResponse) {
        return [
            survey_response.date_created,

            <p className={'mb-0'} onClick={() => setShowedSurveyResponse(survey_response)}>
                {!mobile && survey_response.date_created.format(mobile ? 'l' : 'll')}
                {mobile && <div>
                    <p className={'mb-0'} onClick={() => setShowedSurveyResponse(survey_response)}>
                        <b>{survey_response.survey.name}</b>
                    </p>
                    <div className='d-flex justify-content-between'> 
                        <small className={'mb-0'}>{survey_response.date_created.format('l')}</small>
                    </div>
                </div>}
            </p>,

            <p className={'mb-0'} onClick={() => setShowedSurveyResponse(survey_response)}>
                {survey_response.survey.name}
            </p>,
        
            <IconButton
                onClick={() => archiveSurveyResponse(participant_id, survey_response.id)}
                icon={{ icon: faArchive, color: "#5CB6E7" }}
                text={getContent('survey_response__archive__tooltip')}
            />,
            
            <IconButton
                onClick={() => downloadSurveyResponse(participant_id, survey_response.id.toString())}
                icon={{ icon: faFileCsv, color: "#5CB6E7" }}
                text={getContent('survey_result__feature__download_csv')}
            />,

            undefined
        ];
    }

    function activity2DataFrame(surveys: SurveyResponse[], protocols: ProtocolResDataShort[]) {
        let survey_rows = surveys.map(s => surveyResponse2row(s))
        let protocol_rows = protocols.map(p => protocolRes2row(p))

        let rows = [...survey_rows, ...protocol_rows];
        rows.sort((a,b) => (a[0] as Moment).isBefore(b[0] as Moment ) ? 1: -1);
        for (let row of rows) {
            row.shift();
        }
        return rows;
    }

    function archiveProtocolRes(participant_id: string, protocolres_id: number) {
        // Update protocols in front and back independently
        // if backend update fails, the protocol will be restored in the next refresh
        // and it's not a big problem but a bug to solve when they report it
        archiveProtocolResInStore(protocolres_id)
        Apirest.archive_protocol_res(
            participant_id,
            protocolres_id.toString()
        );
    }

    function archiveSurveyResponse(participant_id: string, survey_response_id: number) {
        // Update survey in front and back independently
        // if backend update fails, the survey will be restored in the next refresh
        // and it's not a big problem but a bug to solve when they report it
        archiveSurveyResponseInStore(survey_response_id);
        Apirest.archive_survey_response(
            participant_id,
            survey_response_id.toString()
        );
    }

    let toggleEvolutionChart = toggleElement(['readProtocolRes','readSurveyRes'], <EvolutionChart />,<Container className={'bg-light p-5'} fluid> <Alert variant={'light'} className={'m-5 text-center'}>{getContent('toggle_content__unable_feature__alert')}</Alert> </Container> )


    let thereIsActivity = surveyResponses.length + protocolResponses.length > 0;

    return (
        <Container fluid className={'content-wrapper'}>
            <ParticipantHeader fetch_data/>
            <Row className={'bg-info p-2 d-flex justify-content-between'}>
            <Col className={'mt-2'}>
                <h5 className={'text-white  font-weight-bold mb-0 pb-0'}>
                    <FontAwesomeIcon icon={faFlask} id={'flaskIcon'}/>  {getContent("mind_lab__title")}
                </h5>
            </Col>
            <GoBackButton/>
            </Row>
            
            <Container fluid className={'px-3'}>
                <Row>
                    <Col sm={12}>
                        <Section
                            title={getContent("mind_lab__recent_activity__title")}
                            className={'secondary-section'}
                            headerClassName={'secondary-section-header'}
                            bodyClassName={'secondary-section-body'}
                        >
                            {thereIsActivity && <DataFrame
                                dataframe={activity2DataFrame(surveyResponses, protocolResponses)}
                                pageSize={5}
                                striped={true}
                                clickeableRow={true}
                                columnClassName={'text-right'}
                                columnWidths={[26,50,8,8,8]}
                                hiddenColumns={activityHiddenColumns}
                            />}
                            {!thereIsActivity && <div className='bg-light text-info text-center p-3'>
                                {getContent("mind_lab__recent_activity__empty_data")}
                            </div>}
                        </Section>
                    </Col>
                </Row>

                    <Row>
                        <Col sm={12}>
                            <Section
                                title={getContent("evolution_chart__protocols__title")}
                                subtitle={toggleElement( ['readProtocolRes','readSurveyRes'], getContent("evolution_chart__protocols__subtitle"), null)}
                                className={'secondary-section'}
                                headerClassName={'secondary-section-header'}
                                bodyClassName={'secondary-section-body'}
                            >
                               {toggleEvolutionChart}
                            </Section>
                        </Col>
                    </Row>
            </Container>

            <FeatureToggle featureNames={['readSurveyRes']}>
                {showedSurveyResponse && <SurveyResponseView 
                    id={showedSurveyResponse.id}
                    name={showedSurveyResponse.survey.name}
                    date={showedSurveyResponse.date_created.format(mobile ? 'l' : 'll')}
                    onHide={() => setShowedSurveyResponse(undefined)}
                />}
            </FeatureToggle>

            <FeatureToggle featureNames={['readProtocolRes']}>
                <Modal show={showedProtocolComment !== undefined}  centered>
                    <Modal.Header closeButton onHide={()=> setShowedProtocolComment(undefined)}>
                        <small>{getContent('protocol_response__comment_title')}</small>
                    </Modal.Header>
                    <Modal.Body className={'text-break'}>
                        {showedProtocolComment}
                    </Modal.Body>
                </Modal>
            </FeatureToggle>
        </Container>
    );
};

export default Participant;

import { Survey, SurveyResponse } from "../../Dashboard/Surveys/types";
import { AllActionsTypes } from "../actions_all";

// The global surveys store state interface
export interface SurveysStoreState {
    surveys?: Survey[]
    responses: SurveyResponse[]
};

// The surveys store action names
export const SET_SURVEYS = 'SET_SURVEYS';
export const CLEAR_SURVEY = 'CLEAR_SURVEY';
export const SET_SURVEY_RESPONSES = 'SET_SURVEY_RESPONSES';
export const CLEAR_SURVEY_RESPONSES = 'CLEAR_SURVEY_RESPONSES';
export const ARCHIVE_SURVEY_RESPONSE = 'ARCHIVE_SURVEY_RESPONSE';
export const RESTORE_SURVEY_RESPONSE = 'RESTORE_SURVEY_RESPONSE';

// The surveys store actions interfaces
interface setSurveyAction {
    type: typeof SET_SURVEYS
    payload: Survey[]
}

interface clearSurveyAction {
    type: typeof CLEAR_SURVEY
}

interface setSurveyResponsesAction {
    type: typeof SET_SURVEY_RESPONSES
    payload: SurveyResponse[]
}

interface clearSurveyResponsesAction {
    type: typeof CLEAR_SURVEY_RESPONSES
}

interface archiveSurveyResponseAction {
    type: typeof ARCHIVE_SURVEY_RESPONSE
    payload: number
}

interface restoreSurveyResponsesAction {
    type: typeof RESTORE_SURVEY_RESPONSE
    payload: number

}

// Export the final surveys store action states as a single type
export type SurveyActionTypes =
    | setSurveyAction
    | clearSurveyAction
    | setSurveyResponsesAction
    | clearSurveyResponsesAction
    | archiveSurveyResponseAction
    | restoreSurveyResponsesAction
    | AllActionsTypes;
import { AllActionsTypes } from "../actions_all";

export interface dirtyState {
    [component: string]: boolean
}

export const SET_DIRTY_STATE = 'SET_DIRTY_STATE';
export const CLEAR_DIRTY_STATE = 'CLEAR_DIRTY_STATE';

export interface setDirtyAction {
    type: typeof SET_DIRTY_STATE,
    payload: [string,boolean]
}

export interface clearDirtyAction {
    type: typeof CLEAR_DIRTY_STATE
}

export type DirtyActionActionTypes =
    | setDirtyAction
    | clearDirtyAction
    | AllActionsTypes;

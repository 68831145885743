import React, { useEffect } from 'react';
import {useState} from "react";
import { useSelector } from "react-redux";


import { ImpersonificationData } from './types';
// Import the store
import { StoreState } from "../../store";
import { UserStoreState } from '../../store/user/types';

import Apirest from '../../Content/Apirest';
import SessionManager from '../../Authentication/SessionManager';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";

import { useContent } from '../../Content/cms';
import DataFrame from '../../Commons/Components/DataFrame';
import {Row as DataFrameRow} from '../../Commons/Components/DataFrame/types';

// Import the interfaces
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faCircle } from "@fortawesome/free-solid-svg-icons";


interface ImpersonificationProps {
    participant_id?: string
    show?: boolean
    disable_close_action?: boolean
    onHide?: () => void
}

const Impersonification: React.FC<ImpersonificationProps> = (props) => {

    let getContent = useContent();
    
    let [impersonifications, setImpersonifications] = useState<ImpersonificationData[]>([]);
    let [impersonificationDataFrame, setImpersonificationDataFrame] = useState<DataFrameRow[]>([]);

    useEffect(
        () => {
            Apirest.get_available_impersonifications(setImpersonifications);
        }, [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    useEffect(
        () => {
            setImpersonificationDataFrame(impersonification2Dataframe(impersonifications))
        }, [impersonifications] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const onSelectImpersonification = (e: any) => {
        let index = parseInt(e.target.value); 
        let impersonification = impersonifications[index];
        
        SessionManager.impersonificate(
            impersonification.examiner.id,
            impersonification.mode
        );
    };
    
    const isImpersonify = JSON.parse(localStorage.getItem('impersonification') || "null");


    let user = useSelector<StoreState, UserStoreState>((state: StoreState) => state.user);
   
    let onlyImp = !user.info?.roles.is_examiner && !user.info?.roles.is_manager && !user.info?.roles.is_manager

    function impersonification2row(impersonifications: ImpersonificationData, idx:number) {
        let imp_index = idx
        return [
            <FontAwesomeIcon icon={faUserFriends} color={'#5CB6E7'}/>,
            <button className={'text-btn link-button '} value={imp_index} onClick={onSelectImpersonification}>{impersonifications.examiner.first_name + ' ' + impersonifications.examiner.last_name}</button>,
            <Badge variant="warning">{impersonifications.mode}</Badge>,
            isImpersonify !== null && (isImpersonify.mode === impersonifications.mode && isImpersonify.examiner_id === impersonifications.examiner.id) ? <FontAwesomeIcon icon={faCircle} size='sm' color={'#5CB6E7'}/> : ' ',
        ]
    }


    const impersonification2Dataframe = (impersonifications: ImpersonificationData[]) : DataFrameRow[] => {
        return impersonifications.map((imp, idx) => impersonification2row(imp, idx));
    }

    const deImpersonifyButton = <Button className={'btn-primary'} disabled={isImpersonify === null} onClick={() => SessionManager.deimpersonificate()}>{getContent('impersonification__deimpersonify_button')}</Button>
    
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop={props.disable_close_action ? 'static' : true}
            keyboard={!props.disable_close_action}
            size={'lg'}
            centered
        >
            <Modal.Header className={'mb-0 pb-0 mr-2'} closeButton={!props.disable_close_action}>
                <Modal.Title className={' pl-2 pt-0 w-100'}>
                {onlyImp &&
                <Row className={'mb-2'}>
                    <Col className={'d-flex flex-row-reverse'}>
                        <Button onClick={SessionManager.logout}>{getContent("base__header__examiner_menu__logout")}</Button>
                    </Col>
                </Row>
                }


                <Row>
                    {getContent('impersonification__modal_title')}
                </Row>
                         
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'no-border'}>
            {impersonifications.length === 0 && onlyImp?
                <Alert variant={'info'} className="text-left">
                    {getContent('impersonification__modal__no_impersonifications_available')}
                </Alert>
                :
                <Row>
                    <DataFrame
                    dataframe={impersonificationDataFrame}
                    pageSize={5}
                    striped={true}
                    columnClassName={'text-right'}
                    className={'text-left'}
                />  
                    {deImpersonifyButton}
                </Row>
            }                  
            </Modal.Body>
        </Modal>
    );
}

export default Impersonification;

import * as React from 'react';
import {ReactNode, useState} from "react";

// Import components
import {Card, Container, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";

// Import css
import './section.css';

interface SectionProps {
    title?: string
    subtitle?: string
    modal?: ReactNode
    footer?: string
    className?: string
    bodyClassName?: string
    header?: ReactNode
    headerClassName?: string
    mobile?: boolean
}

const Section : React.FC<SectionProps> = (props) => {

    let {title, subtitle, children, modal} = props;
    let {className, bodyClassName, headerClassName, mobile} = props;

    return (
        <Card className={(className || '') + ' my-3 p-3 rounded'}>
            <Card.Header className={(headerClassName || '') + ' no-border bg-transparent d-flex-column spa'} >
                <Row className={props.modal ? 'justify-content-between': undefined}>
                    <Col sm={ props.modal ? 8 : 12 }>{props.title ? <p className={'p-0 mb-0'}>{title}</p> : null }
                    {props.subtitle ? <small className={'text-muted'}>{subtitle}</small> : null}
                    {props.header}
                    </Col>
                    {props.modal && modal}

                </Row>
            </Card.Header>
            <Card.Body className={bodyClassName || ( mobile ? 'px-0 pt-0' : 'px-5 pt-0') }>{children}</Card.Body>
        </Card>
    );
};

export default Section;

interface SubSectionProps {
    title?: ReactNode
    subtitle?: string
    className?: string
    foldable?: boolean
    startFolded?: boolean
    button?: ReactNode
}

export const SubSection : React.FC<SubSectionProps> = (props) => {

    let {title, subtitle, children, foldable, button} = props;
    let [folded, setFolded] = useState<boolean>(props.startFolded !== undefined);

    const toggleFolded = () => setFolded(prevFolded => !prevFolded);


    return  (
        <Container fluid className={'mb-3'}>
            {props.title &&
            <Row>
                <Col xs={ props.button ? 8 : 12 }>
                    <h5
                        className={(props.className ? props.className : "primary-subsection") + (foldable ? ' pointer' : '')}
                        onClick={foldable ? toggleFolded : undefined}
                    >
                        <FontAwesomeIcon icon={folded ? faAngleDoubleLeft : faAngleDoubleRight}/> {title}
                    </h5>

                    {props.subtitle ? <small className={'text-muted primary-subsection-subtitle mb-2'}>{subtitle}</small> : null}
                </Col>
                {props.button && button}
            </Row>}
            {(!foldable || !folded) && children}
        </Container>
    );
};

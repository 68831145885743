import { useDispatch } from "react-redux";
import { SET_DIRTY_STATE } from "./types";

export function useDirtyState(componentName: string) {

    const dispatch = useDispatch();

    let setDirtyState = (isDirty: boolean, customComponentName?: string) => dispatch({
        type: SET_DIRTY_STATE,
        payload: [
            customComponentName ? componentName + '-' + customComponentName : componentName,
            isDirty
        ]
    });

    let setDirty = () => setDirtyState(true);
    let setClean = () => setDirtyState(false);

    return {
        setDirty,
        setClean,
        setDirtyState
    }
}
import { useState, useRef } from 'react';
import { PopUpAlert } from '../../../../Commons/Helpers';
import { useContent } from '../../../../Content/cms';

export function useVolumeLevel(mic: MediaStream | null = null) {

    const [volumeLevel, setVolumeLevel] = useState(0);

    const getContent = useContent();

    // create a refs to store the microphone stream and the script processor
    const micStream = useRef<MediaStream | null>(mic);
    const destroyMediaStream = useRef<boolean>(false);
    const scriptProcessor = useRef<ScriptProcessorNode | null>(null);
    const audioContext = useRef<AudioContext | null>(null);
    const analyser = useRef<AnalyserNode | null>(null);


    function stopVolumeLevel() {
        setVolumeLevel(0);

        // disconnect the script processor
        scriptProcessor.current?.disconnect();
        scriptProcessor.current = null;

        // disconnect the analyser
        analyser.current?.disconnect();
        analyser.current = null;

        // disconnect the audio context
        audioContext.current?.close();
        audioContext.current = null;

        if (destroyMediaStream.current) {
            micStream.current?.getTracks().forEach(track => track.stop());
            micStream.current = null;
        }
    }

    function _captureMicrophone(deviceID: string, callback: (mic: MediaStream) => void) {

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            PopUpAlert(getContent('use_volume__no_browser_support'));
            return;
        }

        console.log('[UseVolume] Capturing microphone', deviceID);
        navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: {exact: deviceID},
                echoCancellation: false
            },
            video: false
        }).then((mic: MediaStream) => {
            micStream.current = mic;
            destroyMediaStream.current = true;
            callback(mic);
        }).catch(
            function (error) {
                alert('[UseVolume] Unable to capture your microphone. Please check console logs.');
                console.error(error);
            }
        );
    }

    function _startPollingVolume(callback: (volume: number) => void) {

        if (micStream.current === null) {
            console.error('[UseVolume] No microphone stream available');
            return;
        }

        // window.AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContext.current = new AudioContext();
        analyser.current = audioContext.current.createAnalyser();
        scriptProcessor.current = audioContext.current.createScriptProcessor(2048, 1, 1);
        analyser.current.smoothingTimeConstant = 0.8;
        analyser.current.fftSize = 1024;
        audioContext.current.createMediaStreamSource(micStream.current).connect(analyser.current);
        analyser.current.connect(scriptProcessor.current);
        scriptProcessor.current.connect(audioContext.current.destination);
        scriptProcessor.current.onaudioprocess = function () {
            const array = new Uint8Array(analyser.current!.frequencyBinCount);
            analyser.current!.getByteFrequencyData(array);
            const arraySum = array.reduce((a, value) => a + value, 0);
            const average = arraySum / array.length;
            console.log('[UseVolume] Volume level:', average);
            setVolumeLevel(average);
            callback(average);
        };
    }

    function startVolumeLevel(callback: (volume: number) => void = () => {}, deviceID?: string) {

        if (micStream.current === null && deviceID !== undefined) {
            _captureMicrophone(deviceID, () => _startPollingVolume(callback));
        } else if (micStream.current !== null) {
            _startPollingVolume(callback);
        } else {
            console.error('[UseVolume] No microphone stream available');
        }
    }

    return {startVolumeLevel, stopVolumeLevel, volumeLevel};
  }
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { str2FormTelField } from '../../../Commons/Helpers';
import Apirest from '../../../Content/Apirest';
import { saveAs } from 'file-saver';
import moment from 'moment';

import { SurveyResponse, SurveyResponseField } from '../types';
import { FormSubmitData } from '../../../Commons/Components/FormFields/types';

import Container from 'react-bootstrap/Container';

import Spinner from 'react-bootstrap/Spinner';
import { CloseButton } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import SurveyForm from '../Form/Form';

interface SurveyResponseViewProps {
    id: number;
    name: string;
    date?: string;
    onHide?: () => void;
}

const SurveyResponseView: React.FC<SurveyResponseViewProps> = (props) => {
    let { participant_id } = useParams<{ participant_id: string }>();

    let [response, setResponse] = useState<SurveyResponse | undefined>();

    /* 
        NOTE: this useEffect loads the survey response when the component is mounted
        and not when the component is shown.
        This means that the survey response is loaded even if the user never opens the modal.
        This is done to avoid loading the survey response every time the user opens the modal.
        If the user opens the modal, the survey response is already loaded.
        If in a future this data overload becomes a problem, we can change this behaviour to
        load the survey response only when the modal is opened.
    */
    useEffect(
        () => {
            Apirest.get_survey_response(participant_id, props.id.toString(), setResponse);
        }, [props.id] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <Container className='mt-0' fluid>
            <Modal show={true} onHide={props.onHide} size={'lg'}>
                <Modal.Body>
                    <CloseButton onClick={props.onHide} />
                    {!response && <Spinner animation="border" variant="primary" />}
                    {response && <SurveyForm
                        participant_id={participant_id}
                        {...response.survey}
                        values={Responses2FormValues(response.responses)}
                        description={response.date_created.format('LLLL')}
                        disabled
                    />}
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default SurveyResponseView;

function Responses2FormValues(responses: SurveyResponseField[]): FormSubmitData {
    let data: FormSubmitData = {};
    for (let fieldResponse of responses) {

        let strValue = String(fieldResponse.value);

        switch (fieldResponse.survey_field.type) {
            case 'tel':
                data[fieldResponse.survey_field.name] = str2FormTelField(strValue);
                break;
            case 'multiselect':
                data[fieldResponse.survey_field.name] = JSON.parse(strValue.replaceAll("'", '"'));
                break;
            case 'checkbox':
                data[fieldResponse.survey_field.name] = strValue.toLowerCase() === 'true';
                break;
            case 'date':
                data[fieldResponse.survey_field.name] = moment(strValue).format('YYYY-MM-DD');
                break;
            default:
                data[fieldResponse.survey_field.name] = strValue;
        }
    }
    return data;
}

export function downloadSurveyResponse(participant_id: string, response_id: string) {
    Apirest.get_survey_response(
        participant_id,
        response_id,
        (response: SurveyResponse) => {
            let rows = [['Field', 'Value']];
            for (let fieldResponse of response.responses) {
                let value = fieldResponse.value;
                if (typeof value != 'string') {
                    value = value.format('YYYY-MM-DD')
                }
                rows.push([fieldResponse.survey_field.name, value]);
            }

            let csvFile = rows.map(row => row.join(';')).join('\n');
            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
            let filename = (
                response.survey.name
                + '_' +
                response.date_created.format('YYY-MM-DD') + '.csv'
            );
            saveAs(blob, filename);
        },
    );
}
import { ProtocolResDataShort } from "../../Dashboard/Protocol/types";
import { AllActionsTypes } from "../actions_all";

// The global protocol store state interface
export interface ProtocolStoreState {
    all_protocolres: ProtocolResDataShort[],
};

// The protocol store action names
export const SET_PROTOCOL_RES = 'SET_PROTOCOL_RES';
export const CLEAR_PROTOCOL_RES = 'CLEAR_PROTOCOL_RES';
export const ARCHIVE_PROTOCOL_RES = 'ARCHIVE_PROTOCOL_RES';
export const RESTORE_PROTOCOL_RES = 'RESTORE_PROTOCOL_RES';

// The protocol store actions interfaces
interface setProtocolResAction {
    type: typeof SET_PROTOCOL_RES
    payload: ProtocolResDataShort[]
}

// archive the protocol
interface archiveProtocolResAction {
    type: typeof ARCHIVE_PROTOCOL_RES
    payload: number
}

// restore the protocol
interface restoreProtocolResAction {
    type: typeof RESTORE_PROTOCOL_RES
    payload: number
}


interface clearProtocolResAction {
    type: typeof CLEAR_PROTOCOL_RES
}

// Export the final protocol store action states as a single type
export type ProtocolActionTypes =
    | setProtocolResAction
    | clearProtocolResAction
    | archiveProtocolResAction
    | restoreProtocolResAction
    | AllActionsTypes;

import * as React from 'react';
import { useContent } from '../../Content/cms';

import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import Link from "../../Commons/SiteMap/Link";
import { IconButton } from "../../Commons/MultipleComponents";
import Container from 'react-bootstrap/Container';

interface RecorderLinkProps {
    participant_id: string
    onlyIcon?: boolean
    align?: string
}

const RecorderLink : React.FC<RecorderLinkProps> = (props) => {

    let getContent = useContent();

    return (
        <Container className={props.align}>
        <button className={'ml-0 mb-0 px-3 bg-transparent no-border'}>
            <Link className={'text-btn'} to={'protocol'} params={[props.participant_id]}>
                <IconButton
                    text={getContent("recorder__open__icon_tooltip")}
                    icon={{icon: faMicrophone, color: "#5CB6E7"}}
                />
                {!props.onlyIcon && ' ' + getContent("recorder__open__text")}
            </Link>
            </button>
        </Container>
    )
}

export default RecorderLink;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import ServerErrorModal from "./ServerErrorModal";
import PopUpAlert from "./Commons/Components/PopUpAlert";
import history from "./routerHistory";

// White Labeling
import { applyLabelStyles } from './Commons/WhiteLabeling';

// Store
import {Provider} from "react-redux";
import {store} from "./store";

// Import JXS components
import ProtectedRoutes from "./ProtectedRoutes";
import { Router, Switch, Route } from 'react-router-dom';
import RoutingTriggers from "./Commons/RoutingTriggers";
import Authentication from './Authentication';

//Import css
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './Commons/section.css'
import './Commons/Components/PopUpAlert/index.css';

// White labeling
applyLabelStyles();

// Initialize Google Analytic tracker
// ReactGA.initialize(config.ga.tracking_code, {debug: config.ga.debug});
// ReactGA.pageview(window.location.pathname);
// history.listen((location) => ReactGA.pageview(location.pathname))

/*
Note: For the private section (dashboard) the Login needs first to be passed
in order to get the doctor data from apirest in order to know the doctor language to 
laod the content by the I18NProvider. Because of this, the components hierarchy is:

<Authentication>
  <DataLoader>
      <I18NProvider>
      ...
      <I18NProvider />
  <DataLoader />
<Authentication />
*/
ReactDOM.render(
    <Provider store={store} >
        <Router history={history}>
            <RoutingTriggers>
                <ServerErrorModal />
                <PopUpAlert />
                <Switch>
                    <Route path={["/dashboard", "/admin"]} component={ProtectedRoutes} />
                    <Route path="/" component={Authentication} />
                </Switch>
            </RoutingTriggers>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useContent } from '../../../../Content/cms';
import Plotly from "plotly.js-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
import moment,{ Moment } from "moment";
import SiteMap from '../../../../Commons/SiteMap';

import { FeatureExtractionScoresEvolution } from '../types';

const Plot = createPlotlyComponent(Plotly);


function getLSHiddenState(participant_id: string, section:string, serie:string, default_value:boolean): boolean {
    let participant_key = participant_id + '_evolution_chart_state';
    
    let state_str = localStorage.getItem(participant_key);

    if (state_str === null) {
        setLSHiddenState(participant_id, section, serie, default_value);
        return default_value;
    }

    let state = JSON.parse(state_str);

    if (state[section] === undefined || state[section][serie] === undefined) {
        setLSHiddenState(participant_id, section, serie, default_value);
        return default_value;
    }

    return state[section][serie];
};


function setLSHiddenState(participant_id: string, section:string, serie:string, value:boolean) {
    let participant_key = participant_id + '_evolution_chart_state';

    let state = JSON.parse(localStorage.getItem(participant_key) || '{}');

    if (state[section] === undefined) {
        state[section] = {};
    }
    
    state[section][serie] = value;
    localStorage.setItem(participant_key, JSON.stringify(state));
};


// NOTE: currently we only consider line+markers plots, then, to avoid problems with
// the plotly library typescript definitions, we use the Plotly.PlotData type instead of
// using the Plotly.Data type that is general for all the chart types. Modify this type
// if you need to use other chart types and fix eventual type hint problems.
export type PlotlyData = Partial<Plotly.PlotData> & {
    id: string
    yaxis: string
    links: {to: string, params: any}[]
    min_value?: number
    max_value?: number
};

interface ChartProps {
    section: string
    data: FeatureExtractionScoresEvolution
    protocol_item_trigger: string
}

const Chart: React.FC<ChartProps> = (props) => {

    let history = useHistory();

    let {participant_id} = useParams<{participant_id: string}>();

    let getContent = useContent();

    let {section, protocol_item_trigger} = props;

    let data: PlotlyData[] = Object.entries(props.data).map(([name, scores], i) => ({
        id: [name, protocol_item_trigger].join('__').replace(' ', '_'),
        x: scores.map(({timestamp}) => timestamp.format('YYYY-MM-DD')),
        y: scores.map(({value}) => value),
        links: scores.map(({protocol_res_id}) => ({to: 'protocolres', params: [participant_id, protocol_res_id]})),
        name: getContent("protocol__feature_extraction_score__" + name + "__name"),
        mode: "lines+markers" as "lines+markers",
        marker: {size: 8},
        yaxis: 'yaxis' + (i+2).toString(),
        visible: true, // TODO(Lao): Check if this information should be modified in the admin panel as before
        hovertemplate: '%{x}<br><b>' + getContent("protocol__feature_extraction_score__" + name + "__name") + ':</b> %{y:.2f}<extra></extra>'
    }))

    let dates : Moment[] = Object.values(props.data).map(scores => scores.map(s => s.timestamp)).flat();
    let maxDate = dates.reduce((a, b) => a.isAfter(b) ? a : b);
    let minDate = dates.reduce((a, b) => a.isBefore(b) ? a : b);
    let total_days = maxDate.diff(minDate, 'days');

    let layout : {[key: string]: any} = {
        modebar: {
            remove: [
                'pan', 'zoom', 'zoomin', 'zoomout', 'box_select',
                'lasso', 'select', 'resetscale', 'autoscale'
            ]
        },
        margin: {
            t: 25, l: 0, r: 0, b: 25
        },
        hoverlabel: {
            align: 'left'
        },
        xaxis: {    
            type: 'date',
            rangemode: 'tozero',
            tickformat: '%d-%m-%Y',
            nticks: Math.min(10, Math.max(1,total_days)),
            autorange: true,
            showgrid: true,
            zeroline: false,
            showline: true,
            rangeslider: {
                visible: true
            },
        },
        yaxissurvey: {
            autorange: false,
            showgrid: true,
            zeroline: false,
            showline: true,
            showticklabels: false,
            range: [0, 100],
        },
        showlegend: true,
        legend: {x: 0, y: -10, orientation: 'h'}
    };

    // Add extra y axes for each serie
    for (let i=0; i<data.length; i++) {
        layout[data[i].yaxis] = {
            showgrid: false,
            zeroline: false,
            overlaying: 'y',
            anchor: 'x',
            showticklabels: false,
            position: ((i+1)*0.05),
            side: 'left'
        }
    }

    // Set inicial visible state using localStorage
    for (let serie of data) {
        serie.visible = getLSHiddenState(participant_id, section, serie.id, serie.visible === true);
        if (!serie.visible) {
            serie.visible = 'legendonly';
        }
    }

    // Update the localStorage visible state when the user clicks on the legend
    // to persist the state on time.
    function onLegendClick(event: any) {
        let id = event.data[event.curveNumber].id;
        let visible = !(event.data[event.curveNumber].visible === true);
        setLSHiddenState(participant_id, section, id, visible);
        return true;
    }

    // Click event go to link if the data has a list of links
    // NOTE: If there is more than one point selected (to point the same day with the same value)
    // then we are going to the first link. Thi could be improved by opening new tabs but is a very 
    // rare case not worth the effort.
    function onClick(event: any) {
        if (event.points.length > 0 && event.points[0].data.links) {

            // Find the data index in the time series
            let x_date = moment(new Date(event.points[0].x));
            let data_dates = event.points[0].data.x.map((d: Date) => moment(d));
            let linkIdx = data_dates.findIndex((d: Moment) => d.isSame(x_date, 'day'));
            let link = event.points[0].data.links[linkIdx];
            
            history.push(SiteMap.getFullURL(
                link.to,
                link.params
            ));
        }
    }

    return (
        <Plot 
            useResizeHandler={true}
            style={{width: '100%', height: '300px'}}
            data={[...data]}
            layout={layout}
            onClick={onClick}
            onLegendClick={onLegendClick}
        />
    );
}

export default Chart;
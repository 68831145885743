import { CLEAR_ALL } from "../actions_all";
import {FeatureToggleActionTypes, FeatureToggleState, SET_TOGGLE_DICT, CLEAR_FEATURE_TOGGLE} from "./types";

const initialState: FeatureToggleState = {};

export function FeatureToggleReducer(state=initialState, action: FeatureToggleActionTypes): FeatureToggleState {

    let newState = {...state};

    switch (action.type) {

        case SET_TOGGLE_DICT:
            newState.table = action.payload;
            break;

        case CLEAR_ALL:
        case CLEAR_FEATURE_TOGGLE:
            newState = initialState;
            break;

        default:
            break;
    }

    return newState;
}
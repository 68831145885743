import React from "react";
import { useContent } from '../Content/cms';
import { getDesign } from "../Commons/WhiteLabeling";

// Import components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Import css
import './index.css'

const NotFound: React.FC = () => {
    
    let getContent = useContent();  

    const whiteLabels = getDesign();

    return(
    <Container className={'bg-light content-minheight'} fluid>
    <Row className={''}>
        <Col sm={6} className={'d-flex justify-content-end margin-logo-col'}>
            <img src={whiteLabels.pages.not_found} alt={'page_not_found'} className={'w-50'}/>
        </Col>
        <Col xs={6} className={'margin-text-col'}>
            <h3 className={'style-404'}>404</h3>
            <h4 className={'font-weight-bold'}>{getContent('not_found__title')}</h4>
            <h5>{getContent('not_found__text')}</h5>
        </Col>
    </Row>
    </Container>
    );
}

export default NotFound;

import * as React from 'react';
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useContent } from "../../../Content/cms";
import { capitalize } from "../../../Commons/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";

import { Participant } from "../../../Dashboard/interfaces";
import { StoreState } from "../../../store";

interface ParticipantSearchProps {
    onSelect: (selection: Participant[]) => void
    placeholder: string
    clearOnSelect?: boolean
    onClear?: () => void
}

const ParticipantSearch : React.FC<ParticipantSearchProps> = (props) => {

    let getContent = useContent();

    let [participantPrefix, setParticipantPrefix] = useState<string>('');
    let [lastVisitedParticipant, setLastVisitedParticipant] = useState<Participant[]>([]);

    let participants = useSelector<StoreState, Participant[]>(state => state.participants);

    const inputRef = useRef<Typeahead<Participant>>(null);

    function participantFilter(participant: Participant) {
        let first_name = participant.first_name || '';
        let last_name = participant.last_name || '';
        let code = participant.code;

        let field_orders = [
            [first_name, last_name, code],
            [last_name, first_name, code],
            [code, first_name, last_name],
            [code, last_name, first_name],
        ];

        return field_orders.some(fields => regex.test(fields.join(' ')));
    }

    function onClear() {
        setParticipantPrefix('');
        (inputRef as any).current.clear();

        if (props.onClear) {
            props.onClear();
        }
    };

    function handleInputChange(value: string) {
        setParticipantPrefix(value);
    };

    function handleOnSelect(selection: Participant[]) {

        if (selection.length > 0) {
            setLastVisitedParticipant(visitedParticipants => [...visitedParticipants, selection[0]].slice(-5));
        }

        props.onSelect(selection);

        if (props.clearOnSelect) {
            onClear();
        }
    };

    function labelKey(participant: Participant) {

        let first_name = participant.first_name || '';
        let last_name = participant.last_name || '';

        return [
            ...last_name.split(' '),
            ...first_name.split(' '),
            ...participant.code.split(' ')
        ].map(n => capitalize(n)).join(' ');
    }
    
    // Get the options to show
    let regex = new RegExp('(.)*' + participantPrefix.split('').join('(.)*') + '(.)*', 'i');
    let options = participantPrefix !== '' ?  participants.filter(participantFilter) : lastVisitedParticipant ;

    return (
        <Typeahead
            id={'participant-search-typeahead'}
            ref={inputRef}
            inputProps={{className: 'pl-5'}}
            options={options.slice(0, 5)}
            placeholder={props.placeholder}
            emptyLabel={getContent("base__header__search_participant__empty_label") as string}
            labelKey={labelKey}
            onInputChange={handleInputChange}
            onChange={handleOnSelect}
        >
            <div className="lbt-aux">
                <FontAwesomeIcon icon={faSearch}/>
            </div>
            <div className="rbt-aux">
                { participantPrefix.length >= 1 ? <ClearButton onClick={onClear}/> : null}
            </div>
        </Typeahead>
    )
};

export default ParticipantSearch;
